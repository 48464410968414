import React, { useState, useEffect} from 'react';
import './index.scss';

const DropdownComponentSort = ({ sortType, sortItem, setSortItem }) => {

  const handleClick = (item) => {
    setSortItem(item); // Set active item onClick
  };

  return (
    < div className="rt-dropdown-sort">
        < ul >
          {
            sortType.map((item, index) => (
              <li
                className={item === sortItem ? "active" : ""}
                key={index}
                onClick={() => handleClick(item)}
              >
                {item}
              </li>
            ))
          }
        </ul>
    </div >
  );
};

export default DropdownComponentSort;
