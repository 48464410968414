import React, { useEffect, useState } from "react";
import { Box, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, Checkbox, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useParams } from "react-router";
import "./index.scss";
import { styled } from '@mui/material/styles';

const BpCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.primary.main,
    '&.Mui-checked': {
        color: theme.palette.primary.main,
    },
}));

const ShareAd = ({ openAdShare, onClose, storyMedia, adMedia, isAd }) => {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [followers, setFollowers] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [selectedFollowers, setSelectedFollowers] = useState([]);
    const [conversationId, setConversationId] = useState('')

    const handleCheckboxChange = (userId) => {
        if (selectedFollowers.includes(userId)) {
            setSelectedFollowers(selectedFollowers.filter(id => id !== userId));
        } else {
            setSelectedFollowers([...selectedFollowers, userId]);
        }
    };

    const getMessageType = (mediaUrl) => {
        if (!mediaUrl) return "unknown";
        const extension = mediaUrl.split('.').pop().toLowerCase();
        // Determine the message type based on the extension
        if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
            return "image";
        } else if (['mp4', 'avi', 'mov', 'mkv', 'webm', 'wmv'].includes(extension)) {
            return "video";
        } else {
            return "text";
        }
    };

    const sharingWithFriends = () => {
        if (!accessToken) return;

        const promises = selectedFollowers.map((follower) => {
            const friendData = { senderId: follower, receiverId: userId };
            const send = {
                method: "POST",
                url: global.config.ROOTURL.prod + "/groupchat/getConversation/",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                data: friendData,
            };

            return axios(send)
                .then((response) => {
                    if (response.data) {
                        setConversationId(response.data[0]._id);

                        const messageData = {
                            content: isAd ? adMedia.url : storyMedia.stories[0].media,
                            conversationId: response.data[0]._id, // Use the conversationId from the response
                            messageType: getMessageType(isAd ? adMedia.url : storyMedia.stories[0].media),
                            receiverId: follower,
                            senderId: userId,
                        };

                        const req = {
                            method: "POST",
                            url: global.config.ROOTURL.prod + `/groupchat/sendDirectMessage`,
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + accessToken,
                            },
                            data: messageData,
                        };

                        return axios(req);
                    }
                })
                .catch((error) => console.log(error));
        });
        Promise.all(promises)
            .then(() => {
                onClose()
            })
            .catch((error) => console.log("Error in sharingWithFriends:", error));
    };



    const shareFollowers = () => {
        if (!accessToken) return
        const req = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/auth/get-followers-byuserid/${userId}/${pageNum}/${global.config.pagePerSize}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(req)
            .then(response => {
                if (response.data) {
                    setFollowers(response.data)
                }
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        shareFollowers();
    }, [userId]);

    return (
        <Dialog open={openAdShare} onClose={onClose}>
            <DialogTitle>Share With Friends</DialogTitle>
            <DialogContent>
                <TextField fullWidth placeholder="Search user name" variant="outlined" size="small" />
                <List>
                    {followers.map((user) => (
                        <ListItem key={user._id} sx={{ gap: 1 }} alignItems="center">
                            <BpCheckbox
                                edge="start"
                                onChange={() => handleCheckboxChange(user._id)}
                                checked={selectedFollowers.includes(user._id)}
                                inputProps={{ 'aria-label': 'Custom checkbox' }}
                            />
                            <ListItemAvatar>
                                <Avatar src={user.profilePhoto} />
                            </ListItemAvatar>
                            <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={sharingWithFriends} variant="contained" color="primary">
                    Share with your Friends
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShareAd;