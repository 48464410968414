import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { format } from "date-fns";
import { Smile, Send, Paperclip, ArrowLeft } from "lucide-react";
import { getStorageItem } from "../../../../utils/sessionStorage";
import { SocketContext } from "../../../../context/SocketContext";
import axios from "axios";
import debounce from "lodash/debounce";
import StickerModal from "../StickerModal";
import hahaEmoji from "../../../../assets/images/chatreactions/haha.gif";
import likeEmoji from "../../../../assets/images/chatreactions/like.gif";
import sadEmoji from "../../../../assets/images/chatreactions/sad.gif";
import clapEmoji from "../../../../assets/images/chatreactions/clap.gif";
import loveEmoji from "../../../../assets/images/chatreactions/love.gif";
import celebrateEmoji from "../../../../assets/images/chatreactions/celebrate.gif";

const MAX_VISIBLE_REACTIONS = 2;
const emojiMap = {
  like: likeEmoji,
  haha: hahaEmoji,
  sad: sadEmoji,
  clap: clapEmoji,
  love: loveEmoji,
  celebrate: celebrateEmoji,
};

const Message = ({
  id,
  sender,
  text,
  time,
  isSelf,
  reactions,
  onReact,
  messageType,
  content,
}) => {
  const avatar = getStorageItem("avatar");
  const [showReactions, setShowReactions] = useState(false);
  const longPressTimer = useRef(null);
  const messageRef = useRef(null);

  const handleLongPress = () => {
    setShowReactions(true);
  };

  const handleTouchStart = () => {
    longPressTimer.current = setTimeout(handleLongPress, 500);
  };

  const handleTouchEnd = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageRef.current && !messageRef.current.contains(event.target)) {
        setShowReactions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const reactionOptions = ["like", "celebrate", "haha", "love", "sad", "clap"];

  const renderReactions = () => {
    if (!reactions || reactions.length === 0) {
      return null;
    }

    const visibleReactions = reactions.slice(0, MAX_VISIBLE_REACTIONS);
    const remainingCount = reactions.length - MAX_VISIBLE_REACTIONS;

    return (
      <div className="reactions">
        {visibleReactions.map((reaction, index) => (
          <span key={index} className="reaction">
            <img
              src={
                emojiMap[
                  typeof reaction === "string" ? reaction : reaction.reaction
                ]
              }
              alt={typeof reaction === "string" ? reaction : reaction.reaction}
              className="reaction-emoji"
            />
          </span>
        ))}
        {remainingCount > 0 && (
          <span className="reaction reaction-count">+{remainingCount}</span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={messageRef}
      className={`message ${isSelf ? "self" : "other"}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
      onMouseLeave={handleTouchEnd}
    >
      {!isSelf && (
        <img
          src={sender.profilePhoto || avatar}
          alt={sender || "User avatar"}
          className="avatar"
        />
      )}
      <div>
        <div className="message-content">
          {!isSelf && <span className="sender">{sender.firstName}</span>}
          {messageType === "text" ? (
            <p className="text">{text}</p>
          ) : messageType === "image" ? (
            <img src={content} alt="Shared image" className="shared-image" />
          ) : messageType === "video" ? (
            <video src={content} controls className="shared-video" />
          ) : null}
          <span className="time">{time}</span>
        </div>
        {renderReactions()}
        {showReactions && (
          <div className="reaction-options">
            {reactionOptions.map((reaction) => (
              <button
                key={reaction}
                onClick={() => {
                  onReact(id, reaction);
                  setShowReactions(false);
                }}
              >
                <img
                  src={emojiMap[reaction]}
                  alt={reaction}
                  className="reaction-emoji"
                />
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const DirectChatScreen = ({
  chat,
  friendId,
  receiverId,
  receiverDets,
  onBack,
}) => {
  const [socket] = useContext(SocketContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const userProfilePic = getStorageItem("user_profile_pic");
  const [openStickerModal, setOpenStickerModal] = useState(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (friendId) {
      getFriendsMessage();
      socket.emit("join direct chat", friendId);
    }
  }, [friendId]);

  useEffect(() => {
    const messageHandler = (data) => {
      if (friendId && data.chat.conversationId === friendId) {
        setMessages((prevMessages) => [...prevMessages, data.chat]);
      }
    };

    const typingHandler = (data) => {
      if (friendId && data.conversationId === friendId) {
        setIsTyping(true);
      }
    };

    const stopTypingHandler = (data) => {
      if (friendId && data.conversationId === friendId) {
        setIsTyping(false);
      }
    };

    const reactionHandler = (data) => {
      if (friendId && data.conversationId === friendId) {
        setMessages((prevMessages) =>
          prevMessages.map((message) =>
            message._id === data.messageId
              ? {
                  ...message,
                  reactions: [
                    ...message.reactions,
                    { reaction: data.reaction, userId: data.userId },
                  ],
                }
              : message
          )
        );
      }
    };

    socket.on("message received", messageHandler);
    socket.on("typing", typingHandler);
    socket.on("stop typing", stopTypingHandler);
    socket.on("reaction received", reactionHandler);

    return () => {
      socket.off("message received", messageHandler);
      socket.off("typing", typingHandler);
      socket.off("stop typing", stopTypingHandler);
      socket.off("reaction received", reactionHandler);
    };
  }, [socket, friendId]);

  useEffect(scrollToBottom, [messages]);

  const getFriendsMessage = async () => {
    if (!accessToken || !friendId) return;

    try {
      const { data } = await axios.get(
        `${global.config.ROOTURL.prod}/groupchat/getDirectMessages/${friendId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessages(data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file, file.name);

    try {
      const { data } = await axios.post(
        `${global.config.ROOTURL.prod}/upload-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (data && data.length > 0) {
        return data[0].location;
      } else {
        throw new Error("No data returned from upload.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const sendMessage = async () => {
    if (!accessToken || (!inputMessage.trim() && !file)) return;
    setLoading(true);

    try {
      let messageType = "text";
      let content = inputMessage;

      if (file) {
        const fileUrl = await uploadFileToS3(file);
        messageType = file.type.startsWith("image") ? "image" : "video";
        content = fileUrl;
      }

      const messageData = {
        conversationId: friendId,
        senderId: userId,
        receiverId: receiverId,
        messageType,
        content,
      };

      const { data: responseData } = await axios.post(
        `${global.config.ROOTURL.prod}/groupchat/sendDirectMessage`,
        messageData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      socket.emit("new direct message", responseData);

      setInputMessage("");
      setFile(null);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  const reactMessage = async (messageId, reaction) => {
    try {
      const newReaction = { reaction, userId };

      socket.emit("reaction", {
        messageId,
        reaction,
        userId,
        conversationId: friendId,
      });

      await axios.put(
        `${global.config.ROOTURL.prod}/groupchat/reactDirectMessage`,
        { messageId, reaction, userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error("Error in reactMessage:", error);
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message._id === messageId
            ? {
                ...message,
                reactions: message.reactions.filter(
                  (r) => r.userId !== userId || r.reaction !== reaction
                ),
              }
            : message
        )
      );
    }
  };

  const emitTyping = debounce(() => {
    socket.emit("typing", {
      conversationId: friendId,
      userId,
      user: { profilePic: userProfilePic },
    });
  }, 300);

  const stopTyping = () => {
    socket.emit("stop typing", {
      conversationId: friendId,
      userId,
    });
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    emitTyping();
  };

  const handleSend = () => {
    if (inputMessage.trim() || file) {
      sendMessage();
      stopTyping();
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="chat-screen">
      <header>
        <div>
          <button className="back-button" onClick={onBack}>
            <ArrowLeft />
          </button>
          <div className="chat-info">
            <img
              src={receiverDets.profilePhoto}
              width={50}
              style={{
                borderRadius: "50%",
                marginRight: "10px",
                marginLeft: "20px",
              }}
            />
            <h2>{receiverDets.firstName + " " + receiverDets.lastName}</h2>
          </div>
        </div>
      </header>

      <main className="messages">
        {messages.map((message) => (
          <Message
            key={message._id}
            id={message._id}
            sender={
              message?.senderId?._id === userId ? "You" : message.senderId
            }
            text={message.content}
            time={new Date(message.createdAt).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            isSelf={message.senderId === userId}
            reactions={message.reactions}
            onReact={reactMessage}
            messageType={message.messageType}
            content={message.content}
          />
        ))}
        <div ref={messagesEndRef} />
      </main>

      {isTyping && (
        <div className="typing-indicator">
          <span>Friend is typing...</span>
        </div>
      )}

      <footer>
        <label htmlFor="file-upload" className="attach-button">
          <Paperclip />
        </label>
        <input
          id="file-upload"
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
        <div className="input-wrapper">
          <input
            type="text"
            placeholder="Write a message..."
            value={inputMessage}
            onChange={handleInputChange}
            onKeyPress={(e) => e.key === "Enter" && handleSend()}
            onBlur={stopTyping}
          />
          <button
            className="emoji-button"
            onClick={() => setOpenStickerModal(true)}
          >
            <Smile />
          </button>
        </div>
        <button className="send-button" onClick={handleSend} disabled={loading}>
          <Send />
        </button>
      </footer>

      <StickerModal
        openStickerModal={openStickerModal}
        handleCloseStickerModal={() => setOpenStickerModal(false)}
        // groupId={groupId}
        // setGroupMessage={setInputMessage}
        friendId={friendId}
        receiverId={receiverId}
        // setGroupMessage={setGroupMessage}
        setFriendMessage={setMessages}
      />
    </div>
  );
};

export default DirectChatScreen;
