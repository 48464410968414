import { a, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/header/logo.png";
import Plus from "../../assets/images/header/plus.svg";
import { useEffect, useState } from "react";
import Notification from "./notification";
import SearchBar from "./searchBar";
import Runcard from "../post-guest/run";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import "./index.scss";
import Switch from "react-switch";
import { ReactComponent as Bell } from "../../assets/images/icons/Notification.svg";

import GuestLoginPopup from "../../components/preview/Popup";

const Header = (props) => {
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  return (
    <>
    <div>
      <header className="header" onClick={handleOpen}>
        <div className="header-cnt">
          <div className="logo-block">
            <a >
              <img src={Logo} alt="Champhunt" />
              <span
                style={{
                  paddingLeft: "15px",
                  fontSize: "25px",
                  fontWeight: 500,
                }}
                className="label title-label"
              >
                CHAMPHUNT
              </span>
            </a>
          </div>

          {(
            <div className="search-block">
              <SearchBar
              // onClick={handleOpen}
              />
            </div>
          )}

          {(
            <div className="desktop-only add-funds">
              <div className="add-button">
                <img src={Plus} alt="" />
              </div>
              <p className="money">100</p>
            </div>
          )}
          { (
            <div className="desktop-only profile-block">
              <Runcard onClick={handleOpen}/>
              {/* <div>
                <img src={darkMode} alt="dark" 
                style={{ width: "30px", height: "30px", marginTop: "7px" }}
                onClick={toggleDarkMode} />
              </div> */}
              <Notification />
              <div className="avatar">
                <img
                 src ={defaultAvatar}
                  alt="avatar"
                  style={{ width: "40Spx", height: "43px", borderRadius: 50 }}
                />
              </div>
              <div>
                <div className="primary" >
                  {/* {userInfo.firstName + " " + userInfo.lastName} */}
                  <i className="arrow down"></i>
                  <div
                    direction="bottom"
                    className="header-options"
                    style={{ visibility: "hidden" }}
                  >
                    <div className="caret"  ></div>
                    <div className="options" style={{ width: "149px" }}>
                      <ul>
                        <li >My Profile</li>
                      </ul>
                      <ul>
                        <li >
                          <div>
                            Message
                          </div>
                        </li>
                      </ul>
                      <ul>
                        <li >About us</li>
                      </ul>
                      <ul>
                        <li >Privacy</li>
                      </ul>
                      <ul>
                        <li >Rules</li>
                      </ul>
                      <ul style={{ paddingLeft: "28px" }}>
                        <li>
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span style={{ paddingRight: "7px" }}>
                              GlobalPitch
                            </span>
                            {/* <Switch
                              onChange={togglechanged}
                              checked={checked}
                              onColor="#86d3ff"
                              onHandleColor="#2693e6"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={19}
                              width={41}
                              className="react-switch"
                              id="material-switch"
                            /> */}
                          </label>
                        </li>
                      </ul>
                      <ul>
                        <li >Sign Out</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {
           (
              <div>
                {(
                  <>
                    <div
                      style={{
                        height: "8px",
                        backgroundColor: "red",
                        borderRadius: " 50%",
                        width: "9px",
                        position: "absolute",
                        marginLeft: "12px",
                      }}
                    ></div>
                    <Bell
                      style={{ height: "20px", width: "20px" }}
                       onClick={() => {
                         navigate("/notification");
                         setActiveNotifications(0);
                       }}
                    
                    />
                  </>
                ) }
                  <Bell
                    style={{ height: "20px", width: "20px" }}
                    // onClick={() => {
                    //   navigate("/notification");
                    // }}
                  />
                
              </div>
            )
            
          } */}
        </div>
      </header>
      <header className="header2" onClick={handleOpen}>
        <div>
          { (
            <div className="desktop-only nav-links">
              <a
                // to="/pitch"
                className={`nav-link ${
                  window.location.pathname.includes("")
                }`}
              >
                Pitch
              </a>

              <a
                // to="/rewards"
                className={`nav-link ${
                  window.location.pathname.includes("") 
                }`}
              >
                {/* Rewards */}
                Arena
              </a>
              {/* Advertisments */}
              {/* <a
              to="/earn-rewards"
              className={`nav-link ${window.location.pathname.includes("/earn-rewards") ? "active" : ""
                }`}
            >
              Earn Rewards
            </a> */}
              <a
                // to="/deals"
                className={`nav-link ${
                  window.location.pathname.includes("")? "active"
                  : "" 
                }`}
              >
                Deals
              </a>

              <a
                // to="/jobs"
                className={`nav-link ${
                  window.location.pathname.includes("")? "active"
                  : "" 
                }`}
              >
                Jobs
              </a>
              <a
                // to="/marketplace"
                className={`nav-link ${
                  window.location.pathname.includes("")
                    ? "active"
                    : ""
                }`}
              >
                Marketplace
              </a>

              {/* <a
                to="/groupchat"
                className={`nav-link ${
                  window.location.pathname.includes("/groupchat")
                    ? "active"
                    : ""
                }`}
              >
                Dugout
              </a> */}

              <a
                // to="/groupchat"
                className={`nav-link ${
                  window.location.pathname.includes("")
                    ? "active"
                    : ""
                }`}
              >
                Dugout
              </a>

              <a
                // to="/contact-us" // Update the "to" attribute as per your routing setup
                className={`nav-link ${
                  window.location.pathname.includes("")
                    ? "active"
                    : ""
                }`}
                 // Handle the click event
              >
                Contact Us
              </a>

              <a
                // to="/champhunt-plus"
                className={`nav-link ${
                  window.location.pathname.includes("")
                    ? "active"
                    : ""
                }`}
              >
                Subscription
              </a>
            </div>
          )}
        </div>
      </header>
    </div>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  );
};

export default Header;

{
  /* <a
to="/champhunt-plus"
>
<div className="champhunt-plus">
  <img className="plus-logo" src={logoPlus} alt="hand" />
  <p className="small-text">Champhunt <spnn className="plus-icon">+</spnn></p>
  <img className="champhunt-name" src={champHunt} alt="logo" />
</div>
</a> */
}
