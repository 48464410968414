import React from "react";

const DynamicField = ({ field, value, handleChange , styles }) => {
    const { label, type, name, options, placeholder } = field

    switch (type) {
        case 'text':
        case 'email':
        case 'password':
        case 'date':
        case 'datetime-local':
            return (
                <div key={name} className={styles.formStyling}>
                    <label>{label}</label>
                    <input
                        type={type}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                    />
                </div>
            );

        case 'select':
            return (
                <div key={name} className={styles.formStyling}>
                    <label>{label}</label>
                    <select name={name} value={value} onChange={handleChange}>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
            );

        case 'textarea':
            return (
                <div key={name} className={styles.formStyling}>
                    <label>{label}</label>
                    <textarea
                        name={name}
                        value={value}
                        onChange={handleChange}
                        placeholder={placeholder}
                    ></textarea>
                </div>
            );
        
        
        default:
            return null
    }
}

export default DynamicField