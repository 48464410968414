

import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Lottie from "react-lottie";
import dealLoanBall from "../../assets/images/deals/dealAnimationloan.json"
import { Button, Typography, IconButton } from '@mui/material';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    textAlign: 'center',
    width: '80%',
    maxWidth: '600px',
  };
  
  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  };
const LoanInformaion = (props) => {
    const { openModel, handleCloseModel } = props;
    const [open, setOpen] = useState(openModel);
    const handleClose = () => {
        handleCloseModel(false);
        setOpen(false);
    };
    useEffect(() => {
        setOpen(openModel);
      }, [openModel]);
    
    return (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <IconButton style={closeButtonStyle} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Lottie 
                  options={{
                        loop: true,
                        autoplay: true,
                        animationData: dealLoanBall,
                      }}
                      width="205px"
                      height="145px"
              />
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{color: "#0C5BA0"}}>
                Browsing Runs
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                You also have the option to borrow runs in order to redeem a deal.
                This deal will be reserved, and once you've earned enough runs to
                cover the borrowed amount, we will deliver the deal to you.
              </Typography>
              {/* <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                Ok
              </Button> */}
            </Box>
          </Modal>
        </div>
      );

    // return (
    //     <div>
    //         <Modal
    //             open={open}
    //             onClose={handleClose}
    //             aria-labelledby="modal-modal-title"
    //             aria-describedby="modal-modal-description"
    //         >
    //             <Box sx={modalStyle}>
    //                 <p>
    //                 You also have the option to borrow runs in order to redeem a deal. This deal will be reserved, and once you've earned enough runs to cover the borrowed amount, we will deliver the deal to you
    //                 </p>

    //                 <div>
    //                     <Button onClick={handleClose}>ok</Button>
    //                 </div>
    //             </Box>
    //         </Modal>
    //     </div>
    // );
};

export default LoanInformaion;
