import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getAuthToken } from "../../utils/auth";
import {
  Box,
  Modal,
  Tab,
  Tabs,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0",
    fontFamily: 'Poppins',
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "20px",
    marginLeft: "40px",
  },
  // Add your custom styles here
  checkboxLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 0,
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left"

  },
  customTick: {
    position: 'absolute',
    width: '6px',
    height: '10.59px',
    left: '862.25px',
    top: '239px',
    border: '2px solid #FDFDFD',
    transform: 'rotate(-90deg)',
  },
  checked: {
    backgroundColor: '#0C5BA0', // Add your custom color for checked state
  },
}));
const styleDesktop = {
  position: 'absolute',
  color: 'white',
  width: '361px',
  bgcolor: '#0C5BA0',
  boxShadow: 24,
  // p: 4,
  minHeight: '347px',
  top: '124px',
  left: '70%',
  transform: 'translateX(-50%)',
  borderRadius: '32px',
  border: '1px',
};

const divStyleMobile = {
  width: "19%",
  height: "5px",
  backgroundColor: "white",
  position: "absolute",
  top: "4px",
  display: "flex",
  alignItems: "center",
  border: "3px",
  borderRadius: '20px',
  // right: "50px",
  left: "160px",
  zIndex: "1",
};
const styleMobile = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  transform: 'translateY(100%)',
  width: '100%',
  color: 'white',
  height: '50%',
  bgcolor: '#0C5BA0',
  borderRadius: '32px 32px 0 0',
  transition: 'transform 0.3s ease-in-out',
};

export default function FilterModal(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');
  const [style, setStyle] = useState();
  const { open, handleClose, setDeal, setFilter } = props;
  const [selectedCategory, setSelectedCategory] = useState('All');
  // const [checkedCategory, setCheckedCategory] = useState({ all: false, offers: false, exclusive: false, timelimit: false, foodandbverages: false });
  const [activeTab, setActiveTab] = useState(0);
  const accessToken = getAuthToken();
  // const handleCheckboxChange = (event) => {
  //   const { value } = event.target;
  //   setSelectedCategory(value);
  // };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };



  const findCategory = (category) => {
    console.log("category", category)
    if (!category && !accessToken) return
    if (props?.type !== "admin") {
      const categoryDetails = {
        method: 'get',
        url: global.config.ROOTURL.prod + `/deal/findByCategoryWeb/${category}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        },
        // json: true
      }
      axios(categoryDetails)
        .then(response => {
          console.log(response.data)
          setDeal(response.data);
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            // clearStorage()
            // navigate('/login');
          }
        })
    }
  }
  const handleSortMethod = (category) => {
    if (!category && !accessToken) return
    if (props?.type === "admin") {
      props?.setSort(category)
      props?.setPage(1)
    }
    if (props?.type !== "admin") {
      const categoryDetails = {
        method: 'get',
        url: global.config.ROOTURL.prod + `/deal/sortDealsWeb/${category}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + accessToken
        },
        // json: true
      }
      axios(categoryDetails)
        .then(response => {
          setDeal(response.data);
        })
        .catch(error => {
          if (error?.response?.status === 401) {
            // clearStorage()
            // navigate('/login');
          }
        })
    }
  }
  useEffect(() => {
    if (activeTab === 0)
      if (selectedCategory) {

        if (props?.type === "admin") {
          props?.setPage(1)
        }
        setFilter(selectedCategory)
        findCategory(selectedCategory);
      }
  }, [selectedCategory]);

  useEffect(() => {
    if (!matches) {
      setStyle({ ...styleMobile, transform: open ? 'translateY(0)' : 'translateY(100%)' });
    } else {
      setStyle(styleDesktop);
    }
  }, [matches, open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Filter" style={{ color: "white" }} />
          <Divider
            orientation="vertical"
            sx={{ background: "white" }}
            flexItem
            light
          />
          <Tab label="Sort" style={{ color: "white" }} />
        </Tabs>

        <Divider sx={{ background: "white" }} />

        {activeTab === 0 && (
          <Box p={2}>  <FormControl>
            <FormGroup style={{ width: "100%" }}>
              <FormControlLabel
                label="All"
                value="All"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  < Checkbox
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}
                    style={{
                      transform: "rotate(-1deg)",
                      color: "white",
                      position: "relative",
                      right: "-40px",
                      // backgroundColor: 'white'
                    }}

                    checked={selectedCategory === "All"}

                    disableRipple


                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                label="E-Commerce"
                value="E-Commerce"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}
                    style={{
                      // transform: 'rotate(-90deg)',
                      color: "white",
                      position: "relative",
                      right: "-40px",
                      // backgroundColor: 'white'
                    }}

                    checked={selectedCategory === "E-Commerce"}
                  // disableRipple
                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                label="Food And Beverage"
                value="FoodAndBeverage"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}
                    style={{
                      // transform: 'rotate(-90deg)',
                      color: "white",
                      position: "relative",
                      right: "-40px",
                      // backgroundColor: 'white'
                    }}

                    checked={selectedCategory === "FoodAndBeverage"}
                  // disableRipple
                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                label="Exclusive"
                value="Exclusive"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}

                    checked={selectedCategory === "Exclusive"}
                    style={{
                      // transform: 'rotate(-90deg)',
                      color: "white",
                      position: "relative",
                      right: "-40px",
                      // backgroundColor: 'white'
                    }}
                  // disableRipple
                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                label="Time Limit"
                value="TimeLimit"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  <Checkbox
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}
                    style={{
                      //   transform: 'rotate(-1deg)',
                      color: "white",
                      position: "relative",
                      right: "-40px",
                      //   backgroundColor: 'white'
                    }}

                    checked={selectedCategory === "TimeLimit"}
                  // disableRipple
                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
              <FormControlLabel
                label="Offers"
                value="Offers"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                control={
                  <Checkbox
                    checked={selectedCategory === "Offers"}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleClose();
                    }}
                    style={{
                      //   transform: 'rotate(-1deg)',
                      //   backgroundColor: '#0C5BA0',
                      color: "white",
                      position: "relative",
                      right: "-40px",
                    }}
                  // disableRipple
                  />
                }
                labelPlacement="start"
                classes={{
                  label: classes.checkboxLabel,
                }}
              />
            </FormGroup>
          </FormControl>
          </Box>
        )}
        {activeTab === 2 && (
          <Box p={2}>
            <List>
              <ListItem
                button

                className={classes.listItem}
                onClick={() => {handleSortMethod("new");
                  handleClose();
                }}
              >
                <ListItemText primary="New" />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {handleSortMethod("popular");
                handleClose();
                }}
              >
                <ListItemText primary="Popular" />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {handleSortMethod("lowToHigh");
                  handleClose();
                  }}
              >
                <ListItemText primary="Price Low To High" />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {handleSortMethod("highToLow");
                  handleClose();
                  }}
              >
                <ListItemText primary="Price High To Low" />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {handleSortMethod("AtoZ");
                  handleClose();
                  }}
              >
                <ListItemText primary="A To Z" />
              </ListItem>
              <ListItem
                button
                className={classes.listItem}
                onClick={() => {handleSortMethod("ZtoA");
                  handleClose();
                  }}
              >
                <ListItemText primary="Z To A" />
              </ListItem>
            </List>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
