// import React from 'react'
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { PieChart, Pie, Cell } from 'recharts';
import axios from 'axios';
import { Grid, Typography, Card, CardContent } from '@material-ui/core';
import Button from "@mui/material/Button";
import { CSVLink, CSVDownload } from "react-csv";
import { FaUsers, FaSignInAlt } from 'react-icons/fa';
import Chart from 'react-apexcharts';
import Flag from '../assets/images/flag.png';
import dashboardbg1 from '../assets/images/dashboardbg1.png';
import dashboardbg2 from '../assets/images/dashboardbg2.png';
import dashboardbg3 from '../assets/images/dashboardbg3.png';
import dashboardbg4 from '../assets/images/dashboardbg4.png';
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";
import icon4 from "../assets/images/icon4.png";
import electronic_icon from "../assets/images/electronic_icon.png";
import sports_icon from "../assets/images/sports_icon.png";
import health_icon from "../assets/images/health_icon.png";
import clothes_icon from "../assets/images/clothes_icon.png";
import './Dashboard.scss';
import { TextField, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


import {
    getLoginCountApi, getUserCountApi, getPitchCountApi, getRedeemedRunApi, getPollCountApi,
    getAllRunsCreated, GetAllUsers, GetAllUsersDetail, getAllUsersRuns, getOnlineUsers, noOfUsersVisitingDeals, totalHitsForDealsToday,
    downloadDealVisiterDetails, downloadRunsGivenByUserDetails, thisMonthUserCreationCount, thisYearUserCreationCount, thisWeekUserCreationCount, todayUserCreationCount,
    totalRunsGivenByAllUsersToday, pitchCreationCount, totalRunsGivenToAllUsersToday, totalRunsGivenToAllUsersThisYear,
    totalRunsGivenToAllUsersThisMonth, totalUsersWhoCreatedPitchApi,
    totalRunsGivenToAllUsersThisWeek, redeemedRunsToday, redeemedRunsThisYear, redeemedRunsThisMonth, redeemedRunsThisWeek,
    pollCreationCountToday, pollCreationCountThisYear, pollCreationCountThisMonth, pollCreationCountThisWeek, dealVisitToday, dealVisitThisWeek, dealVisitThisMonth, dealVisitThisYear
} from './DashboardSevics';




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const headers = [
    { label: "User Id", key: "userId" },
    { label: "User Name", key: "userName" },
    { label: "dealVisitCount", key: "dealVisitCount" },
    { label: "Mobile", key: "mobileNo" },
    { label: "Email", key: "email" },
];

const headers1 = [
    { label: "User Id", key: "userId" },
    { label: "User Name", key: "userName" },
    { label: "totalRunsGiven", key: "totalRunsGiven" },
    { label: "Mobile", key: "mobileNo" },
    { label: "Email", key: "email" },
];
export default function Dashboard() {
    const [Users, setUsers] = useState([]);
    const [TotalUsers, setTotalUsers] = useState(0);
    const [todayLoginCount, setTodayLoginCount] = useState(0);
    const [thisMonthLoginCount, setThisMonthLoginCount] = useState(0);
    const [thisYearLoginCount, setThisYearLoginCount] = useState(0);
    const [thisWeekLoginCount, setThisWeekLoginCount] = useState(0);
    const [loginCountSinceIpl, setLoginCountSinceIpl] = useState(0);
    const [activeTab, setActiveTab] = useState('Today')
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [usersWhoCreatedPitchToday, setusersWhoCreatedPitchToday] = useState(0);
    const [usersWhoCreatedPitchThisWeek, setusersWhoCreatedPitchThisWeek] = useState(0);
    const [usersWhoCreatedPitchThisMonth, setusersWhoCreatedPitchThisMonth] = useState(0);
    const [usersWhoCreatedPitchThisYear, setusersWhoCreatedPitchThisYear] = useState(0);
    const [usersWhoCreatedPitchSinceIpl, setusersWhoCreatedPitchSinceIpl] = useState(0);
    const [userCreatedMonthData, setUserCreatedMonthData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [loginCreatedMonthData, setLoginCreatedMonthData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [redeemedRuns, setRedeemedRuns] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [redeemedDealsCount, setRedeemedDealsCount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [startDate, setStartDate] = useState(null);
    const [countRedeemDealAndRun, setCountRedeemDealAndRun] = useState(0)
    const [productMarketplaceCount, setProductMarketplaceCount] = useState(0)
    const [categoryData, setCategoryData] = useState([])
    const [topProductsData, setTopProductsData] = useState([])
    const accessToken = localStorage.getItem("token");
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userTabData, setUserTabData] = useState(0)
    const [loginTabData, setLoginTabData] = useState(0)

    const headers = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };


    //no of users who created deal
    const usersWhoCreatedPitchTodayCall = () => {
        const now = new Date();
        const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

        // Format the start and end dates as required by your API
        const formattedStartDate = twentyFourHoursAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchToday(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const thisMonthUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        const formattedStartDate = thirtyDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchThisMonth(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisYearUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const currentYear = now.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const endOfYear = new Date(currentYear, 11, 31);

        const formattedStartDate = startOfYear.toISOString();
        const formattedEndDate = endOfYear.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchThisYear(data.data)
            }).catch((error) => {
                console.log('error in year pitch calll', error)
            })
    }

    const thisWeekUsersWhoCreatedPitchCall = () => {

        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

        const formattedStartDate = sevenDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchThisWeek(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const totalUsersWhoCreatedPitchSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 22); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        totalUsersWhoCreatedPitchApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setusersWhoCreatedPitchSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }
    //login
    const todayLoginCountCall = () => {
        const now = new Date();
        const twentyFourHoursAgo = new Date(now - 24 * 60 * 60 * 1000);

        // Format the start and end dates as required by your API
        const formattedStartDate = twentyFourHoursAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setTodayLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const thisMonthLoginCountCall = () => {

        const now = new Date();
        const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

        const formattedStartDate = thirtyDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setThisMonthLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisYearLoginCountCall = () => {

        const now = new Date();
        const currentYear = now.getFullYear();
        const startOfYear = new Date(currentYear, 0, 1);
        const endOfYear = new Date(currentYear, 11, 31);

        const formattedStartDate = startOfYear.toISOString();
        const formattedEndDate = endOfYear.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setThisYearLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }

    const thisWeekLoginCountCall = () => {

        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

        const formattedStartDate = sevenDaysAgo.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setThisWeekLoginCount(data.data)
            }).catch((error) => {
                console.log(error)
            })
    }
    const loginCountSinceIplCall = () => {
        const now = new Date();
        const startDate = new Date(2023, 2, 31); // Months are zero-based, so March is represented as 2
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = now.toISOString();
        getLoginCountApi(formattedStartDate, formattedEndDate)
            .then((data) => {
                setLoginCountSinceIpl(data.data)
            }).catch((error) => {
                console.log(error)
            })

    }




    const getAllProductsCount = async () => {

        let response = await axios.get(global.config.ROOTURL.prod + `/marketplace/product/getAllProducts?fromDate=${fromDate}&toDate=${toDate}`, headers)
        setProductMarketplaceCount(response.data.length === 0 ? 0 : response.data.length)
    }

    // 7 5 7 2 8 3
    // 14 16 20 10 19 13 5

    const getUserAndLoginData = async () => {
        try {
            const now = new Date()
            const thisYear = now.getFullYear()
            const thisMonth = now.getMonth()
            let startDate;
            let endDate;
            if (activeTab === 'Today') {
                startDate = new Date(now.getTime() - 1000 * 60 * 60 * 24)
                endDate = new Date()
                // setUserCreatedMonthData([0,0,0,0,0,0,0,0,0,0,0,0])
                // setLoginCreatedMonthData([0,0,0,0,0,0,0,0,0,0,0,0])
            } else if (activeTab === 'Weekly') {
                startDate = new Date(now.getTime() - 1000 * 60 * 60 * 24 * 7)
                endDate = new Date()
            }

            else if (activeTab === 'Monthly') {
                startDate = new Date(thisYear, thisMonth, 1)
                endDate = new Date(thisYear, thisMonth, thisMonth === 1 ? 28 : thisMonth === 0 || 2 || 4 || 6 || 7 || 9 || 11 ? 31 : 30)
            }
            else if (activeTab === 'This year') {
                startDate = new Date(thisYear, 0, 1)
                endDate = new Date(thisYear, 11, 31)
            }
            else if (activeTab === 'This IPL') {
                startDate = new Date(thisYear, 2, 1)
                endDate = new Date(thisYear, 11, 31)
            }
            const formattedStartDate = startDate.toISOString();
            const formattedEndDate = endDate.toISOString();

            let usersArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            let loginArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            const usersResponse = await axios.get(global.config.ROOTURL.prod + `/auth/userCreatedData/${formattedStartDate}/${formattedEndDate}`, headers)
            let userData = usersResponse.data
            for (let i = 0; i < userData.length; i++) {
                let month = parseInt(userData[i]._id)
                usersArray[month - 1] = userData[i].userCount
            }

            const loginResponse = await axios.get(global.config.ROOTURL.prod + `/auth/userLoginHistory/totalUsersWhoLoggedIn/${formattedStartDate}/${formattedEndDate}`, headers)
            let loginData = loginResponse.data
            for (let i = 0; i < loginData.length; i++) {
                let month = parseInt(loginData[i].month)
                loginArray[month - 1] = loginData[i].distinctUsers
            }
            const totalUsersTabResponse = usersResponse.data.reduce((acc, cur) => { return acc + cur.userCount }, 0)
            const totalLoginTabResponse = loginResponse.data.reduce((acc, cur) => { return acc + cur.distinctUsers }, 0)
            
            setUserTabData(totalUsersTabResponse)
            setLoginTabData(totalLoginTabResponse)
            setUserCreatedMonthData(usersArray)
            setLoginCreatedMonthData(loginArray)

        } catch (err) {
            console.log(err)
        }
    }

    const getAllRedeemedRunAndCount = async () => {
        try {
            const now = new Date();
            const currentYear = now.getFullYear()
            const startOfYear = new Date(currentYear, 0, 1);
            const endOfYear = new Date(currentYear, 11, 31);
            const formattedStartDate = startOfYear.toISOString();
            const formattedEndDate = endOfYear.toISOString();
            const response = await axios.get(global.config.ROOTURL.prod + `/dealredeemdetails/getAllRedeemedRunAndCount/${formattedStartDate}/${formattedEndDate}`, headers)

            let runArray = []
            let countArray = []

            for (let i = 0; i < response.data.length; i++) {
                let redeemdeal = response.data[i]
                runArray.push(redeemdeal.totalRedeemedRun)
                countArray.push(redeemdeal.count)
            }

            setRedeemedRuns(runArray)
            setRedeemedDealsCount(countArray)
        } catch (err) {
            console.log(err)
        }
    }

    const fetchRedeemDealsAndRunsCount = async () => {
        try {
            const response = await axios.get(global.config.ROOTURL.prod + `/dealredeemdetails/getRedeemDetailsCount?fromDate=${fromDate}&toDate=${toDate}`, headers)
            setCountRedeemDealAndRun(response.data)

        } catch (err) {
            console.log(err)
        }
    }

    const getCategoryAndItsCount = async () => {
        try {
            const categoryResponse = await axios.get(global.config.ROOTURL.prod + `/marketplace/product/getCategoryAndCount`, headers)
            setCategoryData(categoryResponse.data)
        } catch (err) {
            console.log("Error in fetching Category Data", err)
        }
    }

    const getTopTrendingProducts = async () => {
        try {
            const productsResponse = await axios.get(global.config.ROOTURL.prod + `/marketplace/product/getTrendingProducts`, headers)
            setTopProductsData(productsResponse.data.trendingProducts)
        } catch (err) {
            console.log('Error in fetching the Top trending products', err)
        }
    }

    const getTotalRevenue = async () => {
        try {
            const totalRevenueResult = await axios.get(global.config.ROOTURL.prod + `/pay/totalRevenue?fromDate=${fromDate}&toDate=${toDate}`, headers)
            setTotalRevenue(totalRevenueResult.data.totalSubscriptionRevenue)
        } catch (err) {
            console.log("Error in fetching the revenue", err)
        }
    }

    useEffect(() => {
        getUserAndLoginData()
    }, [activeTab])

    useEffect(() => {
        getTotalRevenue()
        GetAllUsers(fromDate, toDate)
            .then((data) => {
                setTotalUsers(data.data.totalCount)
            })
            .catch((error) => { console.log(error) })
        fetchRedeemDealsAndRunsCount()
        getAllProductsCount()
    }, [toDate, fromDate])
    useEffect(() => {
        getTotalRevenue()
        getAllRedeemedRunAndCount()
        getCategoryAndItsCount()
        getTopTrendingProducts()
        usersWhoCreatedPitchTodayCall()
        thisMonthUsersWhoCreatedPitchCall()
        thisYearUsersWhoCreatedPitchCall()
        totalUsersWhoCreatedPitchSinceIplCall()
        thisWeekUsersWhoCreatedPitchCall()
        todayLoginCountCall();
        thisYearLoginCountCall();
        thisMonthLoginCountCall();
        thisWeekLoginCountCall();
        loginCountSinceIplCall();
    }, [])

    const Card = styled(Box)(({ theme }) => ({
        padding: theme.spacing(3),
        borderRadius: '15px !important',
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        //justifyContent: 'center',
        color: 'black',
        backgroundColor: theme.palette.primary.main,
        background: 'white',
        boxShadow: 'none !important',
        marginBottom: '6px',
        minWidth: 200,
        minHeight: 120,
        '&:nth-of-type(2)': {
            backgroundColor: theme.palette.secondary.main,
            background: 'linear-gradient(135deg, #7b4397 0%, #dc2430 100%)',
        },
        '&:nth-of-type(3)': {
            backgroundColor: theme.palette.info.main,
            background: 'linear-gradient(135deg, #43cea2 0%, #185a9d 100%)',
        },
        '&:nth-of-type(4)': {
            backgroundColor: theme.palette.warning.main,
            background: 'linear-gradient(135deg, #f7971e 0%, #ffd200 100%)',
        },
    }));

    const data = [
        { title: 'Total Revenue', value: `₹ ${totalRevenue}`, change: '+10%', icon: icon1, design: dashboardbg1, backgroundColor: '' },
        { title: 'Total Users', value: TotalUsers, change: '+15%', icon: icon2, design: dashboardbg2, background: '' },
        { title: 'Redeemed Runs & Deals', value: countRedeemDealAndRun, change: '-25%', icon: icon3, design: dashboardbg3, backgroundColor: '' },
        { title: 'Total Product (Marketplace)', value: productMarketplaceCount, change: '0%', icon: icon4, design: dashboardbg4, backgroundColor: '' },
    ];

    const StyledCard = styled(Card)(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        borderRadius: theme.shape.borderRadius,
    }));

    const tabs = ['Today', 'Weekly', 'Monthly', 'This year', 'This IPL']

    const dashboardData = {
        series: [
            {
                name: 'Users',
                data: userCreatedMonthData
            },
            {
                name: 'Login',
                data: loginCreatedMonthData
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                title: {
                    text: 'Value'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            colors: ['#883DCF', '#F98550']
        },
    };

    const dashboardRunsAndDeals = {
        series: [
            {
                name: 'Runs',
                data: redeemedRuns
            },
            {
                name: 'Deals',
                data: redeemedDealsCount
            }
        ],
        options: {
            chart: {
                type: 'line',
                height: 350
            },
            stroke: {
                curve: 'smooth'
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 5,
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                title: {
                    text: 'Value'
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            colors: ['#883DCF', '#F98550']
        },
    };

    const dashboardPitchesData = [
        { time: 'This Week', total_number: usersWhoCreatedPitchThisWeek, color: '#883DCF' },
        { time: 'This Month', total_number: usersWhoCreatedPitchThisMonth, color: '#2BB2FE' },
        { time: 'This Year', total_number: usersWhoCreatedPitchThisYear, color: '#F9C80E' },
        { time: 'Since IPL', total_number: usersWhoCreatedPitchSinceIpl, color: '#F86624' }
    ]

    const dashboardUserGrowthData = [
        { country: "India", user_count: "1,240", percentage: "80%" },
    ]

    const chartData = [
        { name: 'Weekly', value: usersWhoCreatedPitchThisWeek },
        { name: 'Monthly', value: usersWhoCreatedPitchThisMonth },
        { name: 'Yearly', value: usersWhoCreatedPitchThisYear },
        { name: 'Since IPL', value: usersWhoCreatedPitchSinceIpl },
    ];

    const COLORS = ['#883DCF', '#2BB2FE', '#F9C80E', '#F86624'];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h1 style={{ color: '#0C5BA0' }}>Welcome Admin</h1>
                    <p style={{ color: '#777980' }}>Users and activity metrics</p>
                </div>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack direction="row" spacing={2}>
                        <DatePicker
                            label="From Date"
                            value={fromDate === '' ? null : fromDate}
                            onChange={(newValue) => setFromDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate === '' ? null : toDate}
                            onChange={(newValue) => setToDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </div>
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>

                        <div style={{ backgroundImage: `url(${item.design})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100%', width: '100%', color: 'white', padding: '30px ', borderRadius: '12px' }}>
                            <Typography variant="h5" component="div">
                                <img src={item.icon} alt="icons" />
                            </Typography>
                            <Typography variant="h6" component="div">
                                {item.title}
                            </Typography>
                            <Typography variant="h5" component="div" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '3px 0px' }}>
                                {item.value}
                            </Typography>
                        </div>

                    </Grid>
                ))}
            </Grid>
            <Box>
                <Grid container spacing={2}>

                    {/* First Column */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>

                            {/* First Row - User Creation */}
                            <Grid item xs={12}>
                                <StyledCard >
                                    <CardContent>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <h3>User Creation</h3>
                                                <p>Our Users</p>
                                            </div>
                                            <div className='tab-navigation'>
                                                {tabs.map((tab) => (
                                                    <button
                                                        key={tab}
                                                        className={`tab ${activeTab === tab ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setActiveTab(tab)

                                                        }}>
                                                        {tab}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '30px', gap: '120px' }}>
                                            <div className='dashboard-card'>
                                                <div className='icon'>
                                                    <FaUsers size={24} color="#9b59b6" />
                                                </div>
                                                <div className='details'>
                                                    <div>Users</div>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <h4 style={{ paddingRight: '10px' }}>{userTabData}</h4>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='dashboard-card'>
                                                <div className='icon'>
                                                    <FaSignInAlt size={24} color="#9b59b6" />
                                                </div>
                                                <div className='details'>
                                                    <div>User Login</div>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <h4 style={{ paddingRight: '10px' }}>{loginTabData}</h4>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bar-chart">
                                            <Chart
                                                options={dashboardData.options}
                                                series={dashboardData.series}
                                                type="bar"
                                                height={350}
                                            />
                                        </div>
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Second Row - Runs & Deals */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent>
                                        <div>
                                            <h3>Runs & Deals </h3>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px 0' }}>
                                                <p>Redeemed Runs and Deals</p>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <span className='dashboard-dots runs'>12</span>
                                                    <p>Runs</p>
                                                    <span className='dashboard-dots deals'>21</span>
                                                    <p>Deals</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line-chart">
                                            <Chart
                                                options={dashboardRunsAndDeals.options}
                                                series={dashboardRunsAndDeals.series}
                                                type="line"
                                                height={350}
                                            />
                                        </div>
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Third Row - Top Product and Top Category */}
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <StyledCard>
                                            <CardContent>
                                                <div className='dashboard-margin'>
                                                    <h3>Top Product</h3>
                                                    <p>Top Product in This Month</p>
                                                </div>
                                                <div>
                                                    {topProductsData.map((product, index) => (
                                                        <div key={index} className='dashboard-product'>
                                                            <div className='dashboard-product-icon'>
                                                                <div>12</div>
                                                            </div>
                                                            <div className='dashboard-product-feature'>
                                                                <div className='dashboard-product-text'>
                                                                    <h3>{product.title}</h3>
                                                                    <p>{product.category}</p>
                                                                </div>
                                                                <div>
                                                                    {product.runs}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <StyledCard>
                                            <CardContent>
                                                <div className='dashboard-margin'>
                                                    <h3>Top Category</h3>
                                                    <p>Top Category in This Month</p>
                                                </div>
                                                <div>
                                                    {categoryData.map((category, index) => (
                                                        <div key={index} className='dashboard-product'>
                                                            <div className='dashboard-product-icon'>
                                                                <img src={health_icon} alt='icon' />
                                                            </div>
                                                            <div className='dashboard-product-feature'>
                                                                <div className='dashboard-product-text'>
                                                                    <h3>{category._id}</h3>
                                                                </div>
                                                                <div className='dashboard-category-feature'>
                                                                    {category.count}
                                                                    {/* <span className='dashboard-category-growth' style={{ backgroundColor: category.count < 0 ? '#FEECEE' : category.count === 0 ? '#F0F1F3' : '#E9FAF7', color: category.count < 0 ? '#EB3D4D' : category.count === 0 ? '#4A4C56' : '#1A9882' }}>{category.count > 0 ? `+${category.count}` : category.count}%</span> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </CardContent>
                                        </StyledCard>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                    {/* Second Column */}
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>

                            {/* First Row - Users Creating Pitches */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent style={{ position: 'relative' }}>
                                        <div className='dashboard-margin'>
                                            <h3>Users Creating Pitches</h3>
                                        </div>
                                        <div>
                                            <PieChart width={300} height={300}>
                                                <Pie
                                                    data={chartData}
                                                    dataKey="value"
                                                    cx={150}
                                                    cy={150}
                                                    innerRadius={125}
                                                    outerRadius={140}
                                                    paddingAngle={0}
                                                    startAngle={-180}
                                                    endAngle={180}
                                                    cornerRadius={0}
                                                >
                                                    {data.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                            <div>
                                                <span style={{ position: 'absolute', top: '216px', left: '89px' }}>Pitch created Today</span>
                                            </div>
                                            {dashboardPitchesData.map((pitch, index) => (
                                                <div key={index} className='dashboard-product'>
                                                    <div className='dashboard-pitch-icon' style={{ backgroundColor: `${pitch.color}`, color: `${pitch.color}` }}>
                                                        <div>12</div>
                                                    </div>
                                                    <div className='dashboard-product-feature'>
                                                        <div className='dashboard-product-text'>
                                                            <h3>{pitch.time}</h3>
                                                        </div>
                                                        <div className='dashboard-category-feature'>
                                                            {pitch.total_number}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                            {/* Second Row - User Growth */}
                            <Grid item xs={12}>
                                <StyledCard>
                                    <CardContent>
                                        <div className='dashboard-margin'>
                                            <h3>Users Growth</h3>
                                            <p>Based on Country</p>
                                        </div>
                                        <div>
                                            {dashboardUserGrowthData.map((user, index) => (
                                                <div key={index} className='dashboard-product'>
                                                    <div>
                                                        <img src={Flag} style={{ height: '34px', width: '34px', marginRight: '6px' }}></img>
                                                    </div>
                                                    <div className='dashboard-product-feature'>
                                                        <div className='dashboard-product-text'>
                                                            <h3>{user.country}</h3>
                                                            <p>{user.user_count} Users</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </CardContent>
                                </StyledCard>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Box>

        </Box>
    )
}
