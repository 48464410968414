import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { getStorageItem } from "../../utils/sessionStorage";
import AllEvents from './events';
import './index.scss';

const Events = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [allEvents, setAllEvents] = useState([]);
  useEffect(() => {
    if (accessToken) {
      const options = {
        method: "GET",
        url: global.config.ROOTURL.prod + "/events/getAll/1/5?ongoing=true",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          setAllEvents([...data].slice(0, 3));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [])
  return (
    <Box sx={{ marginTop: "20px" }} backgroundColor="#ffffff" borderRadius="28px" paddingY={2} paddingX={2} minHeight="300px" paddingBottom={'0px'} className="event-small-wrapper">
      <Typography variant="p" fontWeight={600} fontSize={18} letterSpacing={0.1} color="#1A1919" fontFamily="Poppins">Events</Typography>
      <hr color="#F1F1F5" height="1px" className="line" />
      <AllEvents allEvents={allEvents} />
    </Box>
  )
}

export default Events