import React from "react";
import { useMediaQuery } from "react-responsive";
import ResponsiveChat from "./MobileChat/ResponsiveChats";
import NewGroupChat from "./GroupChat";

const Dugout = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <div className="dugout">
      {isMobile ? <ResponsiveChat /> : <NewGroupChat />}
    </div>
  );
};

export default Dugout;
