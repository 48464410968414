import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import GuestLoginPopup from "../../components/preview/Popup";
import "../carousel/index.scss";
import { useState, useEffect } from "react";

const MyCarousel = () => {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  const [carousel, setCarousel] = useState(null);

  const getCarousels = async () => {
    
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/carousel/getAllGuest/PitchCarousel/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        
      },
    };
    axios(options)
      .then(({ data }) => {
        const sortedCarousel = data.sort((a, b) => a.priority - b.priority); // Sorted based on priority
        setCarousel(sortedCarousel);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getCarousels();
  }, []);

  return (
    <>
    <div className="fix-width">
      <Carousel
        dynamicHeight={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showThumbs={false}
      >
        {carousel ? (
          carousel.map((data, index) => (
            <div
            onClick={handleOpen}
              key={index}
            >
              {(
                data.name.endsWith('.gif') || data.name.endsWith('.png') || data.name.endsWith('.jpg') ?  ( 
                  <img src={data.name} alt="Pitch Carousel" style={{height:"35vh"}} loading="lazy" />
                ) : (
                  <video key={index} autoPlay loop muted style={{ width: '100%',height:"35vh",objectFit: 'fill' }}>
                    <source src={data.name} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) 
              )}
            </div>
          ))
        ) : (
          <div>
            <img
              alt="carousel"
              src={""}
              className="carouserImage"

            />
          </div>
        )}
      </Carousel>
    </div>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  );
};

export default MyCarousel;
