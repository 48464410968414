import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { X, Send } from "lucide-react";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import "./SendInviteRequest.scss";

const SendInviteRequest = ({ open, handleClose, friend }) => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");

  const handleSendRequest = async () => {
    try {
      const friendId = friend._id;
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/groupchat/sendFriendRequest/${userId}/${friendId}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      handleClose();
    } catch (error) {
      console.error("Error sending friend request:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="send-invite-modal"
      aria-describedby="send-invite-description"
    >
      <Box className="send-invite-modal">
        <button className="close-button" onClick={handleClose}>
          <X />
        </button>
        <div className="friend-info">
          <img
            src={friend.profilePhoto || "https://via.placeholder.com/100"}
            alt={`${friend.firstName}'s profile`}
            className="profile-photo"
          />
          <h2>{`${friend.firstName} ${friend.lastName}`}</h2>
        </div>
        <p className="invite-text">
          Send a friend request to start a conversation with {friend.firstName}?
        </p>
        <button className="send-button" onClick={handleSendRequest}>
          <Send size={20} />
          Send Request
        </button>
      </Box>
    </Modal>
  );
};

export default SendInviteRequest;
