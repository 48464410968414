import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { Search } from "lucide-react";
import { X } from "lucide-react";
import { Divider } from "@mui/material";
import { getStorageItem } from "../../../utils/sessionStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
  height: "700px",
};

const ParticipantModal = ({
  openParticipantModal,
  handleCloseParticipantModal,
  participants,
}) => {
  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search input

  // Filter participants based on the search query
  const filteredParticipants = participants.filter((user) =>
    `${user.firstName} ${user.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      open={openParticipantModal}
      onClose={handleCloseParticipantModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "20px" }}>Participants</p>
          <p
            style={{
              backgroundColor: "#FFF2E2",
              padding: "5px",
              borderRadius: "7px",
              cursor: "pointer",
            }}
          >
            <X onClick={handleCloseParticipantModal} />
          </p>
        </div>

        <div
          style={{
            display: "flex",
            padding: "10px",
            border: "0.5px solid gray",
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <Search />
          <input
            type="text"
            style={{
              outline: "none",
              border: "none",
              marginLeft: "10px",
              width: "230px",
            }}
            placeholder="Search participants"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)} // Update the search query state
          />
        </div>

        <div style={{ marginTop: "10px" }}>
          {filteredParticipants.map((user, ind) => (
            <React.Fragment key={ind}>
              <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
                <img
                  src={user.profilePhoto}
                  width={48}
                  height={48}
                  style={{
                    borderRadius: "10px",
                  }}
                  alt={`${user.firstName} ${user.lastName}`}
                />
                <p style={{ color: "#45464E" }}>
                  {user.firstName} {user.lastName}
                </p>
              </div>
              <Divider />
            </React.Fragment>
          ))}
        </div>
      </Box>
    </Modal>
  );
};

export default ParticipantModal;
