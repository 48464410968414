import React, { useState, useEffect, useCallback } from "react";
import Modal from "@mui/material/Modal";
import Box from "@material-ui/core/Box";
import { Search, X } from "lucide-react";
import { Divider } from "@mui/material";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import SendInviteRequest from "./SendInviteRequest";
import "./NewChatModal.scss";

const NewChatModal = ({ open, handleClose, getFriends }) => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [query, setQuery] = useState("");
  const [followers, setFollowers] = useState([]);
  const [filteredFollowers, setFilteredFollowers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageSize] = useState(20);
  const [pageNo] = useState(1);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const getFollowers = useCallback(() => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: `${global.config.ROOTURL.prod}/auth/get-followers-user/${pageNo}/${pageSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setFollowers(data);
        setFilteredFollowers(data);
      })
      .catch((error) => {
        console.log("Error fetching followers:", error);
      });
  }, [accessToken, pageNo, pageSize]);

  useEffect(() => {
    getFollowers();
  }, [getFollowers]);

  const handleInputChange = (e) => {
    const searchQuery = e.target.value;
    setQuery(searchQuery);

    const filtered = followers.filter(
      (follower) =>
        follower.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        follower.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFollowers(filtered);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    createConversation(user._id);
  };

  const createConversation = async (friendId) => {
    if (!userId || !friendId) return;
    try {
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/groupchat/createConversation`,
        { userId, friendId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      if (response.data === "Conversation Not Found") {
        setShowInviteModal(true);
      } else {
        getFriends();
        handleClose();
      }
    } catch (error) {
      console.error("Error creating conversation:", error);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="new-chat-modal">
          <div className="modal-header">
            <h2>Select Friend</h2>
            <X onClick={handleClose} className="close-icon" />
          </div>

          <p className="subheader">Search a user to invite to the group</p>

          <div className="search-container">
            <Search />
            <input
              type="text"
              value={query}
              onChange={handleInputChange}
              placeholder="Search followers..."
            />
          </div>

          <div className="followers-list">
            {filteredFollowers.length > 0 ? (
              filteredFollowers.map((follower) => (
                <React.Fragment key={follower._id}>
                  <div
                    className="follower-item"
                    onClick={() => handleUserClick(follower)}
                  >
                    <img
                      src={
                        follower.profilePhoto ||
                        "https://via.placeholder.com/48"
                      }
                      alt={`${follower.firstName}'s profile`}
                    />
                    <p>{`${follower.firstName || "Unknown"} ${
                      follower.lastName
                    }`}</p>
                  </div>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <p className="no-results">No followers found</p>
            )}
          </div>
        </Box>
      </Modal>

      {showInviteModal && (
        <SendInviteRequest
          open={showInviteModal}
          handleClose={() => setShowInviteModal(false)}
          friend={selectedUser}
        />
      )}
    </>
  );
};

export default NewChatModal;
