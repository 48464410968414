import React, { useState } from 'react';
import { useMediaQuery } from "@mui/material";
import Button from '@mui/material/Button';
import "./DealDescription.scss";
import NewDealModal from '../NewDeals/NewDealModal';
import { useEffect } from 'react';
import { getAuthToken } from '../../utils/auth';
import axios from "axios";
import config from '../../config';
import { useContext } from 'react';
import AppContext from '../../context/AppContext';
import { PathAction } from '../../redux/reducers/globalPath';
import { useDispatch } from 'react-redux';
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useParams, useNavigate } from "react-router-dom";
import RedeemedTodayModal from "../NewDeals/AlreadyRedeemedToday";
import UpdateMobileNumber from "./UpdateMobileNumber";
import { toast } from 'react-toastify';
import LoanDescription from './LoanDescription';
import { IoMdClose } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ball from "../../assets/images/marketplace/redball.png";
import RunsBackModal from './RunsBackModal';


const DealDescription = (props) => {
  const mobileView = useMediaQuery("(max-width:920px)");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  dispatch(PathAction.handlePath({ payload: path }));
  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;
  const { checkRedeem, redeemedDeals } = props;
  const { deal } = props;
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const navigate = useNavigate();
  const [userRuns, setUserRuns] = useState(0);
  const [eligible, setEligible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalForRedeemed, setShowModalForRedeemed] = useState(false);
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [redeemedToday, setRedeemedToday] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateMobileNo, setUpdateMobileNo] = useState(false)
  const [openRedeemedModal, setOpenRedeemedModal] = useState(false);
  const [dealDetailsById, setDealDetailsById] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const handleCloseCongratulationPage = () => setHasRedeemed(false);
  const handleUpdateMobileNoModal = () => setUpdateMobileNo(!updateMobileNo);
  const [adModal, setAdModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const { dealId } = useParams();
  const [ad, setAd] = useState(null);
  const [adWatched, setAdWatched] = useState(false);
  const checkUserRuns = (DealRun, TotalRun, discount) => {
    // checking user is eligible to avail deal or not 
    let realRuntoAvialDeal = DealRun * ((100 - discount) / 100)
    if (TotalRun < realRuntoAvialDeal) {
      setEligible(false);
    }
  }
  const [runsBack,setRunsBack] = useState(10)
  const [isSubscriber, setIsSubscriber] = useState(false);

  useEffect(() => {
    checkUserIsSubscriber()
}, []);

  const checkUserIsSubscriber = async () => {
    
    let checkIsSubcriber = {
      method: "GET",
      url: `${config.ROOTURL.prod}/pay/isSubscriber/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(checkIsSubcriber).then((response) => {
      //data
      if (response?.data?.status === "active") {
        setIsSubscriber(true)
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const [openRunsModal, setOpenRunsModal] = useState(false)
  const handleOpenRunModal = () => {
    setOpenRunsModal(true)
  }
  const handleCloseRunModal = () => {
    setOpenRunsModal(false)
  }


  const getRuns = async () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    return axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };

  const getDealByDealId = async () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getDealDetails = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/deal/getDealById/" + dealId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    return axios(getDealDetails)
      .then((response) => {

        setDealDetailsById(response.data);
        // setRunsBack(response.data.runsBack)
        return response?.data;
      })
      .catch((error) => {
        console.log(error);
        return 0;
      });
  };

  const reedemLoanDeal = (name, email, mobile, Loan) => {
    if ((mobile === null || mobile === undefined || !mobile)) {
      setUpdateMobileNo(true);
    }
    else {
      const dealRedeemed = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/LoanredeemDeal",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          dealId,
          name: name,
          email: email,
          mobile: mobile,
          loanAmount: Loan,
          comingSoon: dealDetailsById?.comingSoon
        },
        json: true,
      };
      axios(dealRedeemed)
        .then((response) => {
          if (response.data.message === 'redeemedtoday') {
            setShowModalForRedeemed(true);
            setHasRedeemed(false);
            setOpenRedeemedModal(false)
          }
          else if (response.data.message === "redeemed Data required") {
            setUpdateMobileNo(true);
          }
          else {
            setHasRedeemed(true);
            setRedeemedToday(false)
            navigate("/deal-congo")
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });

    }
  }


  const redeemDeal = (name, email, mobile) => {
    if ((mobile === null || mobile === undefined || !mobile)) {
      setUpdateMobileNo(true);
      // handleToggle();
    }

    // if ((name && email && dealId && mobile!==0 )) {
    else {
      const dealRedeemed = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/redeemDeal",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          dealId,
          name: name,
          email: email,
          mobile: mobile,
          comingSoon: dealDetailsById?.comingSoon
        },
        json: true,
      };

      if (userRuns >= dealDetailsById?.redeemrun) {
        axios(dealRedeemed)
          .then((response) => {
            if (response.data.message === 'redeemedtoday') {
              setShowModalForRedeemed(true);
              setHasRedeemed(false);
              setShowModal(!showModal)
              setRedeemedToday(true)
              setOpenRedeemedModal(false)
              // handleCloseRedeemedModal()
            }
            else if (response.data.message === "redeemed Data required") {
              setUpdateMobileNo(true);
            }
            else {
              setHasRedeemed(true);
              setRedeemedToday(false)

              if(isSubscriber)
              {
                setOpenRunsModal(true)
              }else{
                navigate("/deal-congo")
              }
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              clearStorage();
              navigate("/login");
            }
          });
      } else {
        toast.error("You have insufficient runs to redeem this deal");
      }
    }
  };

  const handleOnClick = () => {
    setAdModal(true);
  };

  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const handleCloseRedeemedToday = () => {
    setShowModalForRedeemed(!showModalForRedeemed)
  }

  useEffect(() => {
    if (dealDetailsById.categories === "TimeLimit") {
      const calculateRemainingTime = () => {
        // console.log("deal?.startDateAndTime,deal?.endDateAndTime", dealDetailsById?.startDateAndTime, dealDetailsById?.endDateAndTime)
        const startTime = new Date(dealDetailsById?.startDateAndTime).getTime();
        const endTime = new Date(dealDetailsById?.endDateAndTime).getTime();

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 5);

        currentDate.setMinutes(currentDate.getMinutes() + 30);
        const currentTime = currentDate.getTime();
        if (currentTime < startTime) {
          setRemainingTime(startTime - currentTime);
        } else if (currentTime > endTime) {
          setRemainingTime(0);
        } else {
          setRemainingTime(endTime - currentTime);
        }
      };


      const interval = setInterval(calculateRemainingTime, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [dealDetailsById?.startDateAndTime, dealDetailsById?.endDateAndTime]);
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    const formattedDays = days.toString().padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    if (days > 0) {
      return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  };

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 5);

  currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
  const calculateOffPrice = (redeemrun, discount) => {

    return Math.round(redeemrun * discount / 100);
  }
  const calculateDiscountedPrice = (redeemrun, discount) => {

    return Math.round(redeemrun - redeemrun * discount / 100);
  }

  const UseEffectHelper = async () => {
    const DealRun = await getDealByDealId();
    const TotalRun = await getRuns();
    setShowFooter(true);
    checkUserRuns(DealRun.redeemrun, TotalRun, DealRun.discount);
  }


  useEffect(() => {
    UseEffectHelper();
    return () => {
      setShowFooter(false);
    };
  }, []);

  useEffect(() => {
    // checkUserRuns();
    return () => {
      setHasRedeemed(false);
    };
  }, [showModal, userRuns]);


  const fetchRandomAd = async () => {
    try {
      const response = await axios.get(`${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      });
      console.log(`${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`);
      if (response?.status === 200) {
        setAd(response?.data);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchRandomAd();
  }, []);

  const handleWatchFullAd = async () => {
    try {
      // Give 1000 runs
      const response = await axios.post(`${global.config.ROOTURL.prod}/auth/given-runs/`, {
        userId: userId, givenRun: 1000
      }, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      const viewIncreaseResponse = await axios.post(
        `${global.config.ROOTURL.prod}/ads/increaseViewCount`,
        {
          _id: ad?._id,
          userId: userId
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        }
      );

      if (response.status === 200 && viewIncreaseResponse.status === 200) {
        toast.success('Yipeee! You just got 1000 runs.');
        setVideoModal(false);
        setShowModal(true);
      }
      else {
        setVideoModal(false);
        toast.error('Some error occured!');
      }
    } catch (error) {
      console.error("Error watching ad:", error);
      setVideoModal(false);
    }
  };


  return (
    <>
      <div style=
        {mobileView ? mobilestyle : style}
        className='main-singlegift-container'
      >
        <div className="deal-giftContainer">
          {/* only visible when user have insufficient run to reedem deal */}

          {/* start */}
          {!eligible &&
            <LoanDescription totalRun={userRuns} dealRun={dealDetailsById?.redeemrun} discount={dealDetailsById?.discount} reedemLoanDeal={reedemLoanDeal} />}
          {/* end  */}


          {dealDetailsById.categories === "TimeLimit" &&
            dealDetailsById?.discount > 0 &&
            new Date(dealDetailsById?.discountStartDateAndTime) <= currentDateTime &&
            new Date(dealDetailsById?.discountEndDateAndTime) >= currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="timer-section">
                <span className="timer-text">Limited Time   </span><span className="timer-text">{formatTime(remainingTime)}</span>

              </div>
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">
                    -{calculateOffPrice(dealDetailsById?.redeemrun, dealDetailsById?.discount)} OFF
                  </span>
                </div>
              </div>
            </div>
          ) : dealDetailsById.categories === "TimeLimit" ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="timer-section">
                <span className="timer-text">Limited Time   </span><span className="timer-text">{formatTime(remainingTime)}</span>

              </div>
            </div>
          ) : dealDetailsById.categories === "Exclusive" &&
            dealDetailsById?.discount > 0 &&
            new Date(dealDetailsById?.discountStartDateAndTime) <= currentDateTime &&
            new Date(dealDetailsById?.discountEndDateAndTime) >= currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text" style={{ marginLeft: "-16px" }}>
                    EXCLUSIVE
                  </span>
                  <span className="overlay-text">
                    -{calculateOffPrice(dealDetailsById?.redeemrun, dealDetailsById?.discount)} OFF
                  </span>
                </div>
              </div>
            </div>
          ) : dealDetailsById.categories === "Exclusive" ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">EXCLUSIVE</span>
                </div>
              </div>
            </div>
          ) : dealDetailsById?.discount > 0 &&
            new Date(dealDetailsById?.discountStartDateAndTime) <= currentDateTime &&
            new Date(dealDetailsById?.discountEndDateAndTime) >= currentDateTime ? (
            <div
              style={{ backgroundImage: `url(${dealDetailsById?.dealImage})` }}
              className="deal-container1"
            >
              <div className="blue-overlay__wrapper1">
                <div className="blue-corner-overlay">
                  <span className="overlay-text">
                    -{calculateOffPrice(dealDetailsById?.redeemrun, dealDetailsById?.discount)} OFF
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <img src={dealDetailsById?.dealImage} alt="" className="deal-giftImage" />
            </div>
          )}


          <div className="deal-giftcontent">
            <div className="deal-reamininggiftcontainer">
              <div
                className="deal-reamaning"
                style={{ width: `${dealDetailsById?.count * 100 / dealDetailsById?.totalDeal}%` }}
              ></div>
            </div>
            <div>
              <p className="deal-giftFont">{dealDetailsById?.name}</p>
            </div>
            <div className="deal-giftbuttoncontent">
              {/* <p className="deal-giftFont">{dealDetailsById?.redeemrun}</p>
             */}
              {dealDetailsById?.discount > 0 && new Date(dealDetailsById?.discountStartDateAndTime) <= currentDateTime &&
                new Date(dealDetailsById?.discountEndDateAndTime) >= currentDateTime
                ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p className="discount-price1">
                    {dealDetailsById?.redeemrun}
                  </p>
                  <p className="redeem-run-css">
                    {calculateDiscountedPrice(dealDetailsById?.redeemrun, dealDetailsById?.discount)} runs
                  </p>
                </div>
                : <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "10px"}}><img src={ball} alt="ball-img" style={{width: "20px"}}/>
                <p className="deal-giftFont" style={{paddingTop: "0px"}}>{dealDetailsById?.redeemrun} runs</p></div>
              }
              <div className='del_btn'>
                <button disabled={!eligible} className={eligible ? " giftButton-deal" : 'giftButton-disable'} onClick={handleOnClick} style={{ textTransform: "none" }}>
                  Redeem Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="deal-footer-description">
          {/* <h6 className='deal-name' style={{marginBottom: "10px"}}>{dealDetailsById?.desc}</h6> */}
          <div className='deal-name' style={{ marginBottom: "10px" }}
            dangerouslySetInnerHTML={{ __html: dealDetailsById?.desc }}
          />
          <p className='footer-paragraph'>
            {`Deals will be only successfully redeemed if you enter a
              valid email id and phone number.Incorrect data will lead to you
              not receiving your deals.`}
          </p>
        </div>

      </div>
      {
        adModal && (
          <div className='deal__ad-modal-wrapper'>
            <div className='deal__ad-modal-content'>
              {
                ad?.dealId === dealId ? <div className='deal__ad-modal-close-icon' onClick={() => setAdModal(false)}>
                  <IoMdClose />
                </div> : <div className='deal__ad-modal-close-icon' onClick={() => {
                  setAdModal(false);
                  setShowModal(true);
                }}>
                  <IoMdClose />
                </div>
              }
              <img src="https://res.cloudinary.com/dndkskewk/image/upload/v1712200846/pgktiqmvbbnahbmpkxxu.gif" alt="gift-voucher" className='deal__ad-modal-content-coupon' />
              {ad?.dealId === dealId ? (
                <>
                  <h1 className='deal__ad-modal-content-heading'>
                    This deal is locked
                  </h1>
                  <p className='deal__ad-modal-content-description'>
                    Play advertisement to redeem this deal.
                  </p>
                </>
              ) : (
                <>
                  <h1 className='deal__ad-modal-content-heading'>
                    Unlock 1000 Runs by Watching an Ad!
                  </h1>
                  <p className='deal__ad-modal-content-description'>
                    Get ready to score big! Watch a quick advertisement to claim an exclusive discount of 1000 runs on this amazing deal!
                  </p>
                </>
              )}
              <button className='deal__ad-modal-content-button' onClick={() => {
                setAdModal(false);
                setVideoModal(true);
              }}>Watch Now</button>
            </div>
          </div>
        )
      }

      {
        videoModal && <div className="modal-backdrop-ad">
          <div className="modal-content-ad">
            <div className="modal-header-ad">
              <h2>{ad?.title}</h2>
              <button className="close-ad" onClick={() => {
                setVideoModal(false);
              }}>
                <AiOutlineCloseCircle />
              </button>
            </div>
            <div className="modal-body-ad">
              <video
                src={ad?.url}
                autoPlay
                // controls
                onEnded={() => {
                  setAdWatched(true);
                  handleWatchFullAd();
                }}
              ></video>
              <p>{ad?.description}</p>
            </div>
          </div>
        </div>
      }
      {
        showModal && <NewDealModal
          handleClose={handleToggle}
          deal={deal}
          open={showModal}
          redeemDeal={redeemDeal}
          hasRedeemed={hasRedeemed}
          handleUpdateMobileNoPopup={handleUpdateMobileNoModal}
        />
      }
      {updateMobileNo &&
        <UpdateMobileNumber open={updateMobileNo} handleClose={handleUpdateMobileNoModal} redeemDeal={redeemDeal} dealId={dealId} />
      }
      {showModalForRedeemed &&
        < RedeemedTodayModal handleClose={handleCloseRedeemedToday} open={showModalForRedeemed} />
      }
      {/* <UnlockModal /> */}
      {
        openRunsModal && <RunsBackModal setOpenRunsModal={setOpenRunsModal}  handleCloseRunModal={handleCloseRunModal} runsBack={runsBack} />
      }
    </>
  )
}

const style = {
  display: "flex",
  position: "relative",
  top: "3rem"
}
const mobilestyle = {
  display: "flex",
  // flexDirection: "column"
}
export default DealDescription
