import React from 'react'
import axios from "axios";
import { getStorageItem } from '../../../utils/sessionStorage';
import { toast } from 'react-toastify';
export default function SinglePhoto(props) {
  const accessToken = getStorageItem("token");
  const handledelete = () => {
    if (!accessToken && !props.data._id) return
    const getAllcover = {
      method: "DELETE",
      url: global.config.ROOTURL.prod + `/coverphoto/deleteById/${props.data._id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
    axios(getAllcover)
      .then((response) => {
        toast.success("Cover Photo deleted");

        props.handleDelete();
      })
      .catch((err) => {
        console.log("error", err)
      });
  }
  return (
    <div className="firstChildcover">{
      (props.data.image && (props.data.image.endsWith('.gif') || props.data.image.endsWith('.png')) ?
        <img src={props.data.image} style={{height:'200px',width:'200px',objectFit:'contain'}}/> : props.data.image.endsWith('.mp4') ? (
          <video autoPlay loop muted style={{ height:'200px',width:'200px',objectFit:'contain' }}>
            <source src={props.data.image} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null

      )}
      <p style={{ cursor: 'pointer' }} onClick={handledelete}>Delete</p>

    </div>
  )
}
