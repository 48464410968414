import { useEffect, useState, useRef } from "react";
import { Button } from "carbon-components-react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Helmet } from "react-helmet";
import {
  Modal,
  Box,
  Avatar,
  Typography,
  InputLabel,
  Input,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import DealModal from "./DealModal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { successToast } from "../../commons/toast";
import DealReedeemedModal from "./DealRedeemedModal";
import RedeemedTodayModal from "./AlreadyRedeemedToday";
import ViewConditionPopup from "../deal-description/UnlockModal";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import * as React from "react";
import validator from "validator";
import { toast } from "react-toastify";
import "./singlegift.scss";
import champLogo from "../../assets/images/newLandingPage/logo.png";
import gifImage from "../../assets/images/giftbox-web.gif";

import logo from "../../assets/images/home/logo/logo.png";
import plusIcon from "./Vector.png";
import giftIcon from "./Gift.png";
import dealsubscribeimg from "../../assets/images/deals/deakcornerimage.png";
import unlockdealicon from "../../assets/images/deals/lockimg.png";
import ball from "../../assets/images/marketplace/redball.png";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

// Check screen width and adjust width accordingly
const screenWidth = window.innerWidth;
if (screenWidth <= 600) {
  // Adjust width for small screens (example: <= 600px)
  style.width = 380;
}

export default function SingleGift(props) {
  const { deal } = props;
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const navigate = useNavigate();
  const [userRuns, setUserRuns] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalForRedeemed, setShowModalForRedeemed] = useState(false);
  const [redeemedToday, setRedeemedToday] = useState(false);
  const [discountPresent, setDiscountPresent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [availabledeal, setavailableDeal] = useState("100");
  const [openRedeemedModal, setOpenRedeemedModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openGiftModal, setOpenGiftModal] = useState(false);
  const handleOpenUserModal = () => setOpenUserModal(true);
  const handleOpenGiftModal = () => setOpenGiftModal(true);
  const handleOpenRedeemedModal = () => setOpenModal(true);
  const handleCloseRedeemedModal = () => setOpenRedeemedModal(false);
  const [viewUnlockCondition, setViewUnlockCondition] = useState(false);
  const [email, setEmail] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [OpenOtp, setOpenOtp] = useState(false);
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const reRef = useRef();

  const [verificationText1, setVerificationText1] = useState("");
  const [verificationText2, setVerificationText2] = useState("");
  const [verificationText3, setVerificationText3] = useState("");
  const [verificationText4, setVerificationText4] = useState("");
  const [disableRedeemBtn, setDisableRedeemBtn] = useState(true);
  const [disableResend, setdisableResend] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isSubscriber, setIsSubscriber] = useState(false);

  const inputReference1 = useRef(null);
  const inputReference2 = useRef(null);
  const inputReference3 = useRef(null);
  const inputReference4 = useRef(null);

  const [openPlus, setOpenPlus] = useState(false);
  const handleOpenPlus = () => setOpenPlus(true);
  const handleClosePlus = () => setOpenPlus(false);

  //UserModel
  const signUpSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setError(false);
    setOpenOtp(false);
    setIsTyping(false);
    const mail = email.toLowerCase();

    if (!validator.isEmail(mail)) {
      setErrorMessage("Please enter a valid email");
      setError(true);
      return;
    }
    //...check email exist
    const isMailExistRes = await axios.post(
      `${global.config.ROOTURL.prod}/auth/check-email-exist`,
      { email: email.toLowerCase() }
    );
    if (isMailExistRes.data) {
      setErrorMessage("Email Already Exist");
      setError(true);
      return;
    }

    const sendOTPRes = await axios.post(
      `
    ${global.config.ROOTURL.prod}/auth/sendOTP`,
      { email: email }
    );

    if (sendOTPRes.status === 200) {
      let data = { email: email };
      setOpenOtp(true);
    }
  };

  const handleOnChangeUserModal = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    setIsTyping(true);
  };

  // Verify OTP UserModel
  useEffect(() => {
    const startTime = () => {
      setTimer(30);
      const myInterval = setInterval(() => {
        setTimer((prevCounter) => prevCounter - 1);
      }, 1000);
      setTimeout(() => {
        setdisableResend(true);
        clearInterval(myInterval);
      }, 30000);
    };
    if (!disableResend) {
      startTime();
    }
  }, [disableResend]);

  useEffect(() => {
    const verifyOTP = async (isAllTextField) => {
      if (!isAllTextField) {
        console.log(
          "hello",
          parseInt(
            `${verificationText1.trim()}${verificationText2.trim()}${verificationText3.trim()}${verificationText4.trim()}`
          )
        );
        const sendOTPRes = await axios.post(
          `
            ${global.config.ROOTURL.prod}/auth/verifyOTP`,
          {
            email: email,
            otp: parseInt(
              `${verificationText1.trim()}${verificationText2.trim()}${verificationText3.trim()}${verificationText4.trim()}`
            ),
          }
        );
        if (sendOTPRes.data) {
          setDisableRedeemBtn(false);
        } else {
          setDisableRedeemBtn(true);
          setErrorMessage("OTP is wrong!");
          inputReference1.current.focus();
        }
      }
    };
    verifyOTP(
      !(
        verificationText1.trim().length === 1 &&
        verificationText2.trim().length === 1 &&
        verificationText3.trim().length === 1 &&
        verificationText4.trim().length === 1
      )
    );
  }, [
    email,
    verificationText1,
    verificationText2,
    verificationText3,
    verificationText4,
  ]);

  const loginSubmit = (otp) => {
    if (!email && !otp) return;
    const option = {
      email: email,
      otp: otp,
    };

    var loginOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/auth/login-with-Otp",

      data: JSON.stringify(option),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      json: true,
    };
    axios(loginOptions)
      .then((response) => {
        if (response.data.data.match) {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user_id", response.data.data.payload.id);
          localStorage.setItem("user_email", response.data.data.payload.email);
          setVerificationText1("");
          setVerificationText2("");
          setVerificationText3("");
          setVerificationText4("");
          navigate("/onboarding");
          setOpenGiftModal(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onRegisterClick = async () => {
    let data = {};
    const otp = parseInt(
      `${verificationText1.trim()}${verificationText2.trim()}${verificationText3.trim()}${verificationText4.trim()}`
    );

    setTimeout(async () => {
      try {
        const sendOTPRes = await axios.post(
          `${global.config.ROOTURL.prod}/auth/sign-Up-WithOtp`,
          {
            email: email,
            otp: otp,
          }
        );

        if (sendOTPRes.data.data === "email exist") {
          setErrorMessage("Email already exists");
        }
        if (sendOTPRes.status === 200) {
          loginSubmit(otp);
          setUserCreated(true);
        }
      } catch (error) {
        console.error("Error during registration:", error);
      }
    }, 5000);
  };

  const resendOTP = async (e) => {
    setdisableResend(false);
    e.preventDefault();
    axios.post(
      `
    ${global.config.ROOTURL.prod}/auth/reSendOTP`,
      { email: email }
    );
    setErrorMessage("resend");
    return false;
  };

  const getRuns = () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {
        console.log("2");
        console.log(error);
      });
  };
  let DealId;
  const getDealId = (id) => {
    DealId = id;
    window.location.href = `/getDealById/${id}`;
  };
  const redeemDeal = (name, email, mobile) => {
    if (name && email && mobile && deal._id) {
      const dealRedeemed = {
        method: "post",
        url: global.config.ROOTURL.prod + "/auth/redeemDeal",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          dealId: deal._id,
          name: name,
          email: email,
          mobile: mobile,
          comingSoon: deal?.comingSoon,
        },
        json: true,
      };

      if (userRuns >= deal.redeemrun) {
        axios(dealRedeemed)
          .then((response) => {
            if (response.data.message === "redeemedtoday") {
              setShowModalForRedeemed(!showModalForRedeemed);
              setShowModal(!showModal);
              setRedeemedToday(true);
              setOpenRedeemedModal(false);
              handleCloseRedeemedModal();
            } else {
              setRedeemedToday(false);
            }
          })
          .then(() => handleToggle())
          .then(() => {
            if (!redeemedToday) {
              setOpenRedeemedModal(true);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              clearStorage();
              navigate("/login");
            }
          });
      }
    }
  };

  const clearUserIdFromStorage = () => {
    sessionStorage.removeItem("user_id");
    localStorage.removeItem("user_id");
  };
  const redeemDealNewUser = async (deal, email) => {
    const userId = getStorageItem("user_id");
    try {
      const dealRedeemedNewUser = {
        method: "post",
        url: global.config.ROOTURL.prod + "/newuser/redeemDealNewUser",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          dealId: deal._id,
          userId: userId,
          userEmail: email,
          dealName: deal.name,
        },
      };

      const response = await axios(dealRedeemedNewUser);
      if (response.status === 201) {
        setHasRedeemed(true);
      }
      return response.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Unauthorized access");
      } else {
        console.error("Error redeeming deal:", error);
        throw error;
      }
    }
  };

  const handleConfirm = (deal, email) => {
    const userId = getStorageItem("user_id");
    if (!userId || !accessToken || isLoading) return;
    setLoading(true);
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        clearUserIdFromStorage();
        setUserCreated(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      if (userCreated) {
        redeemDealNewUser(deal, email);
        handleConfirm();
      }
    }, 4000);
  }, [userCreated]);

  const handleOnClick = () => {
    // handleToggle();
  };

  const handleViewUnlockCondition = () => {
    setViewUnlockCondition(!viewUnlockCondition);
  };

  const handleToggle = () => {
    setShowModal(!showModal);
  };
  const handleCloseRedeemedToday = () => {
    setShowModalForRedeemed(!showModalForRedeemed);
  };
  const navigateToDealDescription = (id) => {
    navigate(`/deal-description/${id}`);
  };
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    if (deal.categories === "TimeLimit") {
      const calculateRemainingTime = () => {
        const startTime = new Date(deal?.startDateAndTime).getTime();
        const endTime = new Date(deal?.endDateAndTime).getTime();

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 5);

        currentDate.setMinutes(currentDate.getMinutes() + 30);
        const currentTime = currentDate.getTime();
        if (currentTime < startTime) {
          setRemainingTime(startTime - currentTime);
        } else if (currentTime > endTime) {
          setRemainingTime(0);
        } else {
          setRemainingTime(endTime - currentTime);
        }
      };
      const interval = setInterval(calculateRemainingTime, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [deal?.startDateAndTime, deal?.endDateAndTime]);
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / (1000 * 60)) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    const formattedDays = days.toString().padStart(2, "0");
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    if (days > 0) {
      return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  };

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() + 5);

  currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
  const calculateOffPrice = (redeemrun, discount) => {
    return Math.round((redeemrun * discount) / 100);
  };

  const calculatePriceAfterDiscount = (redeemrun, discount) => {
    return Math.round(redeemrun - (redeemrun * discount) / 100);
  };

  // const checkForSubscriber = ()=>{
  //   if (deal?.SubscribedUser) {
  //     setIsSubscriber(true)
  //   }else {
  //     setIsSubscriber(false)
  //   }
  // }

  const checkUserIsSubscriber = () => {
    if (!accessToken) return;
    if (!userId) return console.error("userId required");
    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pay/isSubscriber/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        if (response.data?.status === "active") {
          setIsSubscriber(true);
        } else {
          setIsSubscriber(false);
        }
      })
      .catch((error) => {
        console.log("1");
        console.log(error);
      });
  };

  useEffect(() => {
    getRuns();
    // checkForSubscriber()
    checkUserIsSubscriber();
  }, []);
  return (
    <>
      <Helmet>
        <title>Champhunt | Deals</title>
      </Helmet>
      <div className="deal-giftContainer">
        {deal.categories === "TimeLimit" &&
        deal?.discount > 0 &&
        new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
        new Date(deal?.discountEndDateAndTime) >= currentDateTime ? (
          <div
            style={{ backgroundImage: `url(${deal?.dealImage})` }}
            className="deal-container"
          >
            <div className="timer-section">
              <span className="timer-text">Limited Time </span>
              <span className="timer-text">{formatTime(remainingTime)}</span>
            </div>
            <div className="blue-overlay__wrapper">
              <div className="blue-corner-overlay">
                <span className="overlay-text">
                  -{calculateOffPrice(deal?.redeemrun, deal?.discount)} OFF
                </span>
              </div>
            </div>
          </div>
        ) : deal.categories === "TimeLimit" ? (
          <div
            style={{ backgroundImage: `url(${deal?.dealImage})` }}
            className="deal-container"
          >
            <div className="timer-section">
              <span className="timer-text">Limited Time </span>
              <span className="timer-text">{formatTime(remainingTime)}</span>
            </div>
          </div>
        ) : deal.categories === "Exclusive" &&
          deal?.discount > 0 &&
          new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
          new Date(deal?.discountEndDateAndTime) >= currentDateTime ? (
          <div
            style={{ backgroundImage: `url(${deal?.dealImage})` }}
            className="deal-container"
          >
            <div className="blue-overlay__wrapper">
              <div className="blue-corner-overlay">
                <span className="overlay-text" style={{ marginLeft: "-16px" }}>
                  EXCLUSIVE
                </span>
                <span className="overlay-text">
                  -{calculateOffPrice(deal?.redeemrun, deal?.discount)} OFF
                </span>
              </div>
            </div>
          </div>
        ) : deal.categories === "Exclusive" ? (
          <div
            style={{ backgroundImage: `url(${deal?.dealImage})` }}
            className="deal-container"
          >
            <div className="blue-overlay__wrapper">
              <div className="blue-corner-overlay">
                <span className="overlay-text">EXCLUSIVE</span>
              </div>
            </div>
          </div>
        ) : deal?.discount > 0 &&
          new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
          new Date(deal?.discountEndDateAndTime) >= currentDateTime ? (
          <div
            style={{ backgroundImage: `url(${deal?.dealImage})` }}
            className="deal-container"
          >
            <div className="blue-overlay__wrapper">
              <div className="blue-corner-overlay">
                <span className="overlay-text">
                  -{calculateOffPrice(deal?.redeemrun, deal?.discount)} OFF
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img src={deal.dealImage} alt="" className="deal-giftImage" />
          </div>
        )}
        {deal.SubscribedUser && !deal.isSubscriber && (
          <div className="corner-icon">
            <img src={dealsubscribeimg} alt="Corner Icon" className="corner-icon-img"                       onClick={handleOpenPlus}
            />
          </div>
        )}
        {deal?.isLocked && !deal?.unlockedByUser && (
          <button className="lock_main_btn" onClick={handleViewUnlockCondition}>
            <img src={unlockdealicon} alt="lock-icon" className="lock_icon" />
          </button>
        )}
        <div className="deal-giftcontent">
          <div className="deal-reamininggiftcontainer">
            <div
              className="deal-reamaning"
              style={{
                width:
                  deal.count > 0
                    ? `${(deal.count * 100) / deal.totalDeal}%`
                    : "0%",
              }}
            ></div>
          </div>
          <div>
            <p className="deal-gift-name">{deal.name}</p>
          </div>
          <div className="deal-giftbuttoncontent">
            {deal?.discount > 0 &&
            new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
            new Date(deal?.discountEndDateAndTime) >= currentDateTime ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p className="discount-price">{deal.redeemrun}</p>
                <p className="deal-giftFont ">
                  {calculatePriceAfterDiscount(deal?.redeemrun, deal?.discount)}{" "}
                  Runs
                </p>
              </div>
            ) : (
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "5px"}}>
              <img src={ball} alt="ball-img" style={{width: "20px"}}/>
              <p className="deal-giftFont">{deal.redeemrun} Runs</p>
              </div>
            )}
            {deal.count ? (
              deal.comingSoon ? (
                <Button className="giftButton-deal-comingsoon" disabled={true}>
                  Coming Soon
                </Button>
              ) : deal.isLocked && !deal.unlockedByUser ? (
                <Button className="giftButton-deal-locked" disabled={true}>
                  Locked
                </Button>
              ) : // Check if the deal is for subscribers
              deal.SubscribedUser ? (
                // Check if the user is a subscriber
                isSubscriber ? (
                  // If both conditions are true, show the "Redeem Now" button
                  <Button
                    className="giftButton-deal"
                    onClick={() => {
                      if (!accessToken) {
                        handleOpenUserModal();
                      } else {
                        navigateToDealDescription(deal._id);
                      }
                    }}
                  >
                    Redeem Now
                  </Button>
                ) : (
                  // If the user is not a subscriber, show the upgrade modal
                  <div>
                    <Button
                      onClick={handleOpenPlus}
                      className="giftButton-deal"
                    >
                      Only for Subscribers
                    </Button>
                    <Modal
                      open={openPlus}
                      onClose={handleClosePlus}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        {/* Modal content for upgrade */}
                        {/* Example content (replace with your actual upgrade modal content) */}
                        <div
                          style={{
                            color: "#0B518E",
                            fontSize: "21px",
                            fontWeight: "600",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <img
                            src={logo}
                            width={50}
                            style={{ marginRight: "10px" }}
                            alt="Logo"
                          />
                          <p>Upgrade to redeem this deal</p>
                        </div>
                        <p
                          style={{
                            color: "#494D50",
                            fontWeight: "500",
                            marginBottom: "10px",
                          }}
                        >
                          Try{" "}
                          <span style={{ color: "#0B518E" }}>Champhunt</span>{" "}
                          <img
                            src={plusIcon}
                            width={15}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Today !!
                        </p>
                        <p style={{ color: "#898A8D" }}>
                          Get this deal & unlock our collections of exclusive
                          deals, Happy hours every day & many more.
                        </p>
                        <p
                          style={{
                            color: "#0C3B5E",
                            fontWeight: "500",
                            fontSize: "20px",
                            marginBottom: "10px",
                            marginTop: "10px",
                          }}
                        >
                          Here’s what’s inside Champhunt Plus
                        </p>
                        <div>
                          <div style={{ display: "flex" }}>
                            <img
                              src={giftIcon}
                              width={20}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ color: "#666666" }}>
                              Exclusive Subscriber Perks
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <img
                              src={giftIcon}
                              width={20}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ color: "#666666" }}>
                              Boosted Runs and Arena Rewards
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <img
                              src={giftIcon}
                              width={20}
                              style={{ marginRight: "10px" }}
                            />
                            <p style={{ color: "#666666" }}>
                              VIP Access and Support
                            </p>
                          </div>
                        </div>
                        <p style={{ fontSize: "12px", marginTop: "10px" }}>
                          Cancel anytime.
                        </p>
                        {/* More content */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <a href="/champhunt-plus">
                            <Button
                              onClick={handleClosePlus}
                              style={{
                                backgroundColor: "#0C5BA0",
                                padding: "15px 25px",
                                textAlign: "center",
                                border: "none",
                                borderRadius: "10px",
                                color: "#ffffff",
                                fontSize: "19px",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                            >
                              Subscribe Now
                            </Button>
                          </a>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                )
              ) : (
                <Button
                  className="giftButton-deal"
                  onClick={() => {
                    if (!accessToken) {
                      handleOpenUserModal();
                    } else {
                      navigateToDealDescription(deal._id);
                    }
                  }}
                >
                  Redeem Now
                </Button>
              )
            ) : (
              <Button className="giftButton-deal1" disabled={true}>
                Out of Stock
              </Button>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <DealModal
          handleClose={handleToggle}
          deal={deal}
          open={showModal}
          redeemDeal={redeemDeal}
        />
      )}
      {showModalForRedeemed && (
        <RedeemedTodayModal
          handleClose={handleCloseRedeemedToday}
          open={showModalForRedeemed}
        />
      )}
      {!redeemedToday && (
        <DealReedeemedModal
          handleClose={handleCloseRedeemedModal}
          handleOpen={handleOpenRedeemedModal}
          open={openRedeemedModal}
        />
      )}

      {viewUnlockCondition && (
        <ViewConditionPopup
          open={viewUnlockCondition}
          handleClose={handleViewUnlockCondition}
          condition={deal?.conditionForUnlock}
          count={deal?.currentCount}
        />
      )}

      {openUserModal && (
        <Modal
          open={openUserModal}
          onClose={() => {
            setOpenUserModal(false);
            navigate("/deals");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // width: "500px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#FFFFFF",
              borderRadius: "20px",
              boxShadow: 24,
              p: 2,
            }}
          >
            <Avatar
              src={champLogo}
              alt=""
              sx={{
                width: "80px",
                height: "80px",
              }}
            />
            <Typography
              id="modal-modal-title"
              sx={{
                fontFamily: "Inter",
                fontSize: "25px",
                fontWeight: 500,
                textAlign: "center",
                color: "#242424",
              }}
            >
              {!OpenOtp ? "Contact Information" : "Verificatiion Code"}
            </Typography>
            <Typography
              id="modal-modal-title"
              sx={{
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: 400,
                textAlign: "center",
                color: "#95999D",
                mt: 2,
              }}
            >
              {!OpenOtp
                ? "Verify yourself to redeem your deals"
                : "We sent you code on given Email Id to"}
            </Typography>

            {OpenOtp && (
              <Typography
                id="modal-modal-title"
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: 400,
                  textAlign: "center",
                  color: "#242424",
                }}
              >
                verify your account
              </Typography>
            )}
            {errorMessage && <p style={{ color: "red" }}> {errorMessage} </p>}
            {OpenOtp && (
              // <Box sx={{ width: "85%" }}>
              //   <InputLabel htmlFor="full-name" sx={{ mt: 2, color: "#242424" }}>Full Name</InputLabel>
              //   <Input
              //     id="full-name"
              //     type="text"
              //     placeholder="enter full name"
              //     disableUnderline
              //     sx={{
              //       width: '100%',
              //       height: '44px',
              //       mt: 1,
              //       border: '1px solid #BAC7D5',
              //       borderRadius: '3px',
              //       p: 1
              //     }}
              //   />
              // </Box>
              <Box sx={{ mt: 2, mb: 2 }}>
                <input
                  ref={inputReference1}
                  className={`inputFiled ${
                    verificationText1.length === 1 ? "" : "required"
                  }`}
                  type="text"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  maxLength="1"
                  onChange={(e) => {
                    setVerificationText1(e.target.value);
                    inputReference2.current.focus();
                  }}
                  autoFocus={true}
                />
                <input
                  ref={inputReference2}
                  className={`inputFiled ${
                    verificationText2.length === 1 ? "" : "required"
                  }`}
                  type="text"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  maxLength="1"
                  onChange={(e) => {
                    setVerificationText2(e.target.value);
                    inputReference3.current.focus();
                  }}
                />
                <input
                  ref={inputReference3}
                  className={`inputFiled ${
                    verificationText3.length === 1 ? "" : "required"
                  }`}
                  type="text"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  maxLength="1"
                  onChange={(e) => {
                    setVerificationText3(e.target.value);
                    inputReference4.current.focus();
                  }}
                />
                <input
                  ref={inputReference4}
                  className={`inputFiled ${
                    verificationText4.length === 1 ? "" : "required"
                  }`}
                  type="text"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  maxLength="1"
                  onChange={(e) => {
                    setVerificationText4(e.target.value);
                  }}
                />
              </Box>
            )}
            {!OpenOtp && (
              <Box sx={{ width: "85%" }}>
                <InputLabel htmlFor="email" sx={{ mt: 1, color: "#242424" }}>
                  Email
                </InputLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="enter email id"
                  disableUnderline
                  value={email}
                  onChange={handleOnChangeUserModal}
                  sx={{
                    mb: 2,
                    mt: 1,
                    width: "100%",
                    height: "44px",
                    border: "1px solid #BAC7D5",
                    borderRadius: "3px",
                    color: isTyping ? "#0C5BA0" : "",
                    p: 1,
                  }}
                />
              </Box>
            )}
            {!OpenOtp ? (
              <Button
                style={{
                  width: "45%",
                  height: "44px",
                  backgroundColor: "#0C5BA0",
                  borderRadius: "5px",
                  border: "none",
                  color: "#FFFFFF",
                  marginTop: "7px",
                  cursor: "pointer",
                }}
                onClick={signUpSubmit}
              >
                Verify Me
              </Button>
            ) : (
              <Button
                style={{
                  width: "45%",
                  height: "44px",
                  backgroundColor: "#0C5BA0",
                  borderRadius: "5px",
                  border: "none",
                  color: "#FFFFFF",
                  marginTop: "7px",
                  cursor: disableRedeemBtn ? "not-allowed" : "pointer",
                }}
                onClick={() => {
                  onRegisterClick();
                  handleOpenGiftModal();
                }}
                disabled={disableRedeemBtn}
              >
                Redeem
              </Button>
            )}
          </Box>
        </Modal>
      )}
      <Modal
        open={openGiftModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100vw",
            height: "100vh",
            bgcolor: "#FFFFFF",
            borderRadius: "20px",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img src={gifImage} alt="" />
          <IconButton
            onClick={() => setOpenGiftModal(false)}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              "@media (max-width: 769px)": {
                display: "none",
              },
            }}
          >
            <CloseIcon sx={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
}
