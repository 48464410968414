import React, { useEffect, useState } from "react";
import BallIcon from "../../assets/images/icons/ball-white.svg";
import "./run.scss";
import axios from "axios";
import fireBaseFuntion from "../../firebase";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router";

const Run = (props) => {
  const formatRuns = (runs) => {
    if (formatInK && runs >= 1000) {
      return (runs / 1000).toFixed(1) + "k";
    }
    return runs;
  };

  const [userRuns, setUserRuns] = useState(0);
  const accessToken = getStorageItem("token");
  const loggedUserId = getStorageItem("user_id");
  const navigate = useNavigate();
  const { isRunValueNeeded, userId, run, formatInK } = props;
  const _userId = userId ? userId : loggedUserId;
  //...call  base real time update end
  const getRuns = () => {
    if (!_userId) return console.error("_userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + _userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {

        console.log(error);
      });
  };

  //...call fire base real time update start
  const realTimeTotalRunUpdate = () => {
    if (_userId) {
      const { onValue, starCountRef } = fireBaseFuntion(
        "user/getTotalRun/" + _userId
      );

      onValue(starCountRef, (snapShot) => {
        const newTotalRun = snapShot.val();
        // update the state with the new value
        // getRuns();
        if (newTotalRun?.totalRun && newTotalRun?.totalRun !== 0) {
          setUserRuns(newTotalRun.totalRun);
        } else {
          getRuns();
        }
      });
    }
  };
  useEffect(() => {
    // let isMounted = true;

    realTimeTotalRunUpdate();
    // getRuns();
    // return () => {
    //   isMounted = false;
    // };
  }, []);
  useEffect(() => {
    // if(run)
    // {getRuns();
    realTimeTotalRunUpdate();
    // }
  }, [run]);
  return isRunValueNeeded ? (
    // <>{userRuns}</>
    <>{formatRuns(userRuns)}</>
  ) : (
    <div className="run-section">
      <div className="runs-stats" style={{cursor:'pointer'}} onClick={() => {
        navigate("/runs-transaction");
      }}>
        <img src={BallIcon} alt="" />
        {/* <div>{userRuns}</div> */}
        <div>{formatRuns(userRuns)}</div>
      </div>
    </div>
  );
};

export default Run;
