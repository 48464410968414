import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@mui/material";
import "./rules.scss";
import { Helmet } from "react-helmet";
import runImg from "../../assets/images/rules/run.png";
import dealImg from "../../assets/images/rules/deal.png";
import spamImg from "../../assets/images/rules/spam.png";
import bgImg from "../../assets/images/rules/champhunt.png";
// import character from "../../assets/images/rules/Character.png";
import character from "./img/bg_policy.png";
import { useState } from "react";

const NewRefundPage = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpandedSection(isExpanded ? panel : null);
  };
  return (
    <div
      className="rule-container"
      style={{ backgroundColor: expandedSection ? "#fafafa" : "#07365f" }}
    >
      <Helmet>
        <title>Champhunt | Rules</title>
      </Helmet>
      <img className="bg" src={bgImg} alt="background" />
      <div className="inner-container">
        <Grid item xs={12} className="inner2-container">
          <img className="character" src={character} alt="character" />
          <Typography className="headding">REFUND POLICIES</Typography>
        </Grid>
        <Grid item xs={12} className="rules-accordian">
          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {" "}
              <Typography className="accordian-heading">
                Refund Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>
                <h3 style={{ color: "#0C5BA0" }}>Subscription</h3>
                <h4 style={{ color: "#0C5BA0" }}>Eligibility:</h4>

                <p>
                  Users may cancel their Champhunt subscription at any time.
                  Refunds are provided on a prorated basis for the unused portion of the subscription period,
                  calculated from the cancellation date.
                </p>
                <h4 style={{ color: "#0C5BA0" }}>Process:</h4>


                <p>To cancel your subscription, please send an email to <a href="mailto:support@champhunt.com">
                  support@champhunt.com </a> including the following details:


                  <li>Full name on Champhunt </li>
                  <li>Registered Email ID on Champhunt</li>
                  <li>Date of purchase</li>
                  <li>Reason for cancellation</li>
                  <li>Bank Details/UPI ID for the refund</li>
                  Upon verification of these details, your subscription will be canceled.
                  You will receive a confirmation email, and the refund will be automatically processed to the account you have provided.</p>


                <h4 style={{ color: "#0C5BA0" }}>Non-Refundable Cases:</h4>
                <p> Refunds may not be provided if a subscription is canceled within the last 7 days of the billing period.
                  Additionally, refunds are not issued for subscriptions that have been used in violation of our terms of service.
                </p>

                <h3 style={{ color: "#0C5BA0" }}>Subscription</h3>
                <h4 style={{ color: "#0C5BA0" }}>Eligibility:</h4>

                <p>
                  Refunds for purchases made through Champhunt’s marketplace are subject to the return policy outlined by the individual seller.
                  Champhunt does not directly issue refunds for marketplace transactions.
                </p>

                <h4 style={{ color: "#0C5BA0" }}>Process:</h4>
                <p>To request a refund, contact the seller directly through the contact details provided on Champhunt’s marketplace.</p>

                <h4 style={{ color: "#0C5BA0" }}>Dispute Resolution:</h4>
                <p>In case of disputes or issues with the seller, Champhunt offers mediation services. Please send your grievances to <a href="mailto:support@champhunt.com">
                  support@champhunt.com </a> along with all transaction details. Champhunt will review the case and take appropriate action.
                  If a resolution cannot be reached, Champhunt may, at its discretion, provide a partial or full refund based on the specifics of the case.</p>


              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ marginTop: "20px" }}
            >
              <Typography className="accordian-heading">
                Shipping Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>

                <h4 style={{ color: "#0C5BA0" }}>Processing Time:</h4>
                <p>
                  Deals for Champhunt Plus subscribers are processed within 1-2 business days.
                  Champhunt is not responsible for the processing time of standard deals.
                </p>

                <h4 style={{ color: "#0C5BA0" }}>Shipping Method:</h4>
                <p>
                  Champhunt does not manage shipping, as it is entirely dependent on the vendors providing the deals.
                  However, if you encounter any issues, you can contact us at <a href="mailto:support@champhunt.com">
                    support@champhunt.com </a> and our customer support team will do their best to resolve your concerns.
                </p>


              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion className="accordian-list">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              style={{ marginTop: "20px" }}
            >
              <Typography className="accordian-heading">
                Refund Policies
              </Typography>
            </AccordionSummary>
            <Grid item xs={12} className="horizontal"></Grid>
            <AccordionDetails className="accordian-details">
              <Typography>

                <h4 style={{ color: "#0C5BA0" }}>Eligibility:</h4>

                <p>Items purchased through Champhunt’s Marketplace platform are eligible for return within 7 days of the delivery date,
                  provided they are in unused, original condition with all tags attached.</p>

                <h4 style={{ color: "#0C5BA0" }}>Process:</h4>

                <p>
                  To initiate a return, contact the seller directly through the contact details provided on Champhunt’s Marketplace.
                </p>

                <h3 style={{ color: "#0C5BA0" }}>Dispute Resolution:</h3>

                <p>In case of disputes or issues with the seller, Champhunt offers mediation services. Please send your grievances to <a href="mailto:support@champhunt.com">
                  support@champhunt.com </a> along with all transaction details. Champhunt will review the case and take appropriate action.
                  If a resolution cannot be reached, Champhunt may, at its discretion, provide a partial or full refund based on the specifics of the case.</p>

                <h4 style={{ color: "#0C5BA0" }}>Exclusions:</h4>

                <p>
                  Rewards earned solely through runs are not eligible for return. Certain items, including digital products, personalized items,
                  clearance sales, or those marked by the seller as “non-returnable,” are excluded from returns.
                </p>

              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </div>
    </div>
  );
};

export default NewRefundPage;
