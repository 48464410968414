import { React, useState, useEffect } from 'react'
import "./leaderboardByTournament.scss";

import ball from "../../assets/images/fantasyy/ball.png";
import medal from "../../assets/images/fantasyy/leaderboard/medal.svg";
import topPlayer from "../../assets/images/fantasyy/leaderboard/top-player.svg";
import arrowIcon from "../../assets/images/fantasyy/leaderboard/arrowIcon.png";
import cup from "../../assets/images/fantasyy/cup.png";
import polygon from "../../assets/images/fantasyy/polygon.png";
import allrounderAnimation from "../../assets/images/fantasyy/leaderboard/allRounder.gif";
import rank1Medal from "../../assets/images/fantasyy/leaderboard/1stMedal.gif";
import rank2Medal from "../../assets/images/fantasyy/leaderboard/2ndMedal.gif";
import rank3Medal from "../../assets/images/fantasyy/leaderboard/3rdmedal.gif";
import { Button } from '@mui/material';
import { useMediaQuery } from "@material-ui/core";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useNavigate, useLocation } from 'react-router-dom';
import { getStorageItem } from '../../utils/sessionStorage';

const LeaderboardByTournament = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const uId = getStorageItem("user_id");
    const accessToken = getStorageItem("token");
    const [playersDetails, setPlayersDetails] = useState({});
    const tId = location.state.tId;
    const mobileView = useMediaQuery("(max-width:768px)");

    const getAllPlayersRank = async () => {
        try {
            const response = await fetch(
                global.config.ROOTURL.prod + `/contest/fantasyLeaderboard/playersRank?tId=${tId}&uId=${uId}`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            setPlayersDetails(data);
        } catch (err) {
            console.log(err.message);
        }
    };

    useEffect(() => {
        getAllPlayersRank();
    }, [])

    return (
        <div className='fantasy_leaderboard_by_tournament'>
            {mobileView && (
                <div className="fantasy-leage-wrapper">
                    <ArrowBackIcon className="f-arrow-icon" onClick={() => navigate(-1)} />
                    <h2 className="f-heading">All Participants</h2>
                </div>
            )}
            <div className='leaderboar-container-bg'>
                <div className='heading-container'>
                    <img className='cup' src={cup} alt='' />
                    <img className='text-heading' src={topPlayer} alt='' />
                    <img className='cup' src={cup} alt='' />
                    <img className='arrow-icon' src={arrowIcon} alt='' onClick={() => navigate(-1)} />
                </div>
                <div className='top-players-container'>
                    {playersDetails.playersRank && playersDetails.playersRank[1] && (
                        <div className='top-players-inner-container styleClass'>
                            <div className='shadow-container-rank-two'>
                                <div className='player-avatar-container' onClick={() => navigate(`/profile/${playersDetails?.playersRank[1]?.userId}`)}>
                                    <img className='player-avatar' src={playersDetails.playersRank[1]?.profilePhoto} alt='' />
                                    <img className='rank-two' src={rank2Medal} alt='' />
                                </div>
                            </div>
                            <h3 className='player-name'>{playersDetails.playersRank[1]?.name}</h3>
                            <div className='player-run-container'>
                                <img className='ball' src={ball} alt='' />
                                <p className='player-score'>{playersDetails.playersRank[1]?.rewardRuns} Runs</p>
                            </div>
                        </div>
                    )}
                    {playersDetails.playersRank && playersDetails.playersRank[0] && (
                        <div className='top-players-inner-container'>
                            <div className='shadow-container-rank-one'>
                                {playersDetails.playersRank && playersDetails.playersRank[0] && (
                                    <div className='player-one-avatar-container'>
                                        <img className='player-avatar-rankone' src={playersDetails.playersRank[0].profilePhoto} alt='' />
                                        <img className='rank-one' src={rank1Medal} alt='' />
                                    </div>
                                )}
                            </div>
                            <h3 className='player-name'>{playersDetails.playersRank[0]?.name}</h3>
                            <div className='player-run-container'>
                                <img className='ball' src={ball} alt='' />
                                <p className='player-score'>{playersDetails.playersRank[0]?.rewardRuns} Runs</p>
                            </div>
                        </div>
                    )}
                    {playersDetails.playersRank && playersDetails.playersRank[2] && (
                        <div className='top-players-inner-container styleClass'>
                            <div className='shadow-container-rank-three'>
                                <div className='player-avatar-container' onClick={() => navigate(`/profile/${playersDetails?.playersRank[2]?.userId}`)}>
                                    <img className='player-avatar' src={playersDetails.playersRank[2]?.profilePhoto} alt='' />
                                    <img className='rank-three' src={rank3Medal} alt='' />
                                </div>
                            </div>
                            <h3 className='player-name'>{playersDetails.playersRank[2]?.name}</h3>
                            <div className='player-run-container'>
                                <img className='ball' src={ball} alt='' />
                                <p className='player-score'>{playersDetails.playersRank[2]?.rewardRuns} Runs</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className='your-rank-points-container'>
                    <div className='your-points-container'>
                        <p className='points-text'>Your Points</p>
                        <div className='runs-box'>
                            <img className='ball' src={ball} alt='' />
                            <p className='runs-text'>{playersDetails?.yourRewardPoint} Runs</p>
                        </div>
                    </div>
                    <div className='your-rank-container'>
                        <p className='rank-text'>Your Rank</p>
                        <div className='rank-box'>
                            <img className='polygon' src={polygon} alt='' />
                            <p className='rank-text'>{playersDetails?.yourRank}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='all-rounder-container'>
                <img className='all-rounder-animation' src={allrounderAnimation} alt='' />
                <h2 className='all-rounder-text'>All Rounders!</h2>
            </div>
            <div className='all-rank-container'>
                {playersDetails?.playersRank?.slice(3, 6).map((item, idx) => {
                    return (
                        <div key={idx + 3}>
                            <div className='all-rank-inner'>
                                <div className='badge-inner'>
                                    <img className='rank-badge' src={medal} alt='' />
                                    <p className='rank-count'>{item?.rank ? item?.rank : null}</p>
                                </div>
                                <div className='profile-container' onClick={() => navigate(`/profile/${item?.userId}`)}>
                                    <img className='profile-avatar' src={item?.profilePhoto ? item.profilePhoto : null} alt='' />
                                    <p className='player-name'>{item?.name ? item?.name : null}</p>
                                </div>
                                <div className='rank-runs-container'>
                                    <img className='rank-ball' src={ball} alt='' />
                                    <p className='runs-text'>{item?.rewardRuns ? item?.rewardRuns : null} Runs</p>
                                </div>
                            </div>
                            <div className='horizontal-line'></div>
                        </div>
                    )
                })}
                <div className='share-container'>
                    <h2 className='share-text'>Capture <span style={{ color: "#0C5BA0" }}>the Game,</span> Share <span style={{ color: "#0C5BA0" }}>the Glory!</span></h2>
                    <Button className='share-button' onClick={() => navigate("/pitch")}>POST NOW</Button>
                </div>
                <div className='horizontal-line'></div>
                {playersDetails?.playersRank?.slice(6).map((item, idx) => {
                    return (
                        <div key={idx + 6}>
                            <div className='all-rank-inner'>
                                <div className='badge-inner'>
                                    <img className='rank-badge' src={medal} alt='' />
                                    <p className='rank-count'>{item?.rank ? item?.rank : null}</p>
                                </div>
                                <div className='profile-container' onClick={() => navigate(`/profile/${item?.userId}`)}>
                                    <img className='profile-avatar' src={item?.profilePhoto ? item?.profilePhoto : null} alt='' />
                                    <p className='player-name'>{item?.name ? item?.name : null}</p>
                                </div>
                                <div className='rank-runs-container'>
                                    <img className='rank-ball' src={ball} alt='' />
                                    <p className='runs-text'>{item?.rewardRuns ? item?.rewardRuns : null} Runs</p>
                                </div>
                            </div>
                            <div className='horizontal-line'></div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
};

export default LeaderboardByTournament;