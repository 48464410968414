import React, { useState } from "react";
import MobileChat from "./MobileChat";
import ChatScreen from "./ChatScreen";
import DirectChatScreen from "./DirectChatScreen";
// import "./App.scss";

const ResponsiveChat = () => {
  const [activeScreen, setActiveScreen] = useState("mobileChat");
  const [activeChat, setActiveChat] = useState(null);
  const [groupId, setGroupId] = useState("");
  const [receivedId, setReceiverId] = useState("");
  const [senderId, setSenderId] = useState("");
  const [friendId, setFriendId] = useState("");
  const [type, setType] = useState("group");
  const [groupProfilePhoto, setGroupProfilePhoto] = useState("");
  const [receiverDets, setReceiverDets] = useState([]);

  const openChat = (chat) => {
    setType(chat.type);
    if (chat.type === "group") {
      setGroupId(chat.id);
      setGroupProfilePhoto(chat.profilePhoto);
    } else if (chat.type === "friend") {
      setFriendId(chat.id);
      setReceiverId(chat.receiverId);
      setSenderId(chat.senderId);
      setReceiverDets(chat?.receiverDets);
    }
    setActiveChat(chat);
    setActiveScreen("chatScreen");
  };

  const goBack = () => {
    setActiveScreen("mobileChat");
    setActiveChat(null);
    setGroupId("");
    setFriendId("");
  };

  return (
    <div className="app">
      {activeScreen === "mobileChat" && <MobileChat onChatSelect={openChat} />}
      {activeScreen === "chatScreen" && type === "group" && (
        <ChatScreen
          chat={activeChat}
          groupId={groupId}
          profilePhoto={groupProfilePhoto}
          onBack={goBack}
        />
      )}
      {activeScreen === "chatScreen" && type === "friend" && (
        <DirectChatScreen
          chat={activeChat}
          friendId={friendId}
          receiverId={receivedId}
          senderId={senderId}
          receiverDets={receiverDets}
          onBack={goBack}
        />
      )}
    </div>
  );
};

export default ResponsiveChat;
