import { React, useEffect, useState } from 'react'
import "./leaderboard.scss";

import cup from "../../assets/images/fantasyy/cup.png";
import ball from "../../assets/images/fantasyy/ball.png";
import pIcon from "../../assets/images/fantasyy/allPIcon.png";
import topPlayer from "../../assets/images/fantasyy/leaderboard/top-player.svg";
import rank1Medal from "../../assets/images/fantasyy/leaderboard/1stMedal.gif";
import rank2Medal from "../../assets/images/fantasyy/leaderboard/2ndMedal.gif";
import rank3Medal from "../../assets/images/fantasyy/leaderboard/3rdmedal.gif";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { getStorageItem } from '../../utils/sessionStorage';

const Leaderboard = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const uId = getStorageItem("user_id");
    const [playersDetails, setPlayersDetails] = useState({});
    const [tId, setTId] = useState("");

    const getAllPlayersRank = async () => {
        try {
            const response = await fetch(
                global.config.ROOTURL.prod + `/contest/fantasyLeaderboard/playersRank?tId=${tId}&uId=${uId}`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            setPlayersDetails(data);
        } catch (err) {
            console.log(err.message);
        }
    };

    const getTournamentStatus = async () => {
        if (!accessToken) return
        const options = {
            method: "GET",
            url:
                global.config.ROOTURL.prod +
                `/contest/fantasy/liveTournament`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        await axios(options)
            .then(({ data }) => {
                setTId(data._id);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };

    useEffect(() => {
        getAllPlayersRank();
    }, [tId])

    useEffect(() => {
        getTournamentStatus()
    }, [])

    const handleAllParticipantsClick = () => {
        navigate("/fantasy-league-leaderboardByTournament", { state: { tId } });
    };

    return (
        <div className='fantasy_leaderboard_main'>
            <div className='player-heading'>
                <img className='cup' src={cup} alt='' />
                <img className='text-heading' src={topPlayer} alt='' />
                <img className='cup' src={cup} alt='' />
                <div className='all-participatns-container' onClick={handleAllParticipantsClick}>
                    <img className='p-icon' src={pIcon} alt='' />
                    <p className='all-participants'>All Participants</p>
                </div>
            </div>
            <div className='top-players-container'>
                <div className='top-players-inner-container styleClass'>
                    <div className='shadow-container-rank-two'>
                        <div className='player-avatar-container'
                            onClick={() => navigate(`/profile/${playersDetails?.playersRank[1]?.userId}`)}>
                            {playersDetails.playersRank && playersDetails.playersRank[1] && (
                                <>
                                    <img className='player-avatar' src={playersDetails.playersRank[1].profilePhoto} alt='' />
                                    <img className='rank-two' src={rank2Medal} alt='' />
                                </>
                            )}
                        </div>
                    </div>
                    {playersDetails.playersRank && playersDetails.playersRank[1] && (
                        <h3 className='player-name'
                            onClick={() => navigate(`/profile/${playersDetails?.playersRank[1]?.userId}`)}>
                            {playersDetails.playersRank[1].name}
                        </h3>
                    )}
                    {playersDetails.playersRank && playersDetails.playersRank[1] && (
                        <div className='player-run-container'>
                            <img className='ball' src={ball} alt='' />
                            <p className='player-score'>{playersDetails.playersRank[1].rewardRuns} Runs</p>
                        </div>
                    )}
                </div>
                <div className='top-players-inner-container'>
                    <div className='shadow-container-rank-one'>
                        {playersDetails.playersRank && playersDetails.playersRank[0] && (
                            <div className='player-one-avatar-container'>
                                <img className='player-avatar-rankone' src={playersDetails.playersRank[0].profilePhoto} alt='' />
                                <img className='rank-one' src={rank1Medal} alt='' />
                            </div>
                        )}
                    </div>
                    {playersDetails.playersRank && playersDetails.playersRank[0] && (
                        <h3 className='player-name'
                            onClick={() => navigate(`/profile/${playersDetails?.playersRank[0]?.userId}`)}>
                            {playersDetails.playersRank[0].name}
                        </h3>
                    )}
                    {playersDetails.playersRank && playersDetails.playersRank[0] && (
                        <div className='player-run-container'>
                            <img className='ball' src={ball} alt='' />
                            <p className='player-score'>{playersDetails.playersRank[0].rewardRuns} Runs</p>
                        </div>
                    )}
                </div>
                <div className='top-players-inner-container styleClass'>
                    <div className='shadow-container-rank-three'>
                        <div className='player-avatar-container'
                            onClick={() => navigate(`/profile/${playersDetails?.playersRank[2]?.userId}`)}>
                            {playersDetails.playersRank && playersDetails.playersRank[2] && (
                                <>
                                    <img className='player-avatar' src={playersDetails.playersRank[2].profilePhoto} alt='' />
                                    <img className='rank-three' src={rank3Medal} alt='' />
                                </>
                            )}
                        </div>
                    </div>
                    {playersDetails.playersRank && playersDetails.playersRank[2] && (
                        <h3 className='player-name'
                            onClick={() => navigate(`/profile/${playersDetails?.playersRank[2]?.userId}`)}>
                            {playersDetails.playersRank[2].name}
                        </h3>
                    )}
                    {playersDetails.playersRank && playersDetails.playersRank[2] && (
                        <div className='player-run-container'>
                            <img className='ball' src={ball} alt='' />
                            <p className='player-score'>{playersDetails.playersRank[2].rewardRuns} Runs</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Leaderboard;
