import { Box, FormControl, InputAdornment, Paper, MenuItem, Select } from "@material-ui/core";
import React, { useState, useRef, useMemo } from "react";
import "./index.css";
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";

// jodit-react
import JoditEditor from "jodit-react";

// Mui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// React-Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const accessToken = getStorageItem("token");

const CarouselCreationForm = () => {
  const [banner, setBanner] = useState("/banner.png");
  const [formContent, setFormContent] = useState({
    image: "",
  });
  const [status, setStatus] = useState(true);
  const [carouselType, setCarouselType] = useState("");
  const handleCarouselTypeChange = (event) => {
    setCarouselType(event.target.value);
  };
  // Image Wrapper Styles
  const styles = {
    paperContainer: {
      height: 300,
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
  };

  const editor = useRef(null);
  const [content, setContent] = useState("");


  const titleRef = useRef();

  const bannerRef = useRef();
  const priorityRef = useRef();
  const linkRef = useRef();


  const [image, setImage] = useState(null);

  const onImageChange = (event) => {
    const { name, value } = event.target;
    setImage(event.target.files[0])
    setBanner(URL.createObjectURL(event.target.files[0]));
    // if (name === "image") {


    //     // if (event.target.files[0].type.includes("image")) {
    //     //     const file = event.target.files[0];
    //     //     console.log({ file });
    //     //     // const formData = new FormData();
    //     //     //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
    //     //     new Compressor(file, {
    //     //         quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
    //     //         success: (compressedResult) => {
    //     //             if (compressedResult.size > 5000000) {
    //     //                 setImage(compressedResult);
    //     //                 setStatus(true);
    //     //             } else {
    //     //                 console.log("This is upload img function call:");

    //     //                 setImage(compressedResult);

    //     //                 setStatus(false);
    //     //             }

    //     //             if (event.target.files && event.target.files[0]) {
    //     //                 setBanner(URL.createObjectURL(event.target.files[0]));
    //     //             }
    //     //         },
    //     //     });
    //     // }
    // }
    //console.log("OUTSIDE IMAGE CONDITION")
  };


  const handleSubmit = () => {
    // const banner = banner;
    const title = titleRef.current.value;
    const link = linkRef.current.value;
    const priority = parseInt(priorityRef.current.value);


    if (
      image === "" ||
      priority === "" ||
      title === "" ||
      carouselType === ""
    ) {
      toast.error("Error: Data incomplete");
      return;
    }

    const formData = new FormData();
    formData.append("uploader", image);
    formData.append("priority", priority);
    formData.append("message", title);
    formData.append("link", link);
    formData.append("type", carouselType);

    // Axios Post request
    const createNewEvent = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/carousel/create",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: formData,
    };
    axios(createNewEvent)
      .then((response) => {
        if (response?.status === 200 && response) {
          toast.success("New Event created successfuly");
          bannerRef.current.value = "";
          titleRef.current.value = "";
          priorityRef.current.value = "";
          setImage("");

          setBanner("");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          error.message
            ? toast.error("Error: " + error.message)
            : toast.error("Error: " + error);
        }
        error.message
          ? toast.error("Error: " + error.message)
          : toast.error("Error: " + error);
      });
  };

  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={6} xs={12}>
        <Box component="form" noValidate autoComplete="off">
          <Grid container columnSpacing={1}>
            {/* Title */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="title"
                  label="Titlertgdbf"
                  name="title"
                  autoFocus
                  margin="normal"
                  inputRef={titleRef}
                  InputProps={{ style: { padding: "16px 0px 16px" } }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  required
                  id="priority"
                  label="priority"
                  name="priority"
                  autoFocus
                  margin="normal"
                  type={"number"}
                  inputRef={priorityRef}
                  InputProps={{ style: { padding: "16px 0px 16px" } }}
                />
              </FormControl>
            </Grid>
            <div style={{ marginLeft: "8px" }}>
              <select value={carouselType} onChange={handleCarouselTypeChange}
                style={{
                  background: "none",
                  padding: "30px 0px 30px 10px",
                  width: "268%",
                  outline: "none",
                  borderRadius: "4px",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "14px"
                }}
              >
                <option value=""
                >Select Carousel</option>
                <option value="LandingPageCarousel">LandingPage Carousel</option>
                <option value="PitchCarousel">Pitch Carousel</option>
                <option value="ArenaCarousel">Arena Carousel</option>
              </select>
            </div>
            <Grid item xs={12}>
              <Tooltip title="example: /deals">
                <FormControl fullWidth>
                  <TextField
                    required
                    id="link"
                    label="link"
                    name="link"
                    autoFocus
                    margin="normal"
                    type={"text"}
                    inputRef={linkRef}
                    InputProps={{ style: { padding: "16px 0px 16px" } }}
                  />
                </FormControl>
              </Tooltip>
            </Grid>

            {/* Location */}

            {/* Banner */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="file"
                  autoFocus
                  margin="normal"
                  name="image"
                  accept="image/*"
                  onChange={onImageChange}
                  inputRef={bannerRef}
                  InputProps={{ style: { padding: "16px 0px 16px" } }}
                  required
                />
              </FormControl>
            </Grid>

            {/* Button */}
            <Grid item xs={12}>
              <FormControl fullWidth></FormControl>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                margin="normal"
                onClick={handleSubmit}
                fullWidth
              >
                Add Carousel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid >
      <Grid item sm={6} xs={12}>
        {image && (
          image.name.endsWith('.gif') || image.name.endsWith('.png') ? (
            <Paper component="img" style={styles.paperContainer} src={banner} />
          ) : (
            <video key={banner} autoPlay loop muted style={{ width: '100%', height: '90%', objectFit: 'cover' }}>
              <source src={banner} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )
        )}
      </Grid>
      <ToastContainer />
    </Grid >
  );
};

export default CarouselCreationForm;
