import axios from "axios";

const auth_ms_url = global.config.ROOTURL.prod;
const accessToken = localStorage.getItem("token");
const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + accessToken,
  },
};

const getRedeemedRunApi = async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/deal/redeemRun/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};


const totalUsersWhoCreatedPitchApi = async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/pitch/totalUsersWhoCreatedPitch/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};
const getPollCountApi = async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/poll/pollCount/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};
const getLoginCountApi = async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/auth/userLoginHistory/totalUsersWhoLoggedIn/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};
const getUserCountApi= async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/auth/userCount/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};

const getPitchCountApi= async (formattedStartDate,formattedEndDate) => {
  let response = await axios.get(auth_ms_url + `/pitch/pitchCount/${formattedStartDate}/${formattedEndDate}`, headers);
  return response;
};
const getAllUsersRuns = async () => {
  let response = await axios.get(auth_ms_url + "/pitch/getTotalRunFromEveryUser", headers);
  return response;
};
const getAllPitchesNumber = async () => {
  let response = await axios.get(
    auth_ms_url + "/pitch/getallpitchesNumber",
    headers
  );
  return response;
};
const getAllRunsCreated = async () => {
  let response = await axios.get(auth_ms_url + "/leaderboard_details/totalRunsCreate", headers);
  return response;
};
const GetAllUsersDetail = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/getallnewUser/count",
    headers
  );
  return response;
};
const getOnlineUsers = async () => {
  let response = await axios.get(
    auth_ms_url + "/onlineuser/getallActiveUsers/",
    headers
  );
  return response;
};
const GetAllUsers = async (fromDate,toDate) => {
  let response = await axios.get(
    auth_ms_url + `/auth/get-all-user-count?fromDate=${fromDate}&toDate=${toDate}`,
    headers
  );
  return response;
};
const noOfUsersVisitingDeals = async () => {
  let response = await axios.get(
    auth_ms_url + "/userEngagement/getDealsVisitByAllUsersCount",
    headers
  );
  return response;
}
const totalHitsForDealsToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/userEngagement/totalHitsInDealsPage",
    headers
  );
  return response;
}

const totalRunsGivenByAllUsersToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/userEngagement/totalRunsGivenByAllUsers",
    headers
  );
  return response;
}
const downloadDealVisiterDetails = async () => {
  let response = await axios.get(
    auth_ms_url + "/userEngagement/userEngagementHistoryOfDealsByAllUsers",
    headers
  );
  return response;

}


const downloadRunsGivenByUserDetails = async () => {
  let response = await axios.get(
    auth_ms_url + "/userEngagement/userEngagementHistoryOfRunsGivenByAllUsers",
    headers
  );
  return response;

}
const pitchCreationCount = async () => {
  let response = await axios.get(
    auth_ms_url + "/pitch/pitchCreationCount",
    headers
  );
  return response;

}
/*
const thisMonthUserCreationCountFromStart = async(monthNumber) => {
  //let response = await axios.get(auth_ms_url + `/auth/thisMonthUserCreationCountFromStart/${startDate}/${endDate}`,
    headers
  )
  //return response
}*/

const thisMonthUserCreationCount = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/thisMonthUserCreationCount",
    headers
  );
  return response;

}

const thisYearUserCreationCount = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/thisYearUserCreationCount",
    headers
  );
  return response;

}

const thisWeekUserCreationCount = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/thisWeekUserCreationCount",
    headers
  );
  return response;

}

const todayUserCreationCount = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/todayUserCreationCount",
    headers
  );
  return response;

}
const totalRunDetails = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunDetails",
    headers
  );
  return response;

}

//run
const totalRunsGivenToAllUsersAllTime = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunsGivenToAllUsersAllTime",
    headers
  );
  return response;

}
const totalRunsGivenToAllUsersThisWeek = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunsGivenToAllUsersThisWeek",
    headers
  );
  return response;

}
const totalRunsGivenToAllUsersThisMonth = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunsGivenToAllUsersThisMonth",
    headers
  );
  return response;

}
const totalRunsGivenToAllUsersThisYear = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunsGivenToAllUsersThisYear",
    headers
  );
  return response;

}
const totalRunsGivenToAllUsersToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/totalRunsGivenToAllUsersToday",
    headers
  );
  return response;

}

const redeemedRunsToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/redeemedRunsToday",
    headers
  );
  return response;

}
const redeemedRunsAllTime = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/redeemedRunsAllTime",
    headers
  );
  return response;

}
const redeemedRunsThisWeek = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/redeemedRunsThisWeek",
    headers
  );
  return response;

}
const redeemedRunsThisMonth = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/redeemedRunsThisMonth",
    headers
  );
  return response;

}
const redeemedRunsThisYear = async () => {
  let response = await axios.get(
    auth_ms_url + "/auth/redeemedRunsThisYear",
    headers
  );
  return response;

}

const pollCreationCountThisWeek = async () => {
  let response = await axios.get(
    auth_ms_url + "/poll/pollCreationCountThisWeek",
    headers
  );
  return response;

}

const pollCreationCountThisMonth = async () => {
  let response = await axios.get(
    auth_ms_url + "/poll/pollCreationCountThisMonth",
    headers
  );
  return response;

}
const pollCreationCountToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/poll/pollCreationCountToday",
    headers
  );
  return response;

}

const pollCreationCountThisYear = async () => {
  let response = await axios.get(
    auth_ms_url + "/poll/pollCreationCountThisYear",
    headers
  );
  return response;
}

const dealVisitToday = async () => {
  let response = await axios.get(
    auth_ms_url + "/deal/dealVisitToday",
    headers
  );
  return response;
}

const dealVisitThisWeek = async () => {
  let response = await axios.get(
    auth_ms_url + "/deal/dealVisitThisWeek",
    headers
  );
  return response;
}

const dealVisitThisMonth = async () => {
  let response = await axios.get(
    auth_ms_url + "/deal/dealVisitThisMonth",
    headers
  );
  return response;
}

const dealVisitThisYear = async () => {
  let response = await axios.get(
    auth_ms_url + "/deal/dealVisitThisYear",
    headers
  );
  return response;
}
export {
  GetAllUsers, GetAllUsersDetail, getAllPitchesNumber, getAllRunsCreated,totalUsersWhoCreatedPitchApi,
  getAllUsersRuns, getOnlineUsers, noOfUsersVisitingDeals, totalHitsForDealsToday,
  totalRunsGivenByAllUsersToday, downloadDealVisiterDetails,
  downloadRunsGivenByUserDetails, pitchCreationCount,thisMonthUserCreationCount,thisYearUserCreationCount,
  thisWeekUserCreationCount,todayUserCreationCount,totalRunsGivenToAllUsersToday,totalRunsGivenToAllUsersThisYear,
  totalRunsGivenToAllUsersThisMonth,
  totalRunsGivenToAllUsersThisWeek, pollCreationCountToday,pollCreationCountThisYear,pollCreationCountThisMonth,pollCreationCountThisWeek,
  totalRunsGivenToAllUsersAllTime,redeemedRunsToday,redeemedRunsThisYear,redeemedRunsThisMonth,redeemedRunsThisWeek,redeemedRunsAllTime,
  dealVisitToday,dealVisitThisWeek,dealVisitThisMonth,dealVisitThisYear,getLoginCountApi,getUserCountApi,getPitchCountApi,getRedeemedRunApi,getPollCountApi,
};