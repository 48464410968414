

import React, { useEffect, useState, useRef } from 'react';
import Chat from './Chat';
import { getStorageItem } from '../../utils/sessionStorage';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BallTriangle } from 'react-loader-spinner';
import send from '../../assets/images/carousel/send.png';
import './index.scss'
import InfiniteScroll from 'react-infinite-scroll-component';
import AppContext from '../../context/AppContext';
import { PathAction } from '../../redux/reducers/globalPath';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import io from 'socket.io-client'


export default function ChatWithadmin() {
    const accessToken = getStorageItem('token');
    const userId = getStorageItem('user_id');
    const { id } = useParams();
    const [page, setPage] = useState(1);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [textValue, setTextValue] = useState('');
    const [openType, setOpenType] = useState(null);
    const [NameofotherPerson, setNameofotherPerson] = useState('');
    const [loading, setLoading] = useState(false);
    const chatContainerRef = useRef(null);
    const [shouldLoadPage, setShouldLoadPage] = useState(false);
    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));
    const socket = useRef()

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;



    const handlesetInpt = (e) => {
        setTextValue(e.target.value);
    };

    const handleSendMessage = async () => {
        const messageDetails = { sendertype: 'user', userid: id, message: textValue, mediatype: null }

        socket.current.emit('send-msg', messageDetails)
        setTextValue('')
        // // let type = 'user';
        // if (userId !== id) {
        //     type = 'Admin';
        // }
        // if (!id && textValue === '') return;

        // const onlineuser = {
        //     method: 'POST',
        //     url: global.config.ROOTURL.prod + '/chat/create',
        //     headers: {
        //         Authorization: 'Bearer ' + accessToken,
        //         'Content-Type': 'application/json',
        //     },
        //     data: {
        //         sendertype: type,
        //         userid: id,
        //         message: textValue,
        //     },
        // };

        // await axios(onlineuser)
        //     .then((data) => {

        //         const newData = data.data;
        //         setItems(prevItems => [newData, ...prevItems]);
        //         setTextValue('');
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    };

    const setSeencheck = async () => {
        let type = 'user';
        if (userId !== id) {
            type = 'Admin';
        }
        if (!id) return;

        const onlineuser = {
            method: 'POST',
            url: global.config.ROOTURL.prod + '/chat/isSeenCheck',
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            data: {
                sendertype: type,
                userid: id,
            },
        };

        await axios(onlineuser)
            .then((data) => { })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchData = async (type) => {
        setLoading(true);
        if (!id) return;

        const onlineuser = {
            method: 'GET',
            url: global.config.ROOTURL.prod + `/chat/getUserAdminChat/${id}/${page}/${global.config.pagePerSize}`,
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
        };

        await axios(onlineuser)
            .then((response) => {
                if (type) {
                    checkName(type, response.data)
                }
                if (response.data.length === 0) {
                    setHasMore(false);
                } else {
                    setItems(response.data)
                    //setItems((prevItems) => prevItems.concat(response.data));
                    setPage((prevPage) => prevPage + 1);
                    setShouldLoadPage(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const checkName = (type, items1) => {
        let name;
        console.log(items1)
        for (let i = 0; i < items1.length; i++) {
            if (items1[i].sendertype !== type) {
                console.log(items1[i].sendertype)
                name = items1[i].Name;
                break;
            }
        }
        console.log(name)
        setNameofotherPerson(name);

    }


    useEffect(() => {
        let type;
        if (userId !== id) {
            type = 'Admin'
            setOpenType('Admin');
        } else {
            type = 'user'
            setOpenType('user');
        }
        setShowFooter(true);
        setSeencheck();
        fetchData(type);

        return () => {
            setShowFooter(false);
        };
    }, []);

    useEffect(() => {
        socket.current = io(global.config.ROOTURL.prod)
        socket.current.on('receive-msg', (data) => {
            if (data.userid === userId) {
                setItems((prevMessages) => [data, ...prevMessages])
            }
        })
    }, [])

    return (
        <>

            <div className="MainChatContainer">
                <div className="firstParentcontainer">
                    <div className='inputBoxContainer'>

                        <div className="ChatInputContainer">

                            <input
                                type="text"
                                className="chatInput"
                                value={textValue}
                                onChange={handlesetInpt}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSendMessage();
                                    }
                                }}
                            />
                            <img
                                src={send}
                                onClick={handleSendMessage}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    <p style={{ position: 'fixed' }}>{NameofotherPerson}</p>
                    <div className="ChatContainer"  >
                        {shouldLoadPage &&
                            <InfiniteScroll
                                dataLength={items.length}
                                next={fetchData}
                                hasMore={hasMore}
                                loader={<BallTriangle
                                    height="100"
                                    width="100"
                                    color="grey"
                                    ariaLabel="loading"
                                />}

                            >


                                {items?.map((data, index) => (
                                    <Chat key={index} chatdata={data} type={openType} number={index} />
                                ))}

                            </InfiniteScroll>
                        }
                    </div>

                </div>
            </div>

        </>
    );
}

