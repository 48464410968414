import { Box, Typography } from "@material-ui/core";
import React from "react";

import HistoryDashboard from "../get_subscriptions_history/history_dashboard";

const GetSubscriptionHistory = () => {
  return (
<HistoryDashboard/>
  );
};

export default GetSubscriptionHistory;
