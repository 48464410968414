import React, { useContext, useEffect, useState } from 'react'
import './Banner.scss'
// import dummy from '../../../assets/images/profile/bg.png'
import { Button } from '@mui/material'
import axios from "axios";
import { useDispatch } from 'react-redux'
import { PathAction } from '../../../redux/reducers/globalPath'
import AppContext from '../../../context/AppContext'

import { getStorageItem } from '../../../utils/sessionStorage'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


export default function Banner() {

    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [coverphotos, setcoverPhotos] = useState([]);
    const [change, setChange] = useState(true);
    const [select, setSelective] = useState(false);
    const [coverid, setcoverid] = useState('');
    const navigate = useNavigate();


    const handleSelection = (id) => {

        let AllPhotos = coverphotos;
        for (let i = 0; i < AllPhotos.length; i++) {
            if (AllPhotos[i]._id === id) {
                setcoverid(id)
                AllPhotos[i].selected = true;
            } else {
                AllPhotos[i].selected = false;
            }
        }
        setChange(!change);
        setcoverPhotos(AllPhotos);
    }
    // footer code 

    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;






    const handleSaveCoverPhoto = () => {
        console.log(coverid)
        if (coverid) {
            const savecover = {
                method: "POST",
                url: global.config.ROOTURL.prod + "/coverphoto/save",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                data: {
                    userid: userId,
                    coverphotoid: coverid
                }
            }
            axios(savecover)
                .then((response) => {
                    navigate(`/profile/${userId}`);
                })
                .catch((err) => {
                    console.log("error", err)
                });
        }
    }

    const handleCoverPhotos = () => {

        if (!accessToken) return;
        const getAllcover = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/coverphoto/getAll/1/10",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        }
        axios(getAllcover)
            .then((response) => {
                console.log(response.data)
                if (response.data) {
                    setcoverPhotos(response.data);
                }

            })
            .catch((err) => {
                console.log("error", err)
            });
    }
    useEffect(() => {

    }, [change])

    useEffect(() => {
        handleCoverPhotos();
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, []);
    return (
        <div className='BannerMainContainer'>

            <div style={{ textAlign: 'center' }}>
                <div>
                    <p className="MainHeaderHeading">Select Banner</p>
                </div>
                <div>
                    {coverphotos.map((i) => (
                        (i.image && (i.image.endsWith('.gif') || i.image.endsWith('.png')) ?
                            <img src={i.image} className={i.selected ? 'bannerImage activeBanner' : "bannerImage"} onClick={() => handleSelection(i._id)} key={i._id} /> : i.image.endsWith('.mp4') ? (
                                <video autoPlay loop muted className={i.selected ? 'bannerImage activeBanner' : "bannerImage"} style={{objectFit:'cover'}}  onClick={() => handleSelection(i._id)} key={i._id}>
                                    <source src={i.image} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : null

                        )
                    ))}
                </div>

                <div>
                    <div style={{ paddingBottom: '20px' }}>
                        <Button className="Btn" onClick={() => navigate(`/profile/${userId}`)}>Cancel</Button>
                        <Button className='Btn SaveBtn' onClick={handleSaveCoverPhoto}>Save</Button>
                    </div>
                </div>
            </div>

        </div>
    )
}
