import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./videos.scss";

const AdminVideos = () => {
    const accessToken = getStorageItem("token");
    const [allVideosByAdmin, setAllVideosByAdmin] = useState([]);

    useEffect(() => {
        const fetchAllVideosByAdmin = async () => {
            try {
                const response = await axios.get(`${global.config.ROOTURL.prod}/commentary/getAllVideosByAdmin`, {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Headers": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                    },
                });
                setAllVideosByAdmin(response.data);
            } catch (error) {
                console.error("Error fetching videos: ", error);
                toast.error('Error fetching videos');
            }
        };

        fetchAllVideosByAdmin();
    }, []);

    const handleDelete = async (id) => {
        try {
            const res = await axios.delete(`${global.config.ROOTURL.prod}/commentary/deleteAdminVideoBy/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            });
            if (res?.status === 200) {
                toast.success('Video deleted successfully');
            }
        } catch (error) {
            console.error("Error deleting video: ", error);
            toast.error('Error deleting video');
        }
    };

    return (
        <div className='commentary'>
            <h1 className='commentary__title'>Videos by admin</h1>
            <div className='commentary__list'>
                {allVideosByAdmin && allVideosByAdmin.length > 0 && allVideosByAdmin.map(video => (
                    <div key={video?._id} className='commentary'>
                        <video controls className='commentary__video'>
                            <source src={video?.url} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                        <div className='commentary__content'>
                            <h2 className='commentary__title'>{video?.title}</h2>
                            <p className='commentary__description'>{video?.description}</p>
                        </div>
                        <button className='commentary__delete-button' onClick={() => handleDelete(video?._id)}>
                            Delete
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AdminVideos
