import React from 'react';
import { useNavigate } from "react-router-dom";
import FantasyGif from "../../assets/images/rewards/Fantasy.gif";

const NoMatchesPage = () => {
  const navigate = useNavigate();

  const handleRewardNavigation = () => {
    navigate("/rewards");
  }

  return (
    <div style={styles.container}>
      <div style={styles.content}>
      <img
          src={FantasyGif} 
          alt="No matches"
          style={styles.image}
        />
        <h1 style={styles.title}>Live Matches Coming Soon</h1>
        <p style={styles.subtitle}>
        No live matches at the moment. We will update you as soon as the next thrilling match is live!
        </p>
        <button style={styles.button} onClick={handleRewardNavigation}>Back</button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  content: {
    textAlign: 'center',
    backgroundColor: '#ffffff',
    padding: '40px 20px',
    borderRadius: '8px',
  },
  image: {
    width: '300px',
    height: '300px',
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    color: '#0F6CBD',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '16px',
    color: '#95999D',
    marginBottom: '30px',
  },
  button: {
    width: "30%",
    padding: '10px 20px',
    fontSize: '16px',
    color: '#ffffff',
    backgroundColor: '#0C5BA0',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default NoMatchesPage;
