import {
  Grid,
  Button,
  CardMedia,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicField from "../form-creation/form-component";
import { useNavigate, useParams } from "react-router-dom";
import ImageBackground from '../../assets/images/Image-Background.png';

const DealUpdateForm = () => {
  const accessToken = getStorageItem("token");
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [imageBG, setImageBG] = useState(null);
  const [dealImage, setDealImage] = useState(null);
  const [updates, setUpdates] = useState({});
  const [conditionForUnlock, setConditionForUnlock] = useState({ post: 0, comment: 0, highlight: 0 });
  const [runcondition, setRuncondition] = useState({ lowerCount: 0, upperCount: 0 });
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [targetUser, setTargetUser] = useState('');

  const formFields = [
    { label: 'Title', name: 'name', type: 'text', placeholder: 'Title Name' },
    { label: 'Runs Required', name: 'redeemrun', type: 'text', placeholder: 'Runs' },
    { label: 'Email Id', name: 'partnerEmailId', type: 'email', placeholder: 'Mail ID' },
    { label: 'Deal Count', name: 'count', type: 'text', placeholder: 'Count' },
    { label: 'Category', name: 'categories', type: 'select', options: [{ label: 'All', value: 'All' }, { label: 'E-Commerce', value: 'E-Commerce' }, { label: 'Food and Beverages', value: 'Food and Beverages' }, { label: 'Exclusive', value: 'Exclusive' }, { label: 'TimeLimit', value: 'TimeLimit' }, { label: 'Offers', value: 'Offers' }] },
    { label: 'App Only', name: 'appOnly', type: 'select', options: [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }] },
    { label: 'Redeem Once', name: 'redeemableOnce', type: 'select', options: [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }] },
    { label: 'Lock Deal', name: 'isLocked', type: 'select', options: [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }] },
    { label: 'Discount Percentage', name: 'discount', type: 'text', placeholder: 'Discount' },
    { label: 'Run Deal', name: 'active', type: 'select', options: [{ label: 'True', value: 'true' }, { label: 'False', value: 'false' }] },
    { label: 'Code', name: 'encrptedcode', type: 'text', placeholder: 'Code' },
    { label: 'Priority No', name: 'priorityNo', type: 'text', placeholder: '0' },
    { label: 'Start Date & Time', name: 'startDateAndTime', type: 'datetime-local' },
    { label: 'End Date & Time', name: 'endDateAndTime', type: 'datetime-local' },
    {
      label: "Discount Start Date & Time",
      name: "discountStartDateAndTime",
      type: "datetime-local",
    },
    {
      label: "Discount End Date & Time",
      name: "discountEndDateAndTime",
      type: "datetime-local",
    },
  ];

  const [updateData, setUpdateData] = useState(
    formFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );

  const onImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBG(reader.result);
    };
    reader.readAsDataURL(file);
    setImage(file);
  };

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${global.config.ROOTURL.prod}/deal/getDealById/${id}`, {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      });

      const data = response.data;
      setUpdateData({
        name: data.name,
        partnerEmailId: data.partnerEmailId,
        redeemrun: data.redeemrun,
        count: data.count,
        categories: data.categories,
        appOnly: data.appOnly,
        redeemableOnce: data.redeemableOnce,
        isLocked: data.isLocked,
        discount: data.discount,
        active: data.active,
        startDateAndTime: data.startDateAndTime,
        endDateAndTime: data.endDateAndTime,
        desc: data.desc,
        encrptedcode: data.encrptedcode,
        priorityNo: data.priorityNo,
      });
      setImageBG(data.dealImage);
      setConditionForUnlock(data.conditionForUnlock);
      setRuncondition(data.runcondition);
    } catch (error) {
      console.error("Error fetching deal data:", error);
    }
  };

  const handleFormSubmit = async () => {
    setSubmitBtnDisable(true);

    if (image) {
      new Compressor(image, {
        quality: 0.8,
        success: async (compressedResult) => {
          const formData = new FormData();
          formData.append("uploader", compressedResult);

          try {
            const uploadResponse = await axios.post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            });

            // Successfully uploaded image, now call sendUpdatedData with the uploaded image
            const uploadedImage = uploadResponse.data[0].location;
            sendUpdatedData(uploadedImage);
          } catch (error) {
            console.error("Error uploading image:", error);
            setSubmitBtnDisable(false); // Re-enable the submit button on error
          }
        },
        error(err) {
          console.error("Error during compression:", err);
          setSubmitBtnDisable(false); // Re-enable the submit button on error
        }
      });
    } else {
      // If no image is being uploaded, directly call sendUpdatedData without the image
      sendUpdatedData(null);
    }
  };
  const sendUpdatedData = async (uploadedImage) => {
    let newUpdates = { ...updates };

    if (targetUser) {
      newUpdates = { ...newUpdates, targetUser };
    }

    // Ensure conditionForUnlock is defined and has valid values
    if (conditionForUnlock && (conditionForUnlock.post || conditionForUnlock.comment || conditionForUnlock.highlight)) {
      newUpdates = { ...newUpdates, conditionForUnlock: JSON.stringify(conditionForUnlock) };
    } else {
      console.log("No conditionForUnlock to add.");
    }

    if (runcondition && (runcondition.upperCount || runcondition.lowerCount)) {
      newUpdates = { ...newUpdates, runcondition: JSON.stringify(runcondition) };
    } else {
    }

    if (uploadedImage) {
      newUpdates = { ...newUpdates, dealImage: uploadedImage };
    }

    const updateEvent = {
      method: "POST",
      url: `${global.config.ROOTURL.prod}/deal/updateDeal?dealId=${id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: newUpdates,
    };

    try {
      const response = await axios(updateEvent);

      if (response?.status === 200) {
        console.log("Deal updated successfully!");
        toast.success("Deal Updated Successfully");
      } else {
        console.error("Unexpected response from server:", response);
        toast.error("Failed to update the deal.");
      }
    } catch (error) {
      console.error("Error updating deal:", error);
      toast.error("Error updating deal. Please try again.");
    } finally {
      setSubmitBtnDisable(false); // Re-enable the button after the process completes
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUpdateData({
      ...updateData,
      [name]: value,
    });
    setUpdates({
      ...updates,
      [name]: value,
    });
  };

  const handleNewChanges = (event) => {
    const { name, value } = event.target;

    if (name === "lowerCount" || name === "upperCount") {
      setRuncondition({ ...runcondition, [name]: parseInt(value, 10) });
    } else if (name === "post" || name === "comment" || name === "highlight") {
      setConditionForUnlock({ ...conditionForUnlock, [name]: parseInt(value, 10) });
    } else if (name === "targetUser") {
      setTargetUser(value);
    }
  };

  const formStyling = {
    formStyling: 'form-styling'
  }


  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: 'relative' }} >
        <CardMedia
          component="img"
          height="300"
          image={imageBG === null ? ImageBackground : imageBG}
          alt="green iguana"
          sx={{ borderRadius: '10px' }}
        />
        <Button
          variant="contained"
          color="primary"
          component='label'
          sx={{ position: 'relative', top: '-8vh', left: '75vh', width: '14%' }}
        ><input type="file" accept='image/*' name="image" style={{ display: 'none' }} onChange={onImageChange} />Add Images</Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {formFields.map((field, index) => (
            index % 2 === 0 && (<DynamicField sx={{ display: 'flex', flexDirection: 'column' }}
              value={updateData[field.name]}
              field={field}
              handleChange={handleChange}
              styles={formStyling}
            />)
          ))}
          <div className='form-styling'>
            <label>Targeted Users</label>
            <select id="mySelect" name="targetUser" onChange={handleNewChanges}>
              <option value="select">select</option>
              <option value="user">Users</option>
              <option value="subscriber">Subscribers</option>
            </select>
          </div>
          <div className='form-styling'>
            <label>RunCondition lowerCount</label>
            <input
              type="number"
              name="lowerCount"
              value={runcondition.lowerCount}
              onChange={handleNewChanges}
              placeholder="0"
            />
          </div>
          {updateData.isLocked && <div className='form-styling'>
            <label>Comment</label>
            <input
              type="number"
              value={conditionForUnlock.comment}
              name="comment"
              onChange={handleNewChanges}
              placeholder="comment"
            />
          </div>}

        </Grid>
        <Grid item xs={12} md={6}>
          {formFields.map((field, index) => (
            index % 2 !== 0 && (<DynamicField sx={{ display: 'flex', flexDirection: 'column' }}
              value={updateData[field.name]}
              field={field}
              handleChange={handleChange}
              styles={formStyling}
            />)
          ))}
          <div className='form-styling'>
            <label>RunCondition upperCount</label>
            <input
              type="number"
              name="upperCount"
              value={runcondition.upperCount}
              onChange={handleNewChanges}
            />
          </div>
          {updateData.isLocked &&
            <>
              <div className='form-styling'>
                <label>Post</label>
                <input
                  type="number"
                  name="post"
                  value={conditionForUnlock.post}
                  onChange={handleNewChanges}
                  placeholder="post"
                />
              </div>
              <div className='form-styling'>
                <label>Highlight</label>
                <input
                  type="number"
                  value={conditionForUnlock.highlight}
                  name="highlight"
                  onChange={handleNewChanges}
                  placeholder="highlight"
                />
              </div>
            </>}
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ position: 'relative' }}>
        <div className="form-styling">
          <label>Description</label>
          <textarea name="desc" value={updateData['desc']} onChange={handleChange} style={{ borderRadius: '8px', backgroundColor: '#F3F5F5', color: '#697D95', border: '1px solid #BAC7D5', fontSize: '14px', padding: '12px 13px' }} placeholder="Enter a Description">
          </textarea>
        </div>
      </Grid>
      <div>
        <Button
          variant="contained"
          color="primary"
          sx={{ position: 'relative', top: '4vh', left: '50vh', width: '75vh', padding: '10px 4px', fontSize: '17px', marginBottom: '40px' }}
          onClick={handleFormSubmit}
          disabled={submitBtnDisable}
        >Submit</Button>
      </div>
    </Grid>
  );
};

export default DealUpdateForm;
