import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../../utils/sessionStorage";
import moment from "moment";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/material";
import { CSVLink, CSVDownload } from "react-csv";
const accessToken = getStorageItem("token");
const headers = [
  { label: "Plan Name", key: "planName" },
  { label: "User Name", key: "firstName" },
  { label: "Plan Amount", key: "planAmount" },
  { label: "Expiry Date", key: "expiresAt" },
  { label: "Plan Duration", key: "planDuration" },
  { label: "User Mobile", key: "mobileNo" },
  { label: "Purchased Date", key: "createdAt" },
  { label: "Email", key: "email" },
  { label: "User Id", key: "userId" },
];

const columns = [
  { id: "index", label: "Sno.", minWidth: 50 },
  { id: "planName", label: "Plan Name", minWidth: 100 },
  { id: "firstName", label: "User Name", minWidth: 100 },
  {
    id: "planAmount",
    label: "Plan Amount",
    minWidth: 50,
    align: "center",
  },
  {
    id: "expiresAt",
    label: "Expiry Date",
    minWidth: 170,
    align: "right",
  },
  {
    id: "planDuration",
    label: "Plan Duration",
    minWidth: 170,
    align: "right",
  },
  {
    id: "mobileNo",
    label: "User Mobile",
    minWidth: 170,
    align: "right",
  },
  {
    id: "createdAt",
    label: "Purchased Date",
    minWidth: 170,
    align: "right",
    format: (value) => moment(value).format("lll"),
  },
  {
    id: "email",
    label: "User Email",
    minWidth: 170,
    align: "right",
  },
];

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

const HistoryDashboard = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [redeemData, setRedeemData] = useState([]);
  const [rows, setRows] = useState([]);
  const [dealFilter, setDealFilter] = useState("");

  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  const navigate = useNavigate();

  useEffect(() => {
    // console.log({ fromDate, toDate });
    // Fetching all events
    setPage(0);
    const options = {
      method: "get",
      // url: global.config.ROOTURL.prod + `/carousel/getAll/${pageNumber}/6`,
      url:
        global.config.ROOTURL.prod +
        `/pay/getAllSubscribers/${fromDate}/${toDate}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setRedeemData(data);
        // setTotalPageNumber(Math.ceil(data.length / 6));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          // toast.error("Some error occured");
        }
      });
  }, [fromDate, toDate]);

  useEffect(() => {
    setPage(0);
    if (dealFilter) {
      setRows(
        redeemData
          .filter((value, index, array) => value.userId === dealFilter)
          .map(
            (
              {
                planName,
                planAmount,
                expiresAt,
                planDuration,
                userId: { email, mobileNo, firstName },
                createdAt,
              },
              index,
              array
            ) => {
              return {
                index: index + 1,
                planName,
                firstName,
                mobileNo,
                planAmount: planAmount,
                dealRedeemDate: moment(createdAt).format("lll"),
                email,
                createdAt: moment(createdAt).format("MMMM Do, YYYY, h:mm:ss A"),

                expiresAt: moment(expiresAt).format("MMMM Do, YYYY"),
                planDuration,
              };
            }
          )
      );
    } else {
      setRows(
        redeemData.map(
          (
            {
              userId: { email, mobileNo, firstName },

              planName,
              planAmount,
              expiresAt,
              planDuration,

              createdAt,
            },
            index,
            array
          ) => {
            return {
              index: index + 1,

              firstName,
              planName,
              mobileNo,
              planAmount: planAmount,
              dealRedeemDate: moment(createdAt).format("lll"),
              email,
              createdAt: moment(createdAt).format("MMMM Do, YYYY"),

              expiresAt: moment(expiresAt).format("MMMM Do, YYYY"),
              planDuration,
            };
          }
        )
      );
    }
  }, [redeemData, dealFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        px={2}
        spacing={2}
      >
        <Typography variant="h4">Subscription History</Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="baseline"
          spacing={2}
        >
          <TextField
            id="fromDate"
            onChange={(e) => {
              // console.log(
              //   moment(new Date(e.target.value)).format("yyyy-MM-DD")
              // );
              setFromDate(
                moment(new Date(e.target.value)).format("yyyy-MM-DD")
              );
            }}
            label="From"
            type="date"
            // defaultValue="2017-05-24"
            value={fromDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toDate"
            onChange={(e) => {
              // console.log(
              //   moment(new Date(e.target.value)).format("yyyy-MM-DD")
              // );
              setToDate(moment(new Date(e.target.value)).format("yyyy-MM-DD"));
            }}
            label="To"
            type="date"
            // defaultValue="2017-05-24"
            value={toDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl minWidth={100}>
            <InputLabel minWidth={100}>Filter</InputLabel>
            <Select
              minWidth={100}
              value={dealFilter}
              label="Filter"
              onChange={(e) => {
                setDealFilter(e.target.value);
              }}
            >
              <MenuItem value={""} selected>
                All
              </MenuItem>
              {redeemData &&
                redeemData
                  .filter(
                    (value, index, self) =>
                      self.findIndex((m) => m.userId === value.userId) === index
                  )
                  .map((value, index, array) => (
                    <MenuItem value={value.userId}>{value.planName}</MenuItem>
                  ))}
            </Select>
          </FormControl>
          <CSVLink
            style={{ textDecoration: "none" }}
            data={rows}
            headers={headers}
            filename={`Subscribers-List-${fromDate}-to-${toDate}`}
          >
            <Button marginTop={2} variant="contained">
              Download
            </Button>
          </CSVLink>{" "}
        </Stack>
      </Stack>

      <Box marginTop={2}>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 550 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
};
export default HistoryDashboard;
