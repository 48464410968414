import CommentsIconGray from "../../assets/images/icons/comments.svg";
import ShareIconGray from "../../assets/images/icons/share.svg";
import Logo from "../../assets/images/header/logo.png";
import CommentsIcon from "../../assets/images/comment_blue_icon.png";
import RunsIcon from "../../assets/images/icons/post-action.png";
import ShareIcon from "../../assets/images/share_pink_icon.png";
import { Box } from "@mui/system";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import React from "react";
import ChampButton from "../../commons/form/button";
import ReactTimeAgo from "react-time-ago";
import Runs from "../../assets/images/icons/post-action.png";
import axios from "axios";
import ScoreRuns from "../runs-dropdown/dropdownMenu";
import RunCard from "../posts/run";
import NameCard from "../posts/name-card";
import ShareModal from "../../commons/components/share-modal/PostModal";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import config from "../../config";
import Header from "../header/header-without-signin";
import runs from "../../assets/images/icons/newBall.png";
import emptyreshare from "../../assets/images/icons/emptyReshare.png";
import newshare from "../../assets/images/icons/emptyShare.png";
import newcomment from "../../assets/images/icons/emptycomment.png";
import newreshare from "../../assets/images/icons/newreshare.png";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import Slider from "../../commons/components/slider";
import "./postPreview.scss";
import Popup from "./Popup";
const baseURL = config.ROOTURL.prod;
const mobileStyle = {
  background: "white",
  minWidth: "370px",
  minHeight: "400px",
};
const style = {
  minWidth: "650px",
  minHeight: "271px",
  marginTop: "45px",
  marginBottom: "62px",
  marginLeft: "82px",
  marginRight: "82px",
};
const Posts = (props) => {
  var regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
  const mobileView = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [postsOfUserId, setPostsOfUserId] = useState([]);
  const [comments, setComments] = useState([]);
  const [startDate, setStartDate] = useState(return1SecondPlusDate());
  const [pageNumber, setPageNumber] = useState(1);
  const [IsOpen, setIsOpen] = useState(false);
  const [runsGivenValue, setRunsGivenValue] = useState(0);
  const [runsImage, setRunsImage] = useState(Runs);
  const [runsGiven, setRunsGiven] = useState(false);
  const [runsScored, setRunsScored] = useState();
  const [showComments, setShowComments] = useState(false);
  const [Open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const params = useParams();
  const pitchId = params.id;
  const [ownPost, setOwnPost] = useState(false);
  const [showRuns, setShowRuns] = useState(false);
  const [sharedisable, setSharedisable] = useState(true);
  const [sampleImage2, setSampleImage2] = useState("");
  const [sampleImage1, setSampleImage1] = useState("");
  const [updateRun, setUpdateRun] = useState(
    props?.post?.postRunCount ? props?.post?.postRunCount : 0
  );
  const [updateCommentCount, setUpdateCommentedCount] = useState(
    props?.post?.postCommentCount ? props?.post?.postCommentCount : 0
  );
  const [updateRepostCount, setUpdateRepostCount] = useState(
    props?.post?.sharedPost ? props?.post?.sharedPost.length : 0
  );

  const [firstImageURL, setFirstImageURL] = useState("");
  const [vid, setVid] = useState("");

  const [youtube, setYoutube] = useState("");
  const [preview, setPreview] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = (data) => setOpen(data);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [popupData, setpopupData] = useState(false);
  const fetchDataByPitchId = () => {
    axios
      .get(`${baseURL}/pitch/getPitchByPitchIdForPreview/${pitchId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response.data.data", response.data);
        setPosts(response.data);
        setFirstImageURL(
          response.data?.postImageURLs &&
            response.data?.postImageURLs.length > 0
            ? response.data?.postImageURLs[0]
            : ""
        );
        setVid(response.data?.video ? response.data?.video : "");
        if (mobileView) fetchSamplePitchesByUserId(posts.userId);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const fetchCommentByPitchId = () => {
    axios
      .get(
        `${baseURL}/pitch/comment/getCommentByByPitchIdForPreview/${pitchId}/1/4`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("response.data", response.data);
        setComments(response.data);
        console.log("this is base url:", `${baseURL}`);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const fetchSamplePitchesByUserId = () => {
    axios
      .post(
        `${baseURL}/pitch/getPitchByUserIdForPreview`,
        {
          pageNumber,
          pagePerSize: "2",
          pitchId,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("response.data.data", response.data);
        setPostsOfUserId(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  let youtubeLink;

  function replaceHashtags(description) {
    // Use a regular expression to match hashtags that are not already anchor tags
    const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
    // Replace hashtags with anchor tags
    return description?.replace(
      regex,
      '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>'
    );
  }

  function youtubeParser(url) {
    if (url !== undefined) {
      var checks = url.split(" ");

      return (
        <p>
          {checks.map((words, index) => {
            var word;

            words.includes("/watch?v=")
              ? (word = words?.replace("/watch?v=", "/embed/"))
              : (word = words?.replace("youtu.be/", "www.youtube.com/embed/"));

            if (word.includes("http://") || word.includes("https://")) {
              youtubeLink = word;
            }

            return words.match(URL_REGEX) ? <></> : words + " ";
          })}
        </p>
      );
    }
  }

  youtubeParser(posts.postMessage);
  useEffect(() => {
    fetchDataByPitchId();
    fetchCommentByPitchId();
    if (mobileView) {
      postsOfUserId.map((value, index) => {
        if (
          index === 0 &&
          value &&
          value.postImageURLs &&
          value.postImageURLs[0]
        ) {
          setSampleImage1(value.postImageURLs[0]);
        } else if (
          index === 1 &&
          value &&
          value.postImageURLs &&
          value.postImageURLs[0]
        ) {
          setSampleImage2(value.postImageURLs[0]);
        }
      });
    }
  }, []);

  useEffect(() => {
    const links = [
      ...new Set(posts?.postMessage?.match(/(https?:\/\/[^\s]+)/g) || []),
    ];

    if (links.length > 0) {
      const lastLink = links[links.length - 1];
      links.forEach((link) => {
        posts.postMessage = posts.postMessage.replace(
          link,
          `<a href="${link}" class="next-not_preview-link" target="_blank">${link}</a>`
        );
      });

      const regex =
        /(https?:\/\/(?:www\.|m\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=|)|https?:\/\/youtu\.be\/)([\w-]{11})/i;
      const isYoutubeLink = lastLink?.match(regex);
      if (isYoutubeLink) {
        const videoId = isYoutubeLink[2];
        setYoutube(
          `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        );
      } else {
        axios
          .post(global.config.ROOTURL.prod + "/preview", {
            url: lastLink,
          })
          .then((res) => res.data)
          .then((data) => {
            setPreview(`<div class="preview-wrapper">
            <img class="preview-image" src="${data.image}" alt="${data.title}" />
            <a href="${lastLink}" target="_blank" class="preview-see_more">See More</a>
            <h1 class="preview-heading">${data.title}</h1>
            <p class="preview-description">${data.description}</p>
            <a href="${lastLink}" target="_blank" class="preview-link">${lastLink}</a>
            </div>`);
          })
          .catch((err) => console.error(err));
      }
    }
    return () => {
      setPreview(""); // This worked for me
    };
  }, []);

  const handlePopup = () => {
    setIsPostOpen(!isPostOpen);
    setpopupData(true);
  };

  return (
    <>
      {mobileView ? (
        <Box sx={mobileStyle}>
          <Header />
          <div className="parent">
            <div className="child12">
              <div className="userDetailPost">
                <img
                  className="post__profile"
                  src={
                    posts.userProfilePhoto
                      ? posts.userProfilePhoto
                      : defaultAvatar
                  }
                  alt="user"
                  onClick={handleOpen}
                />

                <div onClick={handleOpen}>
                  {" "}
                  <h3
                    style={{
                      marginTop: "5px",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.1px",
                      color: "#1a1919",
                    }}
                  >
                    {posts.userName}
                  </h3>
                  <p className="userDetail__align">
                    {" "}
                    {posts?.createdDate && (
                      <ReactTimeAgo
                        date={Date.parse(posts?.createdDate)}
                        locale="en-US"
                      />
                    )}{" "}
                  </p>
                </div>
              </div>
              {/* <p className="comment__text1">{posts.postMessage}</p> */}
              {posts.sharedDetail && posts.sharedDetail !== {} && (
                <div
                  className="post-content shared-border"
                  style={{
                    margin: `${posts.sharedDetail.sharedText ? "" : 0}`,
                    padding: `${posts.sharedDetail.sharedText ? "" : 0}`,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: posts?.sharedDetail?.sharedText,
                    }}
                  />
                  <img
                    className="post-image"
                    src={posts.sharedDetail.postImage}
                    alt=""
                    // onClick={handleOpen}
                    onClick={handleOpen}
                  />
                </div>
              )}
              <div
                className={`post-content ${
                  posts.sharedDetail && posts.sharedDetail.firstName
                    ? "shared-post-content"
                    : ""
                }`}
              >
                {posts.sharedDetail && posts.sharedDetail.firstName && (
                  <NameCard
                    posts={posts}
                    newSharedDetail={posts.sharedDetail}
                  />
                )}
                <div
                  className={`${
                    posts.sharedDetail && posts.sharedDetail.firstName
                      ? "shared-post-text"
                      : ""
                  }`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: posts.postMessage,
                    }}
                  />
                </div>
                {youtube ? (
                  <div dangerouslySetInnerHTML={{ __html: youtube }} />
                ) : (
                  " "
                )}
                {preview ? (
                  <div dangerouslySetInnerHTML={{ __html: preview }} />
                ) : (
                  " "
                )}
                {!youtube &&
                  posts.postImageURLs &&
                  posts.postImageURLs?.length > 0 && (
                    <div style={{ marginBottom: "10px" }}>
                      <Slider
                        onClick={handleOpen}
                        images={posts.postImageURLs}
                        height="500px"
                        width="100%"
                        // onClick={handleOpen}
                      />
                    </div>
                  )}
                {!youtube && posts.postImageURL ? (
                  posts.postImageURL.match(regex) ? (
                    <img
                      className="post-image"
                      src={posts.postImageURL}
                      // onClick={handlePopup}
                      onClick={handleOpen}
                      alt=""
                      style={{ cursor: "pointer", marginTop: "10px" }}
                    />
                  ) : (
                    <video
                      className="post-image"
                      // onClick={handlePopup}
                      onClick={handleOpen}
                      controls
                    >
                      <source
                        src={posts.postImageURL}
                        style={{ cursor: "pointer", marginTop: "10px" }}
                        onClick={handleOpen}
                      />
                    </video>
                  )
                ) : (
                  ""
                )}
                {!youtube && posts.video && (
                  <video className="post-image" onClick={handleOpen} controls>
                    <source
                      src={posts.video}
                      style={{ cursor: "pointer", marginTop: "10px" }}
                      onClick={handleOpen}
                    />
                  </video>
                )}
              </div>

              <div class="container__preview__post">
                <div className="post-footer post-header border post__width">
                  <div className="runs-hld1 " onClick={handleOpen}>
                    <div className="displayFlex run_icon_post">
                      <div>
                        {runsGivenValue === 0 && !ownPost && (
                          <ScoreRuns
                            type="post-menu"
                            setShowRuns={setShowRuns}
                            setRunsGiven={setRunsGiven}
                            setRunsScored={setRunsScored}
                            setRunsGivenValue={setRunsGivenValue}
                            ownPost={ownPost}
                            showRuns={showRuns}
                            // posts={posts}
                            // handleupdaterun={updateRuninPitch}
                          />
                        )}
                        {runsGivenValue !== 0 && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runsImage}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                  visibility: "hidden",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                        {ownPost && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runs}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <p className="post_count hover_css">{updateRun}</p>
                    </div>
                    <div className="icon_holder_style">Runs</div>
                  </div>
                  <div className="comments-hld3" style={{ paddingTop: "13px" }}>
                    <div className="displayFlex">
                      <img
                        src={newcomment}
                        onClick={handleOpen}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                      />
                      <div
                        className="comments-stat post_count"
                        style={{ paddingLeft: "5px" }}
                      >
                        {updateCommentCount}
                      </div>
                      <div className="mobile-comments-stat  post_count">
                        {/* {updateCommentCount} */}
                      </div>
                    </div>
                    <div className="icon_holder_style">Comments</div>
                  </div>

                  <div className="share-hld2" style={{ textAlign: "center" }}>
                    <div className="share-btn-container">
                      {/* //add new image */}
                      {sharedisable ? (
                        <img
                          src={emptyreshare}
                          className="icon-btn post_icon_image"
                          alt=""
                          role="button"
                          onClick={handleOpen}
                        />
                      ) : (
                        <img
                          src={newreshare}
                          onClick={handleOpen}
                          className="icon-btn1 post_icon_image"
                          alt=""
                          role="button"
                        />
                      )}
                      <div className="comments-stat">
                        {/* {postlocation === "/pitch" && " Share"} */}
                        {props?.post?.sharedPost ? (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          >
                            {" "}
                            {updateRepostCount}
                          </p>
                        ) : (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          ></p>
                        )}
                      </div>
                    </div>
                    <div className="icon_holder_style">Repost</div>
                    {/* <div
                      className={`share-modal ${
                        sharing ? "visible" : "hidden"
                      }`}
                    >
                      <div className="share-modal__container">
                        <div className="share-modal__container__form">
                          <div className="input-textarea">
                  <textarea placeholder="Write Something" id="sharedBody" onChange={getSharedBody}></textarea>
                </div>
                          <div className="content leftPost">
                  <div className="avatar">
                    <img className="avatar-image" src={avatar} alt={""} />
                    <div className="avatar-cnt">
                      <p>{posts.firstName + " " + posts.lastName}</p>
                      <p className="date-time">{posts.createdDate}</p>
                    </div>
                  </div>
                </div>
                        </div>
                        <div className="share-modal__container__actions">
                <ChampButton onClick={closeModal} classes="cancel secondary" label="Cancel" />
                <ChampButton onClick={() => sharePost()} classes="share primary" label="Share" />
              </div>
                      </div>
                    </div> */}
                  </div>

                  <div
                    className="comments-hld displayFlex"
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <div>
                      <img
                        src={newshare}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                    </div>
                    <div className="icon_holder_style">Share</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* middle section */}
          <div style={{ textAlign: "center" }}>
            <span class="outer__line span__icon"></span>
            <span class="span__icon">
              <img src={Logo} alt="" />
            </span>
            <span class="outer__line span__icon"></span>
          </div>
          <div className="middle__text">Sign up for our best experience</div>
          <div className="container__preview__middle">
            <div className="left__text">more from {posts.userName}</div>
            <div className="right__text" onClick={handleOpen}>
              View All
            </div>
          </div>
          {/* middle section end */}
          {/* sample pitch */}

          {postsOfUserId.map((data, index) => (
            <div className="parent" key={index}>
              <div className="child12">
                <div className="userDetailPost">
                  <img
                    className="post__profile"
                    src={
                      data.userProfilePhoto
                        ? data.userProfilePhoto
                        : defaultAvatar
                    }
                    alt="user"
                    onClick={handleOpen}
                  />

                  <div>
                    {" "}
                    <h3
                      style={{
                        marginTop: "5px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        color: "#1a1919",
                      }}
                    >
                      {data.userName}
                    </h3>
                    <p className="userDetail__align">
                      {" "}
                      {data?.createdDate && (
                        <ReactTimeAgo
                          date={Date.parse(data?.createdDate)}
                          locale="en-US"
                        />
                      )}{" "}
                    </p>
                  </div>
                </div>
                {/* <p className="comment__text1">{posts.postMessage}</p> */}
                {data.sharedDetail && data.sharedDetail.sharedText ? (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{
                      __html: data.sharedDetail.sharedText,
                    }}
                  />
                ) : (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{ __html: data.postMessage }}
                  />
                )}
                {data.sharedDetail && data.sharedDetail !== {} ? (
                  <div className="child__shared">
                    <div className="userDetailPost">
                      <img
                        className="post__profile"
                        src={
                          data.sharedDetail.profilePhoto
                            ? data.sharedDetail.profilePhoto
                            : defaultAvatar
                        }
                        alt="user"
                      />

                      <div>
                        {" "}
                        <h3
                          style={{
                            marginTop: "5px",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            letterSpacing: "0.1px",
                            color: "#1a1919",
                          }}
                        >
                          {data.sharedDetail.firstName}{" "}
                          {data.sharedDetail.lastName}
                        </h3>
                        <p className="userDetail__align">
                          {" "}
                          {data?.createdDate && (
                            <ReactTimeAgo
                              date={Date.parse(data?.createdDate)}
                              locale="en-US"
                            />
                          )}{" "}
                        </p>
                      </div>
                    </div>
                    {/* <p className="comment__text1">{posts.postMessage}</p> */}
                  </div>
                ) : (
                  <div className="image__wrapper__post">
                    {index === 0 && data.postImageURL ? (
                      data.postImageURL.match(regex) ? (
                        <img
                          className="post__url"
                          src={data.postImageURL}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <video className="post__url" controls>
                          <source
                            src={data.postImageURLs}
                            style={{ cursor: "pointer" }}
                          />
                        </video>
                      )
                    ) : (
                      ""
                    )}

                    {index === 1 && data.postImageURL ? (
                      data.postImageURL.match(regex) ? (
                        <img
                          className="post__url"
                          src={data.postImageURL}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <video className="post__url" controls>
                          <source
                            src={data.postImageURL}
                            style={{ cursor: "pointer" }}
                          />
                        </video>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                )}

                {/* <div class="container__preview__post">
                  {data.postCommentCount > 0 ? (
                    <div class="left">
                      <img
                        src={CommentsIcon}
                        className="comment__icon12"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                      <p style={{ marginLeft: "2px" }}>
                        {data.postCommentCount} Comment
                      </p>
                    </div>
                  ) : (
                    <div class="left">
                      <img
                        // src={CommentsIconGray}
                        className="comment__icon__gray"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                      <p style={{ marginLeft: "2px", color: "white" }}>
                        {data.postCommentCount} Comment
                      </p>
                    </div>
                  )}
                  {data?.sharedPost?.length > 0 ? (
                    <div
                      style={{ color: "pink", fontSize: "16px" }}
                      class="center__preview shareiconesize"
                    >
                      <img
                        src={ShareIcon}
                        className="share__icon__post"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />{" "}
                      {data?.sharedPost ? (
                        <p style={{ color: "#242a37", margin: "3px" }}>
                          {" "}
                          {data?.sharedPost.length}
                        </p>
                      ) : (
                        <p style={{ color: "#242a37", margin: "3px" }}>0</p>
                      )}
                    </div>
                  ) : (
                    <div class="center__preview shareiconesize">
                      <img
                        src={ShareIconGray}
                        className="share__icon__gray"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                    </div>
                  )}
                  <div class="rightPost">
                    <RunCard run={data?.postRunCount} />
                    <img
                      src={RunsIcon}
                      className="icon-btn imge__post"
                      alt=""
                      role="button"
                      onClick={handleOpen}
                    />
                  </div>
                </div> */}

                {/* {/start} */}
                <div className="post-footer post-header border post__width">
                  <div className="runs-hld " onClick={handleOpen}>
                    <div className="displayFlex run_icon_post">
                      <div>
                        {runsGivenValue === 0 && !ownPost && (
                          <ScoreRuns
                            type="post-menu"
                            setShowRuns={setShowRuns}
                            setRunsGiven={setRunsGiven}
                            setRunsScored={setRunsScored}
                            setRunsGivenValue={setRunsGivenValue}
                            ownPost={ownPost}
                            showRuns={showRuns}
                            // posts={posts}
                            // handleupdaterun={updateRuninPitch}
                          />
                        )}
                        {runsGivenValue !== 0 && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runsImage}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                  visibility: "hidden",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                        {ownPost && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runs}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <p className="post_count hover_css">{updateRun}</p>
                    </div>
                    <div className="icon_holder_style">Runs</div>
                  </div>
                  <div className="comments-hld" style={{ paddingTop: "13px" }}>
                    <div className="displayFlex">
                      <img
                        src={newcomment}
                        onClick={handleOpen}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                      />
                      <div
                        className="comments-stat post_count"
                        style={{ paddingLeft: "5px" }}
                      >
                        {updateCommentCount}
                      </div>
                      <div className="mobile-comments-stat  post_count">
                        {/* {updateCommentCount} */}
                      </div>
                    </div>
                    <div className="icon_holder_style">Comments</div>
                  </div>

                  <div className="share-hld" style={{ textAlign: "center" }}>
                    <div className="share-btn-container">
                      {/* //add new image */}
                      {sharedisable ? (
                        <img
                          src={emptyreshare}
                          className="icon-btn post_icon_image"
                          alt=""
                          role="button"
                          onClick={handleOpen}
                        />
                      ) : (
                        <img
                          src={newreshare}
                          onClick={handleOpen}
                          className="icon-btn1 post_icon_image"
                          alt=""
                          role="button"
                        />
                      )}
                      <div className="comments-stat">
                        {/* {postlocation === "/pitch" && " Share"} */}
                        {props?.post?.sharedPost ? (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          >
                            {" "}
                            {updateRepostCount}
                          </p>
                        ) : (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          ></p>
                        )}
                      </div>
                    </div>
                    <div className="icon_holder_style">Repost</div>
                  </div>

                  <div
                    className="comments-hld displayFlex"
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <div>
                      <img
                        src={newshare}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                    </div>
                    <div className="icon_holder_style">Share</div>
                  </div>
                </div>
                {/* {/End} */}
              </div>
            </div>
          ))}
          {/* sample pitch end */}
          {mobileView && (
            <Box className=" mobilefooter">
              <div className="footer_first">
                <img src={Logo} className="" />
                <p className="mobilefootername">ChampHunt</p>
              </div>
              <p className="mobilefooter__">
                Champhunt is a 360 degree platform for all things cricket. Your
                one-stop destination to earn from your love of cricket on the
                internet.{" "}
              </p>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Header />
          <Box sx={style}>
            <div className="parent">
              <div className="child12">
                <div className="userDetailPost">
                  <img
                    className="post__profile"
                    src={
                      posts.userProfilePhoto
                        ? posts.userProfilePhoto
                        : defaultAvatar
                    }
                    alt="user"
                    onClick={handleOpen}
                  />

                  <div onClick={handleOpen}>
                    {" "}
                    <h3
                      style={{
                        marginTop: "5px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        color: "#1a1919",
                      }}
                    >
                      {posts.userName}
                    </h3>
                    <p className="userDetail__align">
                      {" "}
                      {posts?.createdDate && (
                        <ReactTimeAgo
                          date={Date.parse(posts?.createdDate)}
                          locale="en-US"
                        />
                      )}{" "}
                    </p>
                  </div>
                </div>
                {/* {posts.sharedDetail && posts.sharedDetail.sharedText ? (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{
                      __html: posts.sharedDetail.sharedText,
                    }}
                  />
                ) : (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{ __html: posts.postMessage }}
                  />
                )} */}

                {posts.sharedDetail && posts.sharedDetail !== {} && (
                  <div
                    className="post-content shared-border"
                    style={{
                      margin: `${posts.sharedDetail.sharedText ? "" : 0}`,
                      padding: `${posts.sharedDetail.sharedText ? "" : 0}`,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: posts?.sharedDetail?.sharedText,
                      }}
                    />
                    <img
                      className="post-image"
                      src={posts.sharedDetail.postImage}
                      alt=""
                      // onClick={handleOpen}
                      onClick={handleOpen}
                    />
                  </div>
                )}
                <div
                  className={`post-content ${
                    posts.sharedDetail && posts.sharedDetail.firstName
                      ? "shared-post-content"
                      : ""
                  }`}
                >
                  {posts.sharedDetail && posts.sharedDetail.firstName && (
                    <NameCard
                      posts={posts}
                      newSharedDetail={posts.sharedDetail}
                    />
                  )}
                  <div
                    className={`${
                      posts.sharedDetail && posts.sharedDetail.firstName
                        ? "shared-post-text"
                        : ""
                    }`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: posts.postMessage,
                      }}
                    />
                  </div>
                  {youtube ? (
                    <div dangerouslySetInnerHTML={{ __html: youtube }} />
                  ) : (
                    " "
                  )}
                  {preview ? (
                    <div dangerouslySetInnerHTML={{ __html: preview }} />
                  ) : (
                    " "
                  )}
                  {!youtube &&
                    posts.postImageURLs &&
                    posts.postImageURLs?.length > 0 && (
                      <div style={{ marginBottom: "10px" }}>
                        <Slider
                          onClick={handleOpen}
                          images={posts.postImageURLs}
                          height="500px"
                          width="100%"
                          // onClick={handleOpen}
                        />
                      </div>
                    )}
                  {!youtube && posts.postImageURL ? (
                    posts.postImageURL.match(regex) ? (
                      <img
                        className="post-image"
                        src={posts.postImageURL}
                        // onClick={handlePopup}
                        onClick={handleOpen}
                        alt=""
                        style={{ cursor: "pointer", marginTop: "10px" }}
                      />
                    ) : (
                      <video
                        className="post-image"
                        onClick={handlePopup}
                        controls
                      >
                        <source
                          src={posts.postImageURL}
                          style={{ cursor: "pointer", marginTop: "10px" }}
                          onClick={handleOpen}
                        />
                      </video>
                    )
                  ) : (
                    ""
                  )}
                  {!youtube && posts.video && (
                    <video className="post-image" onClick={handleOpen} controls>
                      <source
                        src={posts.video}
                        style={{ cursor: "pointer", marginTop: "10px" }}
                        onClick={handleOpen}
                      />
                    </video>
                  )}
                </div>
              </div>
              <div className="child2">
                <h5 className="comment__count">
                  {posts.postCommentCount} Comment
                </h5>

                <div className="comments__block">
                  {comments.map((comments, index) => {
                    return (
                      <div className="comment__section" key={index}>
                        <div className="poll__user">
                          <img
                            src={
                              comments.userProfilePhoto
                                ? comments.userProfilePhoto
                                : defaultAvatar
                            }
                            alt="user"
                          />
                          <div className="user__details">
                            <h3
                              style={{
                                marginTop: "12px",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                letterSpacing: "0.1px",
                                color: "#0c5ba0",
                              }}
                            >
                              {comments.userName}
                            </h3>
                            <p>
                              <p className="comment__time">
                                {comments?.createdDate && (
                                  <ReactTimeAgo
                                    date={Date.parse(comments?.createdDate)}
                                    locale="en-US"
                                  />
                                )}
                              </p>
                            </p>
                            <p className="comment__text">
                              {comments.text.length > 10
                                ? comments.text.substring(0, 80) + "..."
                                : comments.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* {/start} */}
                <div className="post-footer post-header border post__width">
                  <div className="runs-hld " onClick={handleOpen}>
                    <div className="displayFlex run_icon_post">
                      <div>
                        {runsGivenValue === 0 && !ownPost && (
                          <ScoreRuns
                            type="post-menu"
                            setShowRuns={setShowRuns}
                            setRunsGiven={setRunsGiven}
                            setRunsScored={setRunsScored}
                            setRunsGivenValue={setRunsGivenValue}
                            ownPost={ownPost}
                            showRuns={showRuns}
                            // posts={posts}
                            // handleupdaterun={updateRuninPitch}
                          />
                        )}
                        {runsGivenValue !== 0 && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runsImage}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                  visibility: "hidden",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                        {ownPost && (
                          <div className="">
                            <div
                              style={{
                                margin: "auto",
                                display: "flex",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={runs}
                                onClick={handleOpen}
                                style={{
                                  // cursor: "not-allowed",
                                  padding: "0px",
                                  paddingRight: "3px",
                                }}
                                alt="img"
                                className="post_icon_image"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <p className="post_count hover_css">{updateRun}</p>
                    </div>
                    <div className="icon_holder_style">Runs</div>
                  </div>
                  <div className="comments-hld" style={{ paddingTop: "13px" }}>
                    <div className="displayFlex">
                      <img
                        src={newcomment}
                        onClick={handleOpen}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                      />
                      <div
                        className="comments-stat post_count"
                        style={{ paddingLeft: "5px" }}
                      >
                        {updateCommentCount}
                      </div>
                      <div className="mobile-comments-stat  post_count">
                        {/* {updateCommentCount} */}
                      </div>
                    </div>
                    <div className="icon_holder_style">Comments</div>
                  </div>

                  <div className="share-hld" style={{ textAlign: "center" }}>
                    <div className="share-btn-container">
                      {/* //add new image */}
                      {sharedisable ? (
                        <img
                          src={emptyreshare}
                          className="icon-btn post_icon_image"
                          alt=""
                          role="button"
                          onClick={handleOpen}
                        />
                      ) : (
                        <img
                          src={newreshare}
                          onClick={handleOpen}
                          className="icon-btn1 post_icon_image"
                          alt=""
                          role="button"
                        />
                      )}
                      <div className="comments-stat">
                        {/* {postlocation === "/pitch" && " Share"} */}
                        {props?.post?.sharedPost ? (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          >
                            {" "}
                            {updateRepostCount}
                          </p>
                        ) : (
                          <p
                            style={{ color: "#242a37" }}
                            className="post_count"
                          ></p>
                        )}
                      </div>
                    </div>
                    <div className="icon_holder_style">Repost</div>
                  </div>

                  <div
                    className="comments-hld displayFlex"
                    style={{ display: "inline-block", textAlign: "center" }}
                  >
                    <div>
                      <img
                        src={newshare}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                        onClick={handleOpen}
                      />
                    </div>
                    <div className="icon_holder_style">Share</div>
                  </div>
                </div>
                {/* {/End} */}

                <div class="container__preview__post">
                  <div class="left-avt">
                    <img
                      src={defaultAvatar}
                      style={{ height: "40px", width: "40px" }}
                      alt="user"
                    />
                  </div>

                  <div class="comment__textbox123" onClick={handleOpen}>
                    Write your comment...
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </>
      )}
      {Open && <Popup Open={Open} handleClose={handleClose} />}
    </>
  );
};

const return1SecondPlusDate = () => {
  let t = new Date();

  t.setSeconds(t.getSeconds() + 100);

  return t;
};
export default Posts;
