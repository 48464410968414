import React, { useEffect, useState } from "react";
import "../arena/index.scss";
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import GameModal from "../game-modal";
import { useNavigate } from "react-router-dom";
import bookAnimation from "../../../assets/images/rewards/animation.json";
import WhiteBallIcon from "../../../assets/images/rewards/ballwhite.png";
import RedBallIcon from "../../../assets/images/rewards/redBall.png";
import choukaIcon from "../../../assets/images/rewards/chouka.png";
import Lottie from "react-lottie";
import SwipeAnimation from "../../../assets/images/book-cricket/swipeAnimation.json";
import winAnimation from "../../../assets/images/book-cricket/win-animation.gif";
import twoRuns from "../../../assets/images/book-cricket/two-run.svg";
import fourRuns from "../../../assets/images/book-cricket/four-run.svg";
import sixRuns from "../../../assets/images/book-cricket/six-run.svg";
import wicket from "../../../assets/images/book-cricket/wicket.svg";
import outAnimation from "../../../assets/images/book-cricket/out-animation.gif";
const PlayWithComputer = () => {
  const [title, setTitle] = useState("");
  const [gameAnimation, setGameAnimation] = useState(null);
  const [description, setDescription] = useState("");
  const [wickets, setWickets] = useState(2);
  const [open, setOpen] = useState(false);
  const [isPlayerTurn, setIsPlayerTurn] = useState(true);
  const [computerLatestScore, setComputerLatestScore] = useState(null);
  const [game, setGame] = useState({
    playerScore: 0,
    computerScore: 0,
    playerOutCount: 0,
    computerOutCount: 0,
    playerGameInfo: [],
    computerGameInfo: [],
  });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const getPossibleRuns = () => {
    const runs = [2, 4, 6, 0];
    return runs[Math.floor(Math.random() * runs.length)];
  };

  const checkMilestone = (score, player) => {
    if (score === 50 || score === 100) {
      setTitle("Milestone Reached!");
      setGameAnimation(winAnimation);
      setDescription(`${player} has reached ${score} runs! Congratulations!`);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  };

  const handleTurn = (isPlayer) => {
    const runs = getPossibleRuns();
    updateGameState(isPlayer, runs);
    setIsVideoPlaying(true);

    if (isPlayer) {
      if (runs === 0) {
        const remainingWickets = 2 - game.playerOutCount;
        setGameAnimation(outAnimation);
        setTitle("OUT");
        setWickets(remainingWickets);
        setDescription(
          `You are out! ${remainingWickets} wicket${
            remainingWickets !== 1 ? "s" : ""
          } remaining.`
        );
        setOpen(true);
      } else if (runs === 4) {
        setGameAnimation(fourRuns);
        const remainingWickets = 2 - game.playerOutCount;
        setWickets(remainingWickets);
        setTitle("Four-tastic!");
        setDescription("You hit a boundary for 4 runs!");
        setOpen(true);
      } else if (runs === 6) {
        setGameAnimation(sixRuns);
        const remainingWickets = 2 - game.playerOutCount;
        setWickets(remainingWickets);
        setTitle("Sixer Supreme!");
        setDescription("You smashed a sixer!");
        setOpen(true);
      }

      if (runs === 0 || runs === 4 || runs === 6) {
        setTimeout(() => {
          setOpen(false);
          setIsVideoPlaying(false);
          setIsPlayerTurn(false);
        }, 2000);
      } else {
        setIsVideoPlaying(false);
        setIsPlayerTurn(false);
      }
    } else {
      setComputerLatestScore(runs);
      if (runs === 0) {
        const remainingWickets = 2 - game.computerOutCount;
        setGameAnimation(outAnimation);
        setTitle("Champhunt OUT");
        setDescription(
          `Champhunt is out! ${remainingWickets} wicket${
            remainingWickets !== 1 ? "s" : ""
          } remaining.`
        );
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
          setIsVideoPlaying(false);
          setIsPlayerTurn(true);
          setComputerLatestScore(null);
        }, 2000);
      } else {
        setTimeout(() => {
          setIsVideoPlaying(false);
          setIsPlayerTurn(true);
          setComputerLatestScore(null);
        }, 2000);
      }
    }
  };

  const updateGameState = (isPlayer, runs) => {
    setGame((prevGame) => {
      const newGame = { ...prevGame };
      if (isPlayer) {
        if (runs === 0) {
          newGame.playerOutCount++;
        } else {
          newGame.playerScore += runs;
          checkMilestone(newGame.playerScore, "You");
        }
        newGame.playerGameInfo.push({ run: runs });
      } else {
        if (runs === 0) {
          newGame.computerOutCount++;
        } else {
          newGame.computerScore += runs;
          checkMilestone(newGame.computerScore, "Champhunt");
        }
        newGame.computerGameInfo.push({ run: runs });
      }
      return newGame;
    });
  };

  useEffect(() => {
    if (!isPlayerTurn) {
      const delay = Math.random() * 1000 + 1000;
      setTimeout(() => handleTurn(false), delay);
    }
  }, [isPlayerTurn]);

  useEffect(() => {
    if (game.playerOutCount === 3 || game.computerOutCount === 3) {
      let winner;
      if (game.playerScore > game.computerScore) {
        winner = "You";
      } else if (game.playerScore < game.computerScore) {
        winner = "Champhunt";
      } else {
        winner = "It's a draw";
      }
      setTitle("Game Over!");
      setGameAnimation(winAnimation);
      setDescription(`${winner} won the match!`);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        navigate("/games/book-cricket");
      }, 5000);
    }
  }, [
    game.playerOutCount,
    game.computerOutCount,
    game.playerScore,
    game.computerScore,
    navigate,
  ]);

  return (
    <div className="book-cricket-arena">
      {open && (
        <GameModal
          gameAnimation={gameAnimation}
          title={title}
          description={description}
          wickets={wickets}
          open={open}
          setOpen={setOpen}
          game={game}
          handleClose={handleClose}
        />
      )}
      <div className="arena-pitch">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="user-stack"
        >
          {/* Player profile */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Typography
              variant="h6"
              color="#ffffff"
              className="arena-user-name"
            >
              You
            </Typography>
            <Avatar
              className="user-arena-avatar"
              sx={{ border: "2px solid #ffffff" }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={0}
            >
              {[...Array(game.playerOutCount)].map((_, index) => (
                <img
                  src={RedBallIcon}
                  key={index}
                  alt="Red Ball Icon"
                  className="ball-arena--red"
                />
              ))}
              {[...Array(3 - game.playerOutCount)].map((_, index) => (
                <img
                  src={WhiteBallIcon}
                  key={index}
                  alt="White Ball Icon"
                  className="ball-arena--white"
                />
              ))}
            </Stack>
          </Stack>

          {/* Scoreboard */}
          <Box textAlign="center">
            <Typography
              variant="h6"
              color="#ffffff"
              fontSize="40px"
              fontWeight={700}
              className="Vs-arena"
            >
              VS
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              marginTop={0}
              gap={2}
              backgroundColor="#002C53;"
              padding={1}
              borderRadius="6px"
              fontWeight={600}
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontWeight={600}
                  color="#ffffff"
                >
                  {game.playerScore}/{game.playerOutCount}
                </Typography>
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontWeight={600}
                  color="#ffffff"
                >
                  {game.playerGameInfo.length}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width="70px"
                  sx={{ backgroundColor: "#ffffff" }}
                  borderRadius="4px"
                >
                  <Typography variant="p" fontSize="10px" fontWeight={600}>
                    Score
                  </Typography>
                </Box>
                <Box
                  width="70px"
                  sx={{ backgroundColor: "#ffffff" }}
                  borderTop="1px solid #000000"
                  borderRadius="4px"
                >
                  <Typography variant="p" fontSize="10px" fontWeight={600}>
                    No.of Tries
                  </Typography>
                </Box>
              </Stack>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontWeight={600}
                  color="#ffffff"
                >
                  {game.computerScore}/{game.computerOutCount}
                </Typography>
                <Typography
                  variant="h6"
                  fontSize="12px"
                  fontWeight={600}
                  color="#ffffff"
                >
                  {game.computerGameInfo.length}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          {/* Computer Profile */}
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Typography
              variant="h6"
              color="#ffffff"
              className="arena-user-name"
            >
              Champhunt
            </Typography>
            <Avatar
              className="user-arena-avatar"
              sx={{ border: "2px solid #ffffff" }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={0}
            >
              {[...Array(game.computerOutCount)].map((_, index) => (
                <img
                  src={RedBallIcon}
                  key={index}
                  alt="Red Ball Icon"
                  className="ball-arena--red"
                />
              ))}
              {[...Array(3 - game.computerOutCount)].map((_, index) => (
                <img
                  src={WhiteBallIcon}
                  key={index}
                  alt="White Ball Icon"
                  className="ball-arena--white"
                />
              ))}
            </Stack>
          </Stack>
        </Stack>

        {/* Balls */}
        <Stack direction="column" className="second-section-main">
          <div className="arena-parent1">
            <div className="overflow-row">
              {game.playerGameInfo.map((detail, index) => (
                <Box
                  key={index}
                  height={15}
                  width={15}
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="10px"
                  fontWeight="800"
                  flexShrink={0}
                >
                  {detail.run === 0 && (
                    <img
                      src={RedBallIcon}
                      alt="Red Ball"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 2 && (
                    <img
                      src={twoRuns}
                      alt="Two Runs"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 4 && (
                    <img
                      src={choukaIcon}
                      alt="Four Runs"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 6 && (
                    <img src={sixRuns} alt="Sixer" className="size-runs-icon" />
                  )}
                </Box>
              ))}
            </div>
          </div>
          <Typography
            variant="h6"
            color="#0C5BA0"
            fontSize="1.25rem"
            marginLeft="69px"
            marginTop="2px"
          >
            You
          </Typography>

          <div className="arena-parent2">
            <div className="overflow-row1">
              {game.computerGameInfo.map((detail, index) => (
                <Box
                  key={index}
                  height={15}
                  width={15}
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize="10px"
                  fontWeight="800"
                  className="not-resized-ball"
                  flexShrink={0}
                >
                  {detail.run === 0 && (
                    <img
                      src={RedBallIcon}
                      alt="Red Ball"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 2 && (
                    <img
                      src={twoRuns}
                      alt="Two Runs"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 4 && (
                    <img
                      src={choukaIcon}
                      alt="Four Runs"
                      className="size-runs-icon"
                    />
                  )}
                  {detail.run === 6 && (
                    <img src={sixRuns} alt="Sixer" className="size-runs-icon" />
                  )}
                </Box>
              ))}
            </div>
          </div>
          <Typography
            style={{
              color: "#0C5BA0",
              fontSize: "1.25rem",
              marginLeft: "69px",
              marginTop: "2px",
            }}
          >
            Champhunt
          </Typography>

          <Typography
            style={{
              color: "#F37121",
              fontSize: "35px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            {isPlayerTurn ? game.playerScore : game.computerScore}
          </Typography>
          <div>
            <Lottie
              options={{
                loop: false,
                autoplay: false,
                animationData: bookAnimation,
              }}
              width="205px"
              height="145px"
              isStopped={!isVideoPlaying}
              isPaused={!isVideoPlaying}
            />
          </div>
          <Button
            fontSize="14px"
            onClick={() => handleTurn(true)}
            className="swipe-button"
            disabled={
              !isPlayerTurn ||
              game.playerOutCount === 3 ||
              game.computerOutCount === 3
            }
          >
            {isPlayerTurn &&
              game.playerOutCount < 3 &&
              game.computerOutCount < 3 && (
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: SwipeAnimation,
                  }}
                  width={50}
                  height={50}
                />
              )}
          </Button>
          {!isPlayerTurn && (
            <Typography
              style={{
                color: "white",
                fontSize: "1.25rem",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {computerLatestScore !== null
                ? `Champhunt got ${computerLatestScore} ${
                    computerLatestScore === 0 ? "- OUT!" : "runs"
                  }`
                : "Champhunt's turn..."}
            </Typography>
          )}
        </Stack>
      </div>
    </div>
  );
};

export default PlayWithComputer;
