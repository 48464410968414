import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import InputLabel from '@mui/material/InputLabel';
import {FormControl, TextField} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import AddPredictionItem from "./AddPlayerItem";
import MenuItem from '@mui/material/MenuItem';
import {Autocomplete} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { faL } from "@fortawesome/free-solid-svg-icons";
import { FitScreen } from "@mui/icons-material";
const userID = getStorageItem("user_id");
const accessToken = getStorageItem("token");

const Index = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [team, setTeam] = React.useState('');
  const [allPlayers, setAllPlayers] = useState([]);
  const getPlayersbyTeam=async(teamName)=>{
    if (!accessToken) return
    
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/fantasyPlayers/getPlayersByTeam/${teamName}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body:{fantasyId:state?.id}, 
    };
    await axios(options)
      .then(({ data }) => {
        setAllPlayers(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  }
  const handleChange = (event) => {
    setTeam(event.target.value);
    setAllPlayers([]);
    getPlayersbyTeam(event.target.value);
    
};


  return (
    
    <div className="contest_admin-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-header">
            <p className="contest-admin-create-wrapper-header--text">
              {state?.matchName}
            </p>
            <p
              onClick={() => {
                navigate(`/admin/fantasy`);
              }}
              className="contest-admin-create-wrapper-header--delete-text"
            >
              Back
            </p>
          </div>
          
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Team</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={team}
        label="Team"
        onChange={handleChange}
      >
       <MenuItem value="">
         <em>Country</em>
        </MenuItem>
         {['IND', 'PAK', 'IRE', 'CAN', 'USA', 'ENG', 'AUS', 'NAM', 'SCO', 'OMA', 'NZ', 'WI', 'AFG', 'UGA', 'PNG', 'SA', 'SL', 'BAN', 'NET', 'NEP','ZIM'].map((item) => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
        ))}
      </Select>
      
    </FormControl>
    <FormControl sx={{ m: 1, mt: 3 }}>
    
    
    <div className="contest_admin_prediction-list">
    
    {(allPlayers.length>0) && allPlayers?.map((prediction, index) => {
          {<InputLabel id="player" style={{marginLeft:"80px"}}>Select Players</InputLabel>}
          return (
            
            <AddPredictionItem
              key={index}
              data={prediction}
              fantasyId={state?.id}
              id={prediction._id}
            />
          );
        })}
    </div>
          
        
      </FormControl>
    
            <button
              onClick={()=>{
                navigate(`/admin/fantasy`);
              }}
              className="contest-preediction-admin-creation-submit-button"
            >
              Done
            </button>
            {/* <div className="contest-create-pagination-rounded-wrapper">
              <PaginationRounded />
            </div> */}
          </div>
        </div>
     
    
  );
};

export default Index;
