import React, { useEffect, useState } from "react";
import "./index.css";
import { toast } from 'react-toastify';
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
const accessToken = getStorageItem("token");

const TournamentList = () => {
    const [showFilter, setShowFilter] = useState(false);
    const [tournaments, setAllTournaments] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [liveStatus, setLiveStatus] = useState({});
    const [fromDate, setFromDate] = useState(
        moment(new Date()).format("yyyy-MM-DD")
    );
    const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

    const getAllTournaments = async () => {
        if (!accessToken) return;
        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/contest/fantasy/getAllTournaments`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        await axios(options)
            .then(({ data }) => {
                setAllTournaments(data);
                // Initialize live status for all tournaments
                const initialStatus = {};
                data.forEach(tournament => {
                    initialStatus[tournament._id] = tournament.isLive || false;
                });
                setLiveStatus(initialStatus);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    console.log(error);
                }
            });
    };

    const deleteTournament = async (id) => {
        try {
            const res = await axios.delete(`${global.config.ROOTURL.prod}/contest/fantasy/deleteTournament/${id}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            });
            if (res?.status === 200) {
                toast.success('Tournament deleted successfully');
                getAllTournaments();
            }
        } catch (error) {
            console.error("Error deleting tournament: ", error);
            toast.error('Error deleting tournament');
        }
    };

    const updateTournamentStatus = async (tId) => {
        try {
            const headers = {
                Authorization: "Bearer " + accessToken,
            };
            const res = await axios.patch(`${global.config.ROOTURL.prod}/contest/fantasy/updateTournamentStatus/${tId}`, {}, {
                headers,
            });
            if (res?.status === 200) {
                setLiveStatus((prevStatus) => ({
                    ...prevStatus,
                    [tId]: !prevStatus[tId],
                }));
                toast.success(`Tournament is ${liveStatus[tId] ? 'inactive' : 'live'}`);
            }
        } catch (error) {
            console.error("Error in updating tournament status: ", error);
            toast.error('Error in updating tournament status');
        }
    };

    useEffect(() => {
        getAllTournaments();
    }, []);

    return (
        <div className="contest_admin-wrapper">
            <div className="contest_admin_head">
                <h3 className="contest_admin_head-content--left">Tournament List</h3>
            </div>
            <div className="contest_admin_prediction-list">
                {tournaments?.map((item, index) => (
                    <div
                        style={{
                            border: "1px solid silver",
                            borderRadius: "15px 15px 0 0",
                            height: "150px",
                            width: "300px",
                            padding: "20px"
                        }}
                        key={index}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <h3 style={{
                                color: "#1a1919",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 500
                            }}>{item?.tournamentName}</h3>
                            <img
                                src="/svg/admin/delete.svg"
                                alt="delete"
                                className="contest_admin_prediction-item-delete"
                                key={item._id}
                                onClick={(e) => {
                                    e.preventDefault();
                                    deleteTournament(item._id);
                                }}
                            />
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontStyle: "italic",
                            fontWeight: 500
                        }}>
                            <p>Start-date</p>
                            <p>{item.startDate}</p>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                            fontSize: "14px",
                            fontStyle: "italic",
                            fontWeight: 500
                        }}>
                            <p>End-date</p>
                            <p>{item.endDate}</p>
                        </div>
                        <p
                            className="contest_admin_prediction-sub-schedule-button--text"
                            style={{ textAlign: "center" }}
                            onClick={(e) => {
                                e.stopPropagation();
                                updateTournamentStatus(item._id);
                            }}
                            key={item._id}
                        >
                            {liveStatus[item._id] ? 'Toggle Inactive' : 'Toggle Live'}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TournamentList;
