import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getStorageItem } from "../utils/sessionStorage";
import { useMediaQuery } from "@mui/material";
import NewCard from "./card/NewCard";
import "./marketplace.scss";
import { CartContext } from "../context/CartContext";
import productBg from "../assets/images/marketplace/products/product.png";
import productBg4 from "../assets/images/marketplace/products/product2.png";
import discountBg from "../assets/images/marketplace/discountBg.png";
import sports from "../assets/images/marketplace/category/sports.png";
import health from "../assets/images/marketplace/category/health.png";
import cloths from "../assets/images/marketplace/category/cloths.png";
import electronics from "../assets/images/marketplace/category/electronic.png";
import deals from "../assets/images/marketplace/deals.png";
import arrowLeft from "../assets/images/marketplace/leftArrow.png";
import arrowRight from "../assets/images/marketplace/rightArrow.png";
import addProductIcon from "../assets/images/marketplace/addProduct.png";
import shopIcon from "../assets/images/marketplace/shop2.png";
import wishlistIcon from "../assets/images/marketplace/whislist.png";
import cartIcon from "../assets/images/marketplace/cart.png";
import orderIcon from "../assets/images/marketplace/myorder.png";


// User Information
const userId = getStorageItem("user_id");

// Access Token
const accessToken = getStorageItem("token");

const MarketPlace = () => {
  const navigate = useNavigate();
  const Heading = ({ name, redirect }) => {
    return (
      <div className="marketplace__section--header">
        <h3 className="marketplace__section__title">{name}</h3>
        <div
          className="marketplace__section__see-more"
          onClick={() => {
            navigate(redirect);
          }}
        >
          See more
        </div>
      </div>
    );
  };
  const [allProducts, setAllProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [adminProducts, setAdminProducts] = useState([]);
  const [firstProduct, setFirstProduct] = useState(null);
  const [secondProduct, setSecondProduct] = useState(null);
  const [thirdProduct, setThirdProduct] = useState(null);
  const [fourthProduct, setFourthProduct] = useState(null);
  const [fifthProduct, setFifthProduct] = useState(null);
  const [sixthProduct, setSixthProduct] = useState(null);
  const [menu, setMenu] = useState(true);
  const [cart, setCart] = useContext(CartContext);
  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);
  const sliderRef3 = useRef(null);
  const mobileView = useMediaQuery("(max-width:7690px)");

  const categories = [
    {
      name: "Sports",
      image: sports,
    },
    {
      name: "Electronics",
      image: electronics,
    },
    {
      name: "Health",
      image: health,
    },
    {
      name: "Clothes",
      image: cloths,
    },
  ];

  async function fetchProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod + "/marketplace/product/getAllProducts",
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const products = await response.json();
      setAllProducts(products);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to get new products
  async function fetchNewProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getNewProducts?limit=20`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const { newProducts, count } = await response.json();
      setNewProducts(newProducts);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to get popular products
  async function fetchPopularProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getPopularProducts?limit=20`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const { popularProducts, count } = await response.json();
      setPopularProducts(popularProducts);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to get trending products
  async function fetchTrendingProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getTrendingProducts?limit=20`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const trendingProducts = await response.json();
      setTrendingProducts(trendingProducts);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to like a product
  async function likeProduct(productId) {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/${productId}/like/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  // Function to dislike a product
  async function unlikeProduct(productId) {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/${productId}/like/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const res = await response.json();
    } catch (err) {
      console.log(err);
    }
  }

  // function to get admin priority products
  async function fetchAdminPriorityProducts() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/adminPriorityProducts`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const adminPriorityProducts = await response.json();
      // set default values for each product state variable
      setFirstProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 1
        ) || null
      );
      setSecondProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 2
        ) || null
      );
      setThirdProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 3
        ) || null
      );
      setFourthProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 4
        ) || null
      );
      setFifthProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 5
        ) || null
      );
      setSixthProduct(
        adminPriorityProducts.find(
          (product) => product.productPriority === 6
        ) || null
      );
      setAdminProducts(adminPriorityProducts);
    } catch (err) {
      console.log(err);
    }
  }

  // Call the fetchProducts function within the useEffect hook
  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchNewProducts();
  }, []);

  useEffect(() => {
    fetchPopularProducts();
  }, []);

  useEffect(() => {
    fetchTrendingProducts();
  }, []);

  useEffect(() => {
    fetchAdminPriorityProducts();
  }, []);

  const handlePrevClick1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.scrollLeft -= 200;
    }
  };

  const handlePrevClick2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollLeft -= 200;
    }
  };

  const handlePrevClick3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.scrollLeft -= 200;
    }
  };

  const handleNextClick1 = () => {
    if (sliderRef1.current) {
      sliderRef1.current.scrollLeft += 200;
    }
  };
  const handleNextClick2 = () => {
    if (sliderRef2.current) {
      sliderRef2.current.scrollLeft += 200;
    }
  };
  const handleNextClick3 = () => {
    if (sliderRef3.current) {
      sliderRef3.current.scrollLeft += 200;
    }
  };

  return (
    <div>
      <Helmet>
        <title>Champhunt | MarketPlace</title>
      </Helmet>
      <div className="main_marketplace_container">
        <div className="marketplace__banner">
          <div className="banner__products">
            {firstProduct && firstProduct.productId ? (
              <div className={`product_item_container ${firstProduct?.productPriority}`}
                onClick={() => {
                  navigate(
                    `/marketplace/single/${firstProduct?.productId?._id}`
                  );
                }}
              >
                <img className="product_bg" src={productBg} alt="" />
                {firstProduct?.productId?.discount !== 0 && firstProduct?.productId?.discount !== undefined && (
                  <p className="product_item_discount">
                    {firstProduct?.productId?.discount}%OFF
                  </p>
                )}
                <img
                  className="product_item"
                  src={firstProduct?.productId?.images[0]}
                  alt={firstProduct?.productId?.title} />
                <p className="product_item_name">{firstProduct?.productId?.title?.substring(0, 10)}...</p>
              </div>
            ) : (
              <div className="product_item_sekeleton">No products found</div>
            )}
            {secondProduct && secondProduct.productId ? (
              <div className={`product_item_container ${secondProduct?.productPriority}`}
                onClick={() => {
                  navigate(
                    `/marketplace/single/${secondProduct?.productId?._id}`
                  );
                }}
              >
                <img className="product_bg" src={productBg} alt="" />
                {secondProduct?.productId?.discount !== 0 && secondProduct?.productId?.discount !== undefined && (
                  <p className="product_item_discount">
                    {secondProduct?.productId?.discount}%OFF
                  </p>
                )}
                <div className="product_image_container">
                  <img
                    className="product_item"
                    src={secondProduct?.productId?.images[0]}
                    alt={secondProduct?.productId?.title} />
                </div>
                <p className="product_item_name">{secondProduct?.productId?.title?.substring(0, 10)}...</p>
              </div>
            ) : (
              <div className="product_item_sekeleton">No products found</div>
            )}
            {thirdProduct && thirdProduct.productId ? (
              <div className={`product_item_container ${thirdProduct?.productPriority}`}
                onClick={() => {
                  navigate(
                    `/marketplace/single/${thirdProduct?.productId?._id}`
                  );
                }}
              >
                <img className="product_bg" src={productBg} alt="" />
                {thirdProduct?.productId?.discount !== 0 && thirdProduct?.productId?.discount !== undefined && (
                  <p className="product_item_discount">
                    {thirdProduct?.productId?.discount}%OFF
                  </p>
                )}
                <div className="product_image_container">
                  <img
                    className="product_item"
                    src={thirdProduct?.productId.images[0]}
                    alt={thirdProduct?.productId?.title} />
                </div>
                <p className="product_item_name">{thirdProduct?.productId?.title?.substring(0, 10)}...</p>
              </div>
            ) : (
              <div className="product_item_sekeleton">No products found</div>
            )}
          </div>
        </div>
        {fourthProduct ? (
          <div className="banner_product4_container">
            <img className="banner_product_item4" src={productBg4} alt="" />
            <img
              className="product_image4"
              src={fourthProduct?.productId?.images[0]}
              alt={fourthProduct.productId.title}
            />
            <div className="product_item4_discount_container">
              <img className="discountBg" src={discountBg} alt="" />
              <p className="product_item4_discount">{fourthProduct?.productId?.discount}% OFF</p>
            </div>
            <div className="product_item4_name_container">
              <h1 className="deal_name">BIG DEAL</h1>
              <h4 className="product_item4_name">{fourthProduct?.productId?.title}</h4>
            </div>
          </div>
        ) : null}

        {/* categories */}
        <div className="product_categories">
          <p className="category_heading">Categories</p>
          <div className={mobileView ? "category_slider_mobileView" : "category_slider"}>
            {categories.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(`/marketplace/category/${category.name}`);
                  }}
                  className="product_category_container"
                >
                  {/* <img className="bgImage" src={categoryBg} alt="" /> */}
                  <img
                    className="categoryProducts"
                    src={category.image ||
                      "https://e7.pngegg.com/pngimages/483/759/png-clipart-nike-air-max-nike-free-air-force-shoe-nike-white-outdoor-shoe.png"}
                    alt="" />
                  <p className="category_name_heading">{category.name}</p>
                </div>
              )
            })}
          </div>
        </div>

        {/* Popular */}
        {popularProducts.length > 0 && (
          <div className="common_code_products">
            <div className="heading_arrow">
              <h2 className="category_product_heading">Popular</h2>
              <div className="arrow_container">
                <img className="arrow_left" src={arrowLeft} alt="" onClick={handlePrevClick1} />
                <img className="arrow_right" src={arrowRight} alt="" onClick={handleNextClick1} />
              </div>
            </div>
            <div className="card-slider" ref={sliderRef1}>
              {popularProducts?.map((product, index) => {
                return <NewCard key={index} product={product} index={index} productType="popular" />
              })}
            </div>
          </div>
        )}
        {/* BannerSingle */}
        <div className="deals_container">
          <img className="inner_image" src={deals} alt="" />
        </div>

        {/* Trending */}
        {trendingProducts.length > 0 && (
          <div className="common_code_products">
            <div className="heading_arrow">
              <h2
                className="category_product_heading"
                onClick={() => navigate("marketplace/trending")}
              >Trending</h2>
              <div className="arrow_container">
                <img className="arrow_left" src={arrowLeft} alt="" onClick={handlePrevClick2} />
                <img className="arrow_right" src={arrowRight} alt="" onClick={handleNextClick2} />
              </div>
            </div>
            <div className="card-slider" ref={sliderRef2}>
              {trendingProducts?.map((product, index) => {
                return <NewCard key={index} product={product} index={index} productType="trending" />
              })}
            </div>
          </div>
        )}

        {/* New */}
        {newProducts.length > 0 && (
          <div className="common_code_products">
            <div className="heading_arrow">
              <h2 className="category_product_heading">New</h2>
              <div className="arrow_container">
                <img className="arrow_left" src={arrowLeft} alt="" onClick={handlePrevClick3} />
                <img className="arrow_right" src={arrowRight} alt="" onClick={handleNextClick3} />
              </div>
            </div>
            <div className="card-slider" ref={sliderRef3}>
              {newProducts?.map((product, index) => {
                return <NewCard key={index} product={product} index={index} productType="new" />
              })}
            </div>
          </div>
        )}

        {/* Icons */}
        <div className="icon__wrapper">
          <div className="icon__box">
            {menu && (
              <>
                <div
                  className="icon_container"
                  onClick={() => {
                    navigate("/marketplace/sell");
                  }}
                >
                  <img className="icon" src={addProductIcon} alt="sell" />
                  <h4 className="icon__text">Sell</h4>
                </div>
                <div
                  className="icon_container"
                  onClick={() => {
                    navigate("/marketplace/list");
                  }}
                >
                  <img className="icon" src={shopIcon} alt="list" />
                  <h4 className="icon__text">Shop</h4>
                </div>
                <div
                  className="icon_container"
                  onClick={() => {
                    navigate("/marketplace/wishlist");
                  }}
                >
                  <img className="icon" src={wishlistIcon} alt="wish-list" />
                  <h4 className="icon__text">Wishlist</h4>
                </div>
                <div
                  className="icon_container"
                  onClick={() => {
                    navigate("/marketplace/cart");
                  }}
                >
                  {cart?.length > 0 && (
                    <div className="cart-items-notify">{cart?.length}</div>
                  )}
                  <img className="icon" src={cartIcon} alt="wish-list" />
                  <h4 className="icon__text">Cart</h4>
                </div>
                <div
                  className="icon_container"
                  onClick={() => {
                    navigate(`/marketplace/order_status/${userId}`);
                  }}
                >
                  <img className="icon" src={orderIcon} alt="history" />
                  <h4 className="icon__text">Orders</h4>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketPlace;
