// FantassyModal.js

import React, {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ConfirmationImage from "../../assets/images/fantasyy/confirmimage.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import FantassyModal from "./FantassyModal";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import PlayerScorePageModal from "./PlayerScorePage";


export default function ConfirmationModal({ open, onClose, baseRuns, playerData, onYesClick}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const { playerDetails, fantasyId, fantasyMatchId, basePrice, _id} = playerData;
  const { playerName} = playerDetails;
  const [runsData, setRunsData] = useState(null);
  const [tournamentStatus,setTournamentStatus] = useState({});
  const accessToken = getStorageItem("token")
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : 400,
    height: "433px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
  };

  const gifStyle = {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "6rem",
  };

  const buttonStyle = {
    width: isMobile ? "48%" : "48%",
    backgroundColor: "#0C5BA0",
    color: "white",
    "&:hover": {
      backgroundColor: "#0C5BA0",
      color: "white",
    },
  };
  const highlightedTextStyle = {
    color: "#0C5BA0", 
    fontWeight: "bold",
  };
  
  const handleNoClick = () => {
    handleClose();
  };

  const handleClose = () => {
    onClose(); 
  };

  
  const getTournamentStatus = async () => {
    if (!accessToken) return
    const options = {
        method: "GET",
        url:
            global.config.ROOTURL.prod +
            `/contest/fantasy/liveTournament`,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };
    await axios(options)
        .then(({ data }) => {
            setTournamentStatus(data);
            console.log("data..",data)
        })
        .catch((error) => {
            if (error?.response?.status == 401) {
                console.log(error);
            }
        });
};

useEffect(()=>{
  getTournamentStatus()
},[])
 
  
  const handleYesClick = async () => {
    try {
      const accessToken = getStorageItem("token")
      const userId = getStorageItem("user_id");
      const buyPlayerRequest = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/contest/userFantasy/buyPlayer",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          userId,
        },
        data: {
          fantasyId: fantasyId, 
          fantasyMatchId: _id,
          tournamentId:tournamentStatus._id,
          basePrice,
        },

      };
     
      const response = await axios(buyPlayerRequest);
  
      if (response.status === 200) {
        const result = response.data;
        const hasPlayed = result.played; 
        setSecondModalOpen(true);
        onYesClick(_id, hasPlayed);
        handleClose();
        // await getRunsByNameForFantasyScore();
      } else {
        console.error("API call failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error.message);
    }
  };
  


  return (
    <>
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img src={ConfirmationImage} alt="Fantassy ConfirmationImage" style={gifStyle} />
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
          Are you sure...
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
        You want to buy <span style={highlightedTextStyle}>{playerName}</span> in{" "}
          <span style={highlightedTextStyle}>{baseRuns}</span> runs?
        </Typography>
        <Box sx={buttonContainerStyle}>
          <Button onClick={handleYesClick} sx={buttonStyle}>
            Yes
          </Button>
          <Button onClick={handleNoClick} sx={buttonStyle}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
    <FantassyModal open={secondModalOpen} onClose={() => setSecondModalOpen(false)} />

    </>
  );
}
