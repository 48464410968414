import React, { useState } from "react";
import "./index.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import useImageUploader from "../../utils/useImageUploader";

const AdminCreateCommentary = () => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState("");
    const { uploadImage, uploading, error, imageUrl } = useImageUploader();
    const accessToken = getStorageItem("token");
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const handleFileUpload = async () => {
        try {
            if (file) {
                const videoUrl = await uploadImage(file, 0.8);
                setUrl(videoUrl);
                setUploadSuccess(true);
            } else {
                toast.error("No file selected");
                setUploadSuccess(false);
            }
        } catch (error) {
            console.error("Error uploading video:", error);
            toast.error("Failed to upload video");
            return false;
        }
    };

    const handleCommentaryCreation = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (file && !url) {
            await handleFileUpload();
            if (!uploadSuccess) {
                setLoading(false);
                return;
            }
        }

        if (!url) {
            toast.error("No URL for video. Failed to upload video.");
            setLoading(false);
            return;
        }

        // Create data object for ad creation
        const data = {
            title,
            description,
            url,
        };

        // Validate input
        if (!title || !description || !url) {
            toast.error("Incomplete information");
            setLoading(false);
            return;
        }

        // Proceed with ad creation request
        try {
            const response = await axios.post(
                `${global.config.ROOTURL.prod}/commentary/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Headers": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                    },
                }
            );
            console.log("Commentary created successfully:", response.data);
            toast.success("Commentary created successfully!");
        } catch (err) {
            console.error("Error creating commentary:", err);
            toast.error("Failed to create commentary");
        } finally {
            // Reset form states and loading status
            setLoading(false);
            setTitle("");
            setDescription("");
            setUrl("");
            setFile(null);
            setFileName("");
        }
    };

    // Handle file change
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    return (
        <div className="commentary-creation-wrapper">
            <h1 className="commentary-creation__title">Upload Videos</h1>
            <form className="commentary-creation__form" onSubmit={handleCommentaryCreation}>
                <div>
                    <label htmlFor="commentary-title" className="commentary-creation__form-label">
                        Title
                    </label>
                    <input
                        id="commentary-title"
                        type="text"
                        className="commentary-creation__form-input"
                        placeholder="Enter video title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="commentary-description" className="commentary-creation__form-label">
                        Description
                    </label>
                    <textarea
                        id="commentary-description"
                        className="commentary-creation__form-textarea"
                        placeholder="Enter a description..."
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="commentary-file" className="commentary-creation__form-file-label">
                        <div>
                            <span>Browse files...</span>
                            <input
                                id="commentary-file"
                                type="file"
                                className="commentary-creation__form-file"
                                accept="video/*"
                                onChange={handleFileChange}
                            />
                            {fileName && <span>{fileName}</span>}
                        </div>
                    </label>
                </div>
                <div>
                    <button
                        type="submit"
                        className={`${loading || uploading
                            ? "commentary-creation-form-submit--disabled"
                            : "commentary-creation-form-submit--active"
                            }`}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AdminCreateCommentary;
