import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./videosList.scss"
import axios from 'axios';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@material-ui/core';
import { useMediaQuery } from '@mui/material';

import { getStorageItem } from "../../utils/sessionStorage";

const VideosList = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const [videosByAdmin, setVideosByAdmin] = useState([]);
    const mobileView = useMediaQuery("(max-width:768px)");

    const fetchVideosByAdmin = async () => {
        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/commentary/getAllVideosByAdmin",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then(({ data }) => {
                setVideosByAdmin(data);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };

    useEffect(() => {
        if (!accessToken) return;
        fetchVideosByAdmin();
    }, [])

    return (
        <div className='videoslist-main'>
            <div className='commentary-heading-container'>
                <ArrowBackIcon className='arrow-icon' onClick={() => navigate(-1)} />
                {mobileView ? (
                    <Typography className='heading'>Commentary</Typography>
                ) : (<Typography className='heading'>Commentary Premier League</Typography>)}
            </div>
            <div className='videos-list'>
                {videosByAdmin.map((video) => (
                    <div className='inner-container' key={video._id} onClick={() => navigate(`/commentary/recordVoice/${video?._id}`)}>
                        <video className='video-player' controls key={video._id}>
                            <source src={video.url} type="video/mp4" key={video._id} />
                            Your browser does not support the video tag.
                        </video>
                        <div className='text-container'>
                            <h2 className='title'>{video.title}</h2>
                            <p className='description'>{video.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideosList;