import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { clearStorage, getStorageItem } from "../../../../utils/sessionStorage";
import { Carousel } from "react-responsive-carousel";
import "./index.scss";

const Carousel2 = () => {
  const [carousel, setCarousel] = useState([]);
  const accessToken = getStorageItem("token");
  const getCarousels = async () => {
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/carousel/getLandingPageCarousel",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        const sortedCarousel = data.sort((a, b) => a.priority - b.priority); // Sorted based on priority
        setCarousel(sortedCarousel);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getCarousels();
  }, []);

  return (
    <div className="landingpage_carousel">
      <Carousel
        showArrows={false}
        infiniteLoop={true}
        emulateTouch={true}
        autoPlay={true}
        showStatus={false}
        showThumbs={false}
      >
        {carousel.map((data, index) => (
          <div key={index} className="carousel_container">
            <div className="img_container">
              {(
                data.name.endsWith('.gif') || data.name.endsWith('.png') ? (
                  <img src={data.name} alt="Landing Page Carousel" style={{width:"100%",height:"90vh"}}/>
                ) : (
                  <video key={data.name} autoPlay loop muted style={{ width: '100%', height: '90vh', objectFit: 'fill' }}>
                    <source src={data.name} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
export default Carousel2;
