import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/images/icons/runsTransaction-bg.png";
import backButton from "../../assets/images/icons/back-white-arrow.png";
import redBall from "../../assets/images/icons/red-ball.png";
import filterButton from "../../assets/images/icons/Filter.png";
import sortButton from "../../assets/images/icons/runsTransaction_sort.png";
import searchIcon from "../../assets/images/icons/search.png";
import calendarIcon from "../../assets/images/icons/calendar-icon.png";
import "./index.scss";
import DropdownSort from "../../components/runs-transaction/sort-dropdown.js";
import DropdownFilter from "../../components/runs-transaction/filter-dropdown.js";
import RunsTransactionInfo from "../../components/runs-transaction/index.js";
import pdfIcon from "../../assets/images/icons/icons_pdf.png";
import excelIcon from "../../assets/images/icons/icons_excel.png";
import axios from "axios";
import useOutsideClick from "./dropDown.js";

const RunsTransaction = () => {
  // by date filter
  const [activeButton, setActiveButton] = useState("This Week");
  const endWeekData = new Date()
  const startWeekData = new Date(endWeekData - 1000 * 60 * 60 * 24 * 7)
  const [startDate, setStartDate] = useState(startWeekData);
  const [endDate, setEndDate] = useState(endWeekData);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    const now = new Date()
    let startDate;
    if (buttonId === 'This Week') {
      startDate = new Date(now - 1000 * 60 * 60 * 24 * 7)
    } else if (buttonId === 'Last 15 Days') {
      startDate = new Date(now - 1000 * 60 * 60 * 24 * 15)
    } else if (buttonId === 'Last Month') {
      startDate = new Date(now - 1000 * 60 * 60 * 24 * 30)
    } else if (buttonId === 'Last 3 Months') {
      startDate = new Date(now - 1000 * 60 * 60 * 24 * 90)
    } else if (buttonId === 'Last Years') {
      startDate = new Date(now - 1000 * 60 * 60 * 24 * 365)
    }
    const formattedStartDate = startDate.toISOString()
    const formattedEndDate = now.toISOString()
    setStartDate(formattedStartDate)
    setEndDate(formattedEndDate)
  };

  // rt-sort button dropdown
  const [isSortOpen, setIsSortOpen] = useState(false); // State to track dropdown visibility
  const [isSortButtonActive, setIsSortButtonActive] = useState(false);
  const dateArray = ['This Week', 'Last 15 Days', 'Last Month', 'Last 3 Months', 'Last Years']
  const sortDropdown = () => {
    setIsSortOpen(!isSortOpen); // Toggle sort dropdown visibility
    setIsSortButtonActive(!isSortButtonActive); // Toggle sort button active state
    setIsFilterOpen(false); // Close filter dropdown when sort dropdown is opened
    setIsFilterButtonActive(false); // Set filter button inactive when sort dropdown is opened
  };

  // rt-filter button dropdown
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State to manage filter dropdown visibility
  const [isFilterButtonActive, setIsFilterButtonActive] = useState(false);
  const filterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
    setIsFilterButtonActive(!isFilterButtonActive);
    setIsSortOpen(false);
    setIsSortButtonActive(false);
  };

  const filterRef = useOutsideClick(() => {
    setIsFilterOpen(false);
    setIsFilterButtonActive(false); // Make button inactive when dropdown is closed
  });

  const sortRef = useOutsideClick(() => {
    setIsSortOpen(false);
    setIsSortButtonActive(false);
  });

  const [activeItem, setActiveItem] = useState("All"); // State to track active item
  const runsType = ["All", "Admin", "Pitch", "Deals", "Polls", "Predictions"];

  const [sortItem, setSortItem] = useState("All"); // State to track active item
  const sortType = ["All", "Credited", "Debited"];

  const handleClick = (item) => {
    setSortItem(item);
  };

  const [userRuns, setUserRuns] = useState(0)
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const getRuns = () => {
    if (!userId) return console.error("_userId required");

    const getTotalRun = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getTotalRun)
      .then((response) => {
        setUserRuns(response.data);
      })
      .catch((error) => {

        console.log(error);
      });
  };

  useEffect(() => {
    getRuns()
  }, [])

  return (
    <>
      <Helmet>
        <title>Champhunt | Runs-Transaction</title>
      </Helmet>

      <div className="runstransaction-container">
        {/* Banner */}
        <div className="runsTransaction-banner">
          <img
            src={backgroundImage}
            className="runstransaction_bg"
            alt="backgroundImage"
            style={{}}
          />

          <div className="runsTransaction-header">
            <a href="/">
              <img className="back-button" src={backButton} />
            </a>
            <p className="runsTransaction-title">Transactions</p>
          </div>

          {/* <div className="mobile_runstransaction_icon">
            <div className="mobile_rt_data_download">
              <div>
                <img className="mobile_rt_pdf_icon" src={pdfIcon} />
              </div>
              <div>
                <img className="mobile_rt_excel_icon" src={excelIcon} />
              </div>
            </div>
          </div> */}

          <div className="totalRunsCount">
            <img className="ball_img" src={redBall} />
            <p className="totalRunstext">{userRuns}</p>
          </div>

          <p className="totalRuns">Total Runs</p>
        </div>

        {/* Filter Buttons */}
        <div className="runsTransaction-filter">
          <div className="runstransaction-filter-button">
            {dateArray.map((date, index) => (
              <button
                id="thisWeekButton"
                className={`runs_filter_button ${activeButton === date ? "active" : ""
                  }`}
                onClick={() => handleButtonClick(date)}
              >
                {date}
              </button>
            ))}
          </div>
        </div>

        <div className="mobile_search-sort">
          {sortType.map((item, index) => (
            <p
              className={`mobile-search-button ${item === sortItem ? "active" : ""}`}
              key={index}
              onClick={() => handleClick(item)}
            >
              {item}
            </p>
          ))}
        </div>

        <div className="runsTransaction-search">
          {/* <div className="search-box">
            <input className="search-input" type="text" placeholder="Search" />
            <img className="search-icon" src={searchIcon} />
          </div> */}

          <div className="search-filter" ref={filterRef}>
            <a
              className={`rt-filter-button ${isFilterButtonActive ? "active" : ""
                }`}
              onClick={filterDropdown}
            >
              <img src={filterButton} />
            </a>
            {isFilterOpen && (
              <DropdownFilter
                runsType={runsType}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                setIsFilterOpen={setIsFilterOpen}
                setIsFilterButtonActive={setIsFilterButtonActive}
              />
            )}
          </div>

          <div className="search-sort" ref={sortRef}>
            <a
              className={`rt-sort-button ${isSortButtonActive ? "active" : ""}`}
              onClick={sortDropdown}
            >
              <img src={sortButton} alt="sort" />
            </a>
            {isSortOpen && <DropdownSort sortType={sortType} sortItem={sortItem} setSortItem={setSortItem} setIsSortOpen={setIsSortOpen}
              setIsSortButtonActive={setIsSortButtonActive} />}
          </div>
        </div>

        <RunsTransactionInfo activeItem={activeItem} sortItem={sortItem} dateFilter={activeButton} runStartDate={startDate} runEndDate={endDate} />
      </div>
    </>
  );
};

export default RunsTransaction;
