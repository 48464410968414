import React from "react";
import "./index.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useState, useEffect } from "react";
import bgImage from "../../assets/images/rewards/carouselbg.png";

export default function RewardCarousel() {
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");

  const [carousel, setCarousel] = useState(null);

  const getCarousels = async () => {
    if (!accessToken) return;
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/carousel/getAll/ArenaCarousel/1/10",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        const sortedCarousel = data.sort((a, b) => a.priority - b.priority); // Sorted based on priority
        setCarousel(sortedCarousel);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    if (!accessToken) return;
    getCarousels();
  }, []);

  return (
    <div className="carouselcalss">
      <Carousel
        // dynamicHeight={true}
        autoPlay={true}
        infiniteLoop={true}
        interval={3500}
        showThumbs={false}
      >
        {carousel ? (
          carousel.map((data, index) => (
            <div
              onClick={() => {
                if (data.link) {
                  navigate(data.link);
                }
              }}
              key={index}
            >
              {(
                data.name.endsWith('.gif') || data.name.endsWith('.png') ? (
                  <img src={data.name} alt="Arena Carousel"/>
                ) : (
                  <video key={data.name} autoPlay loop muted style={{ width: '100%', height: '90%', objectFit: 'cover' }}>
                    <source src={data.name} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )
              )}
            </div>
          ))
        ) : (
          <div>
            <img alt="carousel" src={""} className="carouserImage" />
          </div>
        )}
      </Carousel>
    </div>
  );
}
