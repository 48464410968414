import React, { useState, useEffect } from "react";
import "./index.scss";
import champLogo from "../../assets/images/icons/storyIcon.png";
import axios from "axios";
import GuestLoginPopup from "../../components/preview/Popup";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import ViewStories from "../../components/stories-guest/ViewStories"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxWidth: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const Story = () => {

  const [stories, setStories] = useState([]);
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  const [videoIndex, setVideoIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState([]);
  const accessToken = getStorageItem("token")
  const userId=getStorageItem("id")
  const [suggestedProfile, setSuggestedProfile] = useState([])


  const getStoriesGuestAndUserDetails = () => {

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + "/story/getStoriesGuest",
      headers: {
      },
    };

    axios(options)
      .then(({ data }) => {
        setStories([...data]);
        setMedia([...data[0].stories.map((story) => story.media)]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };
  useEffect(() => {
    getStoriesGuestAndUserDetails();
  }, []);

  return (
    <>
      <div className="story-main-container" onClick={handleOpen}>
        <div className="stories-slider">


          {stories.map((story, index) => (
            <div
              key={story._id}
              className="following-stories"
            >
              <div className="following-profile-container">
                <img
                  className="following-profile"
                  src=
                   {story.user.profilePhoto || champLogo}
                  alt={story.user?.firstName || "User"}
                />
              </div>
              <p className="following-name">{`${story.user?.firstName} `}</p>
            </div>
          ))}
        </div>

        {/* <ViewStories
            openModal={openModal}
            setOpenModal={setOpenModal}
            videoIndex={videoIndex}
            storyData={stories}
          /> */}

        {/* {openModalView && (
          <ViewPersonalStory
            handleOpenStory={handleOpen}
            openModalView={openModalView}
            setOpenModalView={setOpenModalView}
            myStories={myStories}
            handleAfterStoryDelete={handleAfterStoryDelete}
          />
        )} */}
      </div>

      {Open && <GuestLoginPopup Open={Open} handleClose={handleClose} />}
    </>
  );
};

export default Story;
