import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from "react-toastify";
import "./recordVoice.scss";
import PopupModel from './model';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import { Button, Typography } from '@material-ui/core';

import recordImg from "../../assets/images/commentary/recordImg.png";
import audioAnimation from "../../assets/images/commentary/audioAnimation.gif";

import { getStorageItem } from "../../utils/sessionStorage";
import useImageUploader from "../../utils/useImageUploader";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";

const RecordVoice = () => {
  const accessToken = getStorageItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isRecordingPage, setIsRecordingPage] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
  const [mergedVideoUrl, setMergedVideoUrl] = useState("");
  const audioChunk = useRef([]);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isVideoSaved, setIsVideoSaved] = useState(false);
  const [isAudioMerging, setIsAudioMerging] = useState(false);
  const [isAudioMergedWithVideo, setIsAudioMergedWithVideo] = useState(false);
  const { uploadImage } = useImageUploader();

  const startRecording = async (e) => {
    if (videoRef.current) {
      videoRef.current.muted = true;
      videoRef.current.play();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);

        mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunk.current.push(e.data);
          }
        }

        mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(audioChunk.current, { type: "audio/wav" });
          const audioFile = new File([audioBlob], "audio.wav", { type: "audio/wav" });
          audioChunk.current = [];
          await saveFileToS3(audioFile);
        }
        mediaRecorderRef.current = mediaRecorder;
        mediaRecorder.start();
        setIsRecording(true);
        setIsAnimationPlaying(true);
      } catch (error) {
        console.error("Error starting recording:", error);
      }
    }
  };

  const playPauseRecording = () => {
    if (isPaused) {
      videoRef.current.play();
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'paused') {
        mediaRecorderRef.current.resume();
        setIsAnimationPlaying(true)
      }
    } else {
      videoRef.current.pause();
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.pause();
        setIsAnimationPlaying(false);
      }
    }
    setIsPaused(!isPaused);
  };

  const stopRecording = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording' || mediaRecorderRef.current.state === 'paused') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsAnimationPlaying(false)
      if (videoRef.current) videoRef.current.pause();
    }
  }

  const saveFileToS3 = async (audioFile) => {
    const formData = new FormData();
    formData.append('uploader', audioFile);

    try {
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/upload-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        const audioUrl = response.data[0].location;
        setAudioUrl(audioUrl);
        toast.success('Audio saved successfully!');
        setOpenPopup(true);
      } else {
        throw new Error("Failed to save to S3.");
      }
    } catch (error) {
      console.error("Error saving to S3:", error.response ? error.response.data : error.message);
      toast.error('Failed to save audio to S3.');
      throw error;
    }
  };

  const handlePreview = () => {
    setIsAudioMerging(true);
    mergeVoiceWithVideo();
  }

  const handleSaveVideo = () => {
    setIsVideoSaved(true);
    setOpenPopup(true);
  }

  const handleSendToAdmin = () => {
    setOpenPopup(false)
    saveMergedVideoByUser();
  }

  const handleShareToPitch = () => {
    PostToPitch();
  }

  const fetchAdminVideoById = async () => {
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/commentary/getAdminVideoBy/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setVideoUrl(data.url);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  const mergeVoiceWithVideo = async () => {
    if (!audioUrl || !videoUrl) {
      toast.error('Audio or video URL is missing');
      return;
    }

    try {
      setIsAudioMerging(true);
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/commentary/mergeAudioWithVideo`,
        { audioUrl, videoUrl },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      );
      const videoBlob = new Blob([response.data], { type: 'video/mp4' });
      const file = new File([videoBlob], 'merged-video.mp4', {
        type: 'video/mp4',
      });

      if (file) {
        const newUrl = await uploadImage(file, 0.8);
        setMergedVideoUrl(newUrl);
      } else {
        toast.error("No video output found in the response.");
        setIsAudioMerging(false);
        return;
      }

      if (response.status === 200) {
        setIsAudioMerging(false);
        setOpenPopup(false);
        setIsAudioMergedWithVideo(true);
        toast.success("Your voice was merged with the video successfully!");
      }
    } catch (error) {
      console.error('Error merging audio and video:', error);
      setIsAudioMerging(false);
      toast.error("Error in merging voice with video.");
    }
  };


  const saveMergedVideoByUser = async () => {
    const userId = getStorageItem('user_id');
    const userName = getStorageItem("full_name");
    if (!userId) return;
    const saveVideo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/commentary/createMerge",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        userId: userId,
        name: userName,
        videoUrl: mergedVideoUrl,
      },
    };
    axios(saveVideo)
      .then((data) => {
        toast.success("Video successfully sent to admin")
        navigate("/commentary")
      }).catch((error) => {
        console.log(error);
      })
  };

  const PostToPitch = () => {
    let submitPostOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/pitch/create",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        video: mergedVideoUrl,
      }
    };
    if (mergedVideoUrl) {
      axios(submitPostOptions)
        .then(({ data }) => {
          if (data) {
            dispatch(PathAction.handleCreatedObject({ payload: data }));
            navigate("/pitch")
            toast.success("Video successfully posted in pitch")
          }
          return data;
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log(error);
          }
        });
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    fetchAdminVideoById()
  }, [id])



  return (
    <div className='video-preview-main'>
      <div className='commentary-heading-container'>
        <ArrowBackIcon className='arrow-icon' onClick={() => { navigate(-1) }} />
        <Typography className='heading'>Talent Hunt</Typography>
      </div>
      {isAudioMergedWithVideo ? (
        <div className='video-container'>
          {mergedVideoUrl && (
            <video className='video-player' controls autoPlay key="merged-video" >
              <source src={mergedVideoUrl} type="video/mp4" key="mergedvid" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ) : (
        <div className='video-container'>
          {videoUrl && (
            <video className='video-player' ref={videoRef} controls onEnded={stopRecording} autoPlay={false} key="original-video">
              <source src={videoUrl} type="video/mp4" key="originalvid" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      )}
      <div className='record-container'>
        {isAnimationPlaying ? (
          <Typography className='record-text'>Recording Inprogress</Typography>
        ) : isAudioMergedWithVideo ? (
          <Typography className='record-text'>Recording Completed</Typography>
        ) : (
          <Typography className='record-text'>Record your Commentary</Typography>
        )}
        {!isRecordingPage && (
          <img className='record-img' src={recordImg} alt='' />
        )}
        {isAnimationPlaying && (
          <img src={audioAnimation} alt='' />
        )}
        {isRecordingPage ? (
          <div className='record-buttons'>
            {isRecording ? (
              isPaused ? (
                <PlayArrowIcon className='play-pause' onClick={playPauseRecording} />
              ) : (
                <PauseIcon className='play-pause' onClick={playPauseRecording} />
              )
            ) : isAudioMergedWithVideo ? (
              <div className='save-retake'>
                <Button className='retake-btn' onClick={() => setIsAudioMergedWithVideo(false)}>Retake</Button>
                <Button className='save-btn' onClick={handleSaveVideo}>Save</Button>
              </div>
            ) : (
              <Button onClick={startRecording}><MicIcon className='start-rec' /></Button>
            )}
            {isRecording && (
              <Button onClick={stopRecording}><StopIcon className='stop-rec' /></Button>
            )}
          </div>
        ) : (
          <Button className='record-btn' onClick={() => setIsRecordingPage(true)}>
            <MicIcon className='mic-icon' />
            <Typography>Record Now</Typography>
          </Button>
        )}
      </div>
      {openPopup && (
        <PopupModel
          isVideoSaved={isVideoSaved}
          isAudioMerging={isAudioMerging}
          handlePreview={handlePreview}
          handleSendToAdmin={handleSendToAdmin}
          handleShareToPitch={handleShareToPitch}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        />
      )}
    </div >
  )
}

export default RecordVoice;

