import { useEffect, useState } from "react";
import axios from "axios";
import { Grid } from "@mui/material";
import { getStorageItem } from "../../utils/sessionStorage";
import Followers from "../followers/followers";
import Events from "../../components/events";
import "./index.scss";
import { Token } from "aws-sdk";
import { Link, useNavigate } from "react-router-dom";
import GuestLoginPopup from "../../components/preview/Popup";
const Suggestions = () => {
  
  const navigate = useNavigate();
  const [Open, setOpen] = useState(false);
  const handleClose = (data) => setOpen(data);
  const handleOpen = () => setOpen(true);
  const accessToken = getStorageItem("token")
  const [suggestedProfile, setSuggestedProfile]= useState([])
  const getSuggestedGuestProfile = async () => {

    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/suggestedGuestProfile",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setSuggestedProfile(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getSuggestedGuestProfile();
  }, []);

  return (
    <>
    <div className="component suggestions">
      <Grid container spacing={1} sx={{ mt: 0 }}>
        <Grid onClick={handleOpen}>
          <Events />
        </Grid>
      </Grid>
      <div className="suggestions-panel" onClick={handleOpen}>
        <div className="suggest-heading">
          <p>Suggested profiles to follow</p>
        </div>
        <Grid container spacing={0} direction="column" item>
          {suggestedProfile.map((suggestProfile, index)=>(
          <Grid item xs={12} sm={12} lg={6} key={index} gap={20}>
          <Followers
            key={suggestProfile._id}
            following={suggestProfile}
            //followings={followings}
          />
        </Grid>
        ))}
        <Grid item xs={12} sm={12} lg={6}>
            <div className="view-more" onClick={handleOpen}>
              <p  className=" view-more-text primary">
                View more
              
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
    {Open && <GuestLoginPopup Open={Open} handleClose={handleClose}/>}
    </>
  );
};

export default Suggestions;
