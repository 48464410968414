import { Grid, Button, CardMedia } from "@mui/material";
import React, { useState, useRef } from "react";
import "./index.css";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageBackground from "../../assets/images/Image-Background.png";
import DynamicField from "../form-creation/form-component";

const DealCreationForm = () => {
  const accessToken = getStorageItem("token");
  const [banner, setBanner] = useState("/banner.png");
  const [status, setStatus] = useState(true);
  const [runsBack, setRunsBack] = useState();
  const [showRunDealAmount, setShowRunDealAmount] = useState(false);
  const [runDealAmount, setRunDealAmount] = useState(0);
  const [conditionForUnlock, setConditionForUnlock] = useState({
    post: "",
    comment: "",
    highlight: "",
  });
  const [showDealFields, setShowDealFields] = useState(false);
  const [runcondition, setRuncondition] = useState({
    lowerCount: "",
    upperCount: "",
  });
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBG, setImageBG] = useState(null);

  const onImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBG(reader.result);
    };
    reader.readAsDataURL(file);
    setImage(file);
  };

  const formFields = [
    { label: "Title", name: "name", type: "text", placeholder: "Title Name" },
    {
      label: "Runs Required",
      name: "redeemrun",
      type: "text",
      placeholder: "0",
    },
    {
      label: "Email Id",
      name: "partnerEmailId",
      type: "email",
      placeholder: "Mail ID",
    },
    { label: "Deal Count", name: "count", type: "text", placeholder: "0" },
    {
      label: "Category",
      name: "categories",
      type: "select",
      options: [
        { label: "select category", value: "select category" },
        { label: "All", value: "All" },
        { label: "E-Commerce", value: "E-Commerce" },
        { label: "Food and Beverages", value: "Food and Beverages" },
        { label: "Exclusive", value: "Exclusive" },
        { label: "TimeLimit", value: "TimeLimit" },
        { label: "Offers", value: "Offers" },
      ],
    },
    {
      label: "App Only",
      name: "appOnly",
      type: "select",
      options: [
        { label: "select", value: "select" },
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Redeem Once",
      name: "redeemableOnce",
      type: "select",
      options: [
        { label: "select", value: "select" },
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Lock Deal",
      name: "isLocked",
      type: "select",
      options: [
        { label: "select", value: "select" },
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Discount Percentage",
      name: "discount",
      type: "text",
      placeholder: "0",
    },
    {
      label: "Run Deal",
      name: "active",
      type: "select",
      options: [
        { label: "select", value: "select" },
        { label: "True", value: "true" },
        { label: "False", value: "false" },
      ],
    },
    {
      label: "Start Date & Time",
      name: "startDateAndTime",
      type: "datetime-local",
    },
    {
      label: "End Date & Time",
      name: "endDateAndTime",
      type: "datetime-local",
    },
    {
      label: "Discount Start Date & Time",
      name: "discountStartDateAndTime",
      type: "datetime-local",
    },
    {
      label: "Discount End Date & Time",
      name: "discountEndDateAndTime",
      type: "datetime-local",
    },
    {
      label: "Targeted Users",
      name: "targetUser",
      type: "select",
      options: [
        { label: "select", value: "select" },
        { label: "Users", value: "user" },
        { label: "Subscribers", value: "subscriber" },
      ],
    },
    {
      label: "Priority No",
      name: "priorityNo",
      type: "text",
      placeholder: "0",
    },
    { label: "Code", name: "encrptedcode", type: "text", placeholder: "Code" },
  ];

  const [formData, setFormData] = useState(
    formFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {
      uploader: "",
      desc: "",
      runDealAmount: 0,
    })
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "isLocked") {
      setShowDealFields(value === "true");
    }
    if (name === "upperCount" || name === "lowerCount") {
      setRuncondition({ ...runcondition, [name]: parseInt(value, 10) });
      return;
    }
    if (name === "active") {
      setShowRunDealAmount(value === "true");
    }
    if (name === "runDealAmount") {
      setRunDealAmount(parseFloat(value));
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formStyling = {
    formStyling: "form-styling",
  };

  const dealhandleChange = (event) => {
    const { name, value } = event.target;
    setConditionForUnlock({
      ...conditionForUnlock,
      [name]: parseInt(value, 10),
    });
  };

  const handleFormSubmit = async () => {
    setSubmitBtnDisable(true);
    new Compressor(image, {
      quality: 0.8,
      success: (compressedResult) => {
        if (compressedResult.size > 5000000) {
          setImage(compressedResult);
          setStatus(true);
        } else {
          setImage(compressedResult.name);
          setStatus(false);
        }
      },
    });
    const dynamicFormData = new FormData();
    if (formData && Object.keys(formData).length > 0) {
      for (const key in formData) {
        if (key === "uploader") {
          dynamicFormData.append("uploader", image);
        } else if (formData.hasOwnProperty(key)) {
          dynamicFormData.append(key, formData[key]);
        }
      }
    }

    // Explicitly add runDealAmount to the form data
    if (showRunDealAmount) {
      dynamicFormData.append("runDealAmount", runDealAmount.toString());
    }

    if (
      conditionForUnlock.post !== "" ||
      conditionForUnlock.comment !== "" ||
      conditionForUnlock.highlight !== ""
    ) {
      dynamicFormData.append(
        "conditionForUnlock",
        JSON.stringify(conditionForUnlock)
      );
    }
    if (runcondition.lowerCount !== "" || runcondition.upperCount !== "") {
      dynamicFormData.append("runcondition", JSON.stringify(runcondition));
    }
    const createNewEvent = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/deal/create`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: dynamicFormData,
    };
    if (formData) {
      try {
        const response = await axios(createNewEvent);
        if (response?.status === 200 && response) {
          toast.success("New Event created successfully");
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error("Error: " + (error.message || error));
        } else {
          toast.error("Error: " + (error.message || error));
        }
      }
    }
    setFormData(
      formFields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {
        uploader: "",
        desc: "",
      })
    );
    setImage(null);
    setImageBG(null);
    setShowDealFields(false);
    setRuncondition({ lowerCount: "", upperCount: "" });
    setConditionForUnlock({ post: "", comment: "", highlight: "" });
    setRunDealAmount(0);
    setShowRunDealAmount(false);
    setSubmitBtnDisable(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <CardMedia
          component="img"
          height="300"
          image={imageBG === null ? ImageBackground : imageBG}
          alt="green iguana"
          sx={{ borderRadius: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          component="label"
          sx={{ position: "relative", top: "-8vh", left: "75vh", width: "14%" }}
        >
          <input
            type="file"
            accept="image/*"
            name="image"
            style={{ display: "none" }}
            onChange={onImageChange}
          />
          Add Images
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {formFields.map((field, index) => (
            <>
              {index % 2 === 0 && (
                <DynamicField
                  sx={{ display: "flex", flexDirection: "column" }}
                  value={formData[field.name]}
                  field={field}
                  handleChange={handleChange}
                  styles={formStyling}
                />
              )}
              {index === 6 && showDealFields && (
                <div className="form-styling">
                  <label>Comment</label>
                  <input
                    type="number"
                    name="comment"
                    onChange={dealhandleChange}
                    placeholder="comment"
                  />
                </div>
              )}
            </>
          ))}
          <div className="form-styling">
            <label>RunCondition lowerCount</label>
            <input
              type="text"
              name="lowerCount"
              value={runcondition.lowerCount}
              onChange={handleChange}
              placeholder="0"
            />
          </div>
          {showRunDealAmount && (
            <div className="form-styling">
              <label>Run Deal Amount</label>
              <input
                type="number"
                name="runDealAmount"
                value={runDealAmount}
                onChange={handleChange}
                placeholder="0"
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {formFields.map((field, index) => (
            <>
              {index % 2 !== 0 && (
                <DynamicField
                  sx={{ display: "flex", flexDirection: "column" }}
                  value={formData[field.name]}
                  field={field}
                  handleChange={handleChange}
                  styles={formStyling}
                />
              )}
              {index === 7 && showDealFields && (
                <>
                  <div className="form-styling">
                    <label>Post</label>
                    <input
                      type="number"
                      name="post"
                      onChange={dealhandleChange}
                      placeholder="post"
                    />
                  </div>
                  <div className="form-styling">
                    <label>Highlight</label>
                    <input
                      type="number"
                      name="highlight"
                      onChange={dealhandleChange}
                      placeholder="highlight"
                    />
                  </div>
                </>
              )}
            </>
          ))}
          <div className="form-styling">
            <label>RunCondition upperCount</label>
            <input
              type="text"
              name="upperCount"
              value={runcondition.upperCount}
              onChange={handleChange}
            />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <div className="form-styling">
          <label>Description</label>
          <textarea
            name="desc"
            value={formData["desc"]}
            onChange={handleChange}
            style={{
              borderRadius: "8px",
              backgroundColor: "#F3F5F5",
              color: "#697D95",
              border: "1px solid #BAC7D5",
              fontSize: "14px",
              padding: "12px 13px",
            }}
            placeholder="Enter a Description"
          ></textarea>
        </div>
      </Grid>
      <div>
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: "relative",
            top: "4vh",
            left: "50vh",
            width: "75vh",
            padding: "10px 4px",
            fontSize: "17px",
            marginBottom: "40px",
          }}
          onClick={handleFormSubmit}
          disabled={submitBtnDisable}
        >
          Submit
        </Button>
      </div>
    </Grid>
  );
};

export default DealCreationForm;
