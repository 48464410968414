import React, { useState, useEffect } from "react";
import "./MobileChat.scss";
import FriendItem from "./FriendItem";
import axios from "axios";
import { getStorageItem } from "../../../../utils/sessionStorage";
import InviteUsers from "../InviteUsers";
import InviteModal from "../InviteModal";
import GroupItem from "./GroupItem";
import NewChatModal from "../NewChatModal";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "lucide-react";
import Drawer from "../../../side-drawer/Drawer";
import RequestedInvites from "../RequestedInvites";
const MobileChat = ({ onChatSelect }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const accessToken = getStorageItem("token");
  const [friends, setFriends] = useState([]);
  const navigate = useNavigate();
  const [invites, setInvites] = useState([]);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState("");
  const userId = getStorageItem("user_id");

  const [activeTab, setActiveTab] = useState("friends");

  const [openGetInvite, setGetInvite] = useState(false);

  const handleOpenGetInviteModal = () => setGetInvite(true);
  const handleCloseGetInviteModal = () => setGetInvite(false);

  const [openInvite, setOpenInvite] = useState(false);

  const handleOpenInviteModal = () => setOpenInvite(true);
  const handleCloseInviteModal = () => setOpenInvite(false);

  const [openFriendModal, setOpenFriendModal] = useState(false);

  const handleOpenFriendModal = () => setOpenFriendModal(true);
  const handleCloseFriendModal = () => setOpenFriendModal(false);

  const [openRequestModal, setOpenRequestModal] = useState(false);

  const handleOpenRequestModal = () => setOpenRequestModal(true);
  const handleCloseRequestModal = () => setOpenRequestModal(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const getFriends = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/groupchat/getAllConversations/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        const friends = data.map((conversation) => ({
          conversationId: conversation._id,
          receiver: conversation.receiver,
          sender: conversation.senderId,
          status: conversation.status,
        }));

        setFriends(friends.filter((friend) => friend.status === "active"));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching friends:", error);
        }
      });
  };

  const getGroups = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllGroup`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const getInvites = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getinvite/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setInvites(data);
        handleOpenGetInviteModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching invites:", error);
        }
      });
  };

  const handleFriendClick = (friend) => {
    const receiverId =
      friend.receiver._id === userId ? friend.sender._id : friend.receiver._id;
    const senderId =
      friend.receiver._id !== userId ? friend.sender._id : friend.receiver._id;
    const receiverDets =
      friend.receiver._id === userId ? friend.sender : friend.receiver;
    onChatSelect({
      type: "friend",
      id: friend.conversationId,
      receiverId: receiverId,
      senderId: senderId,
      receiverDets,
    });
  };

  const handleGroupClick = (group) => {
    onChatSelect({
      type: "group",
      id: group._id,
      name: group.name,

      profilePhoto: group.groupProfile,
    });
  };

  useEffect(() => {
    getGroups();
    // getInvites();
    getFriends();
    // fetchUserInfo();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="mobile-chat">
      <header className="header">
        <button className="menu-button">
          <button
            className="menu-button"
            onClick={() => {
              navigate("/pitch");
            }}
          >
            <ChevronLeft />
          </button>
        </button>
        <h1>Dugout</h1>
      </header>

      {isDrawerOpen && <Drawer />}

      <nav className="tabs">
        {/* <button
          className={`tab ${activeTab === "friends" ? "active" : ""}`}
          onClick={() => handleTabClick("friends")}
        >
          Friends
        </button> */}
        <button
          className={`tab ${activeTab === "groups" ? "active" : ""}`}
          onClick={() => handleTabClick("groups")}
        >
          Groups
        </button>
      </nav>

      <main className="chat-list">
        {activeTab === "friends" && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <div></div>
              <div
                style={{ color: "#0F6CBD", cursor: "pointer" }}
                onClick={() => setOpenRequestModal(true)}
              >
                Request
              </div>
            </div>

            {friends && friends.length > 0 ? (
              friends.map((friend) => (
                <FriendItem
                  key={friend.conversationId}
                  {...friend}
                  onClick={() => handleFriendClick(friend)}
                />
              ))
            ) : (
              <div>No friends found</div> // Fallback when there are no friends
            )}
          </>
        )}

        {activeTab === "groups" &&
          groups.map((group) => (
            <GroupItem
              key={group._id}
              {...group}
              onClick={() => handleGroupClick(group)}
            />
          ))}
      </main>

      {activeTab === "friends" && (
        <button className="add-button" onClick={handleOpenFriendModal}>
          +
        </button>
      )}

      <InviteUsers
        openInvite={openInvite}
        handleCloseInviteModal={handleCloseInviteModal}
        handleOpenInviteModal={handleOpenInviteModal}
        groupId={groupId}
      />

      <InviteModal
        open={openGetInvite}
        handleClose={handleCloseGetInviteModal}
        invites={invites}
        onGroupSelect={(groupId) => {
          setGroupId(groupId);
          handleCloseGetInviteModal();
        }}
      />
      <RequestedInvites
        open={openRequestModal}
        handleClose={handleCloseRequestModal}
      />

      <NewChatModal
        open={openFriendModal}
        handleClose={handleCloseFriendModal}
        getFriends={getFriends}
        // onFriendSelect={(friendId) => {
        //   setFriendId(friendId);
        //   handleCloseFriendModal();
        // }}
      />
    </div>
  );
};

export default MobileChat;
