import React, { useState, useEffect, useRef, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getStorageItem } from "../../../utils/sessionStorage";
import axios from "axios";
import Popover from "@mui/material/Popover";
import ChatSection from "./ChatSection";
import {
  PanelRightOpen,
  Link,
  Ellipsis,
  CircleX,
  Copy,
  Plus,
  Sticker,
  UserRound,
  UsersRound,
} from "lucide-react";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import groupimg from "../../../assets/images/Group.png";
import dragAndDrop from "../../../assets/images/dragAndDrop.png";
import io from "socket.io-client";
import { SocketContext } from "../../../context/SocketContext";
import DeleteGroupModal from "./DeleteGroupModal";
import ReportSuccessModal from "./ReportSuccessModal";
import InviteUsers from "./InviteUsers";
import sendbtn from "../../../assets/images/chatreactions/sendbtn.png";
import StickerModal from "./StickerModal";
import ParticipantModal from "./ParticipantModal";
import InviteModal from "./InviteModal";
import { debounce, first } from "lodash";
import AdsSection from "./AdsSection";
import FriendChatSection from "./DirectChatSection";
import "../index.scss";
import NewChatModal from "./NewChatModal";
import RequestedInvites from "./RequestedInvites";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "80vh",
  },
  headBG: {
    backgroundColor: "#e0e0e0",
  },
  borderRight500: {
    borderRight: "1px solid #e0e0e0",
  },
  messageArea: {
    height: "60vh",
    overflowY: "auto",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const NewGroupChat = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [groups, setGroups] = useState([]);
  const [friends, setFriends] = useState([]);
  const [groupId, setGroupId] = useState("");
  const accessToken = getStorageItem("token");
  const [join, setJoin] = useState(false);
  const userId = getStorageItem("user_id");
  const [groupMessage, setGroupMessage] = useState();
  const [groupName, setGroupName] = useState("");
  const [groupProfile, setGroupProfile] = useState("");
  const [message, setMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [groupImage, setGroupImage] = useState();
  const [fileData1, setFileData1] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [socket, setSocket] = useContext(SocketContext);
  const [socketConnected, setSocketConnected] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [invites, setInvites] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [friendId, setFriendId] = useState("");
  const [isMounted, setIsMounted] = useState(true);
  const [friendMessage, setFriendMessage] = useState([]);
  const userName = getStorageItem("user_name");
  const [conversation, setConversation] = useState("");
  const userProfilePic = getStorageItem("avatar");
  const [receiverId, setReceiverId] = useState("");
  const [receiverDets, setReceiverDets] = useState([]);

  const [activeFriends, setActiveFriends] = useState([]);

  const [groupForm, setGroupForm] = useState({
    name: "",
    description: "",
    image: "",
  });

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const [anchorElGroupOption, setAnchorElGroupOption] = React.useState(null);
  const [openLeaveModal, setOpenLeaveModal] = React.useState(false);

  const openGroupOption = Boolean(anchorElGroupOption);
  const idGroupOption = openGroupOption ? "simple-popover" : undefined;

  const getFriends = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/groupchat/getAllConversations/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        const friends = data.map((conversation) => ({
          conversationId: conversation._id,
          receiver: conversation.receiver,
          sender: conversation.senderId,
          status: conversation.status,
        }));

        setFriends(friends);
        setActiveFriends(
          friends.filter((friend) => friend.status === "active")
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching friends:", error);
        }
      });
  };

  const fetchUserInfo = () => {
    if (!userId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        if (
          response.data.role !== "SuperAdmin" &&
          response.data.role !== "Admin"
        ) {
          setIsAdmin(false);
        } else {
          setIsAdmin(true);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };

  const getGroups = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getAllGroup`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setGroups(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const emitTyping = debounce(() => {
    if (groupId) {
      socket.emit("typing", {
        groupId,
        userId,
        user: { profilePic: userProfilePic },
      });
    } else if (friendId) {
      socket.emit("typing", {
        conversationId: friendId,
        userId,
        user: { profilePic: userProfilePic },
      });
    }
  }, 300);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (!isTyping) {
      setIsTyping(true);
      emitTyping();
    }
  };

  useEffect(() => {
    const stopTypingTimer = setTimeout(() => {
      if (isTyping) {
        setIsTyping(false);
        if (groupId) {
          socket.emit("stop typing", { groupId, userId });
        } else if (friendId) {
          socket.emit("stop typing", { conversationId: friendId, userId });
        }
      }
    }, 3000);

    return () => clearTimeout(stopTypingTimer);
  }, [message, isTyping]);

  const deleteGroup = () => {
    if (!accessToken) return;

    const options = {
      method: "delete",
      url: global.config.ROOTURL.prod + `/groupchat/delete/${groupId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setGroupId("");
        getGroups();
        handleCloseDeleteModal();
        handleOpenSuccessModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching :", error);
        }
      });
  };
  const getGroupMessage = async () => {
    try {
      if (!accessToken) return;
      const options = {
        method: "get",
        url: global.config.ROOTURL.prod + `/groupchat/getMessages/${groupId}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(options)
        .then(({ data }) => {
          setGroupMessage(data);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {}
  };

  const leaveGroupChatHandler = async () => {
    try {
      if (!accessToken) return;

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/leavechatGroup`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          groupId: groupId,
          userId: userId,
        },
      };

      axios(options)
        .then(({ data }) => {
          setJoin(false);
          checkUserIsJoined();
          setOpenLeaveModal(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {}
  };

  const checkUserIsJoined = async () => {
    try {
      if (!accessToken) return;

      const options = {
        method: "get",
        url:
          global.config.ROOTURL.prod +
          `/groupchat/checkUserJoinedGroup/${groupId}/${userId}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      axios(options)
        .then(({ data }) => {
          if (data === "User already joined group") {
            setJoin(true);
          } else {
            setJoin(false);
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {}
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file, file.name);

    const uploadResponse = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (uploadResponse.data && uploadResponse.data.length > 0) {
      return uploadResponse.data[0].location;
    } else {
      throw new Error("No data returned from upload.");
    }
  };

  const sendMessage = async () => {
    if (!accessToken) return;
    setLoading(true);

    try {
      let messageType = "text";
      let content = message;

      if (file) {
        const fileUrl = await uploadFileToS3(file);
        if (file.type.startsWith("image")) {
          messageType = "image";
        } else if (file.type.startsWith("video")) {
          messageType = "video";
        }
        content = fileUrl;
      }

      let endpoint = "";
      let data = {};

      if (groupId) {
        endpoint = "/groupchat/sendMessage";
        data = {
          groupId,
          senderId: userId,
          messageType,
          content,
        };
      } else if (friendId) {
        endpoint = "/groupchat/sendDirectMessage";
        data = {
          conversationId: conversation,
          senderId: userId,
          receiverId: receiverId,
          messageType,
          content,
        };
      }

      const { data: responseData } = await axios.post(
        global.config.ROOTURL.prod + endpoint,
        data,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (groupId) {
        socket.emit("new message", responseData);
      } else if (friendId) {
        socket.emit("new direct message", responseData);
      }

      setMessage("");
      setFile(null);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };
  const joinChatHandler = async () => {
    try {
      if (!accessToken) return;
      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/joinchatGroup`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          groupId: groupId,
          userId: userId,
        },
      };

      axios(options)
        .then(({ data }) => {
          setJoin(true);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log("Unauthorized:", error);
          } else {
            console.log("Error fetching stories:", error);
          }
        });
    } catch (error) {}
  };

  const getparticipnats = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getParticipants/${groupId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setParticipants(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const [openGroupModal, setOpenGroupModal] = React.useState(false);
  const handleOpenGroupModal = () => setOpenGroupModal(true);
  const handleCloseGroupModal = () => {
    setGroupForm({
      name: "",
      description: "",
      image: "",
    });
    setOpenGroupModal(false);
  };

  const [openParticipantModal, setOpenParticipantModal] = React.useState(false);
  const handleOpenParticipantModal = () => setOpenParticipantModal(true);
  const handleCloseParticipantModal = () => {
    setOpenParticipantModal(false);
  };

  const handleOpenLeaveModal = () => setOpenLeaveModal(true);
  const handleCloseLeaveModal = () => setOpenLeaveModal(false);

  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDeleteModal = () => setOpenDelete(true);
  const handleCloseDeleteModal = () => setOpenDelete(false);

  const [openRequestModal, setOpenRequestModal] = useState(false);

  const handleOpenRequestModal = () => setOpenRequestModal(true);
  const handleCloseRequestModal = () => setOpenRequestModal(false);

  const [openGetInvite, setGetInvite] = useState(false);

  const handleOpenGetInviteModal = () => setGetInvite(true);
  const handleCloseGetInviteModal = () => setGetInvite(false);

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleOpenSuccessModal = () => setOpenSuccess(true);
  const handleCloseSuccessModal = () => setOpenSuccess(false);

  const [openInvite, setOpenInvite] = useState(false);

  const handleOpenInviteModal = () => setOpenInvite(true);
  const handleCloseInviteModal = () => setOpenInvite(false);

  const [openStickerModal, setOpenStickerModal] = useState(false);

  const handleOpenStickerModal = () => setOpenStickerModal(true);
  const handleCloseStickerModal = () => setOpenStickerModal(false);

  const [openFriendModal, setOpenFriendModal] = useState(false);

  const handleOpenFriendModal = () => setOpenFriendModal(true);
  const handleCloseFriendModal = () => setOpenFriendModal(false);

  const handleClickGroupOption = (event) => {
    setAnchorElGroupOption(event.currentTarget);
  };

  const handleCloseGroupOption = () => {
    setAnchorElGroupOption(null);
  };

  const getInvites = () => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getinvite/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setInvites(data);
        handleOpenGetInviteModal();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching invites:", error);
        }
      });
  };

  const createGroup = async () => {
    const file = fileData1;
    let dataURL = groupImage;
    try {
      if (!accessToken) return;

      // Step 1: Upload file if there's a file selected
      if (file) {
        const formData = new FormData();
        formData.append("uploader", file, file.name);

        const uploadResponse = await axios.post(
          global.config.ROOTURL.prod + `/upload-file`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (uploadResponse.data && uploadResponse.data.length > 0) {
          dataURL = uploadResponse.data[0].location;
        } else {
          console.error("Error occurred: No data returned from upload.");
          return;
        }
      }

      const options = {
        method: "post",
        url: global.config.ROOTURL.prod + `/groupchat/create`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          name: groupForm.name,
          description: groupForm.description,
          groupProfile: dataURL,
        },
      };

      const createGroupResponse = await axios(options);

      // Step 3: Update state with new groups data
      if (createGroupResponse.data) {
        getGroups();
        // setGroups(createGroupResponse.data);
        setOpenGroupModal(false);
      }
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const fileURL = URL.createObjectURL(file);
    setFileData1(file);
    setGroupImage(fileURL);
  };
  const getMessageById = (msgId) => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getMessage/${msgId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        setGroupMessage((prevMessages) => [...prevMessages, data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  const getDirectMessageById = (msgId) => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/groupchat/getDirectMessageById/${msgId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        // setFr((prevMessages) => [...prevMessages, data]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching stories:", error);
        }
      });
  };

  useEffect(() => {
    if (groupId !== "") {
      getGroupMessage();
      checkUserIsJoined();
      getparticipnats();
    }
  }, [groupId]);

  useEffect(() => {
    if (groupId != "") {
      checkUserIsJoined();
    }
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      setSocketConnected(true);
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  const getFriendsMessage = async () => {
    if (!accessToken || !friendId) return;

    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/groupchat/getDirectMessages/${friendId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    try {
      const { data } = await axios(options);
      if (isMounted) {
        setFriendMessage(data);
      }
    } catch (error) {
      console.log("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    setIsMounted(true);

    getFriendsMessage();
    return () => {
      setIsMounted(false);
    };
  }, [friendId, accessToken]);

  useEffect(() => {
    socket.on("message received", (data) => {
      if (groupId && data.chat.groupId === groupId) {
        getMessageById(data.chat._id);
      } else if (friendId && data.chat.conversationId === friendId) {
        setFriendMessage((prevMessages) => [...prevMessages, data.chat]);
      }
    });

    socket.on("typing", (data) => {
      if (
        (groupId && data.groupId === groupId) ||
        (friendId && data.conversationId === friendId)
      ) {
        setIsTyping(true);
      }
    });

    socket.on("stop typing", (data) => {
      if (
        (groupId && data.groupId === groupId) ||
        (friendId && data.conversationId === friendId)
      ) {
        setIsTyping(false);
      }
    });

    return () => {
      socket.off("message received");
      socket.off("typing");
      socket.off("stop typing");
    };
  }, [socket, groupId, friendId]);

  useEffect(() => {
    if (socket && userId) {
      socket.emit("setup", userId);
    }
  }, [socket, userId]);

  useEffect(() => {
    if (socket && groupId) {
      socket.emit("join chat", groupId);
    }
  }, [socket, groupId]);
  useEffect(() => {
    if (socket && friendId) {
      socket.emit("join direct chat", friendId);
    }
  }, [socket, friendId]);
  useEffect(() => {
    getGroups();
    // getInvites();
    getFriends();
    fetchUserInfo();
  }, []);

  return (
    <div className="groupchatsmain">
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={3} className={classes.borderRight500}>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              if (currentTab === 0) {
                setFriendId("");
              } else if (currentTab === 1) {
                setGroupId("");
              }
              setCurrentTab(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              icon={
                <div className={classes.tabLabel}>
                  <UsersRound className={classes.tabIcon} />
                  {"GROUPS"}
                </div>
              }
            />
            {/* <Tab
              icon={
                <div className={classes.tabLabel}>
                  <UserRound className={classes.tabIcon} />
                  {"FRIENDS"}
                </div>
              }
            /> */}
          </Tabs>
          {currentTab == 0 && (
            <>
              <Divider />
              {groups.length > 0 ? (
                <>
                  {groups.map((group) => (
                    <List
                      key={group._id}
                      onClick={() => {
                        setGroupProfile(group.groupProfile);
                        setGroupName(group.name);
                        setGroupId(group._id);
                      }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <Avatar
                            alt="Group Profile"
                            src={group.groupProfile}
                          />
                        </ListItemIcon>
                        <div className="groupNames">
                          <ListItemText primary={group.name} />
                        </div>
                      </ListItem>
                    </List>
                  ))}
                </>
              ) : (
                <div>
                  <h3>No Groups</h3>
                </div>
              )}
              {/* AdsSection shown only when groups are 4 or fewer */}
              {groups.length < 4 && <AdsSection />}
              {isAdmin && (
                <List align="end">
                  <div
                    onClick={handleOpenGroupModal}
                    style={{
                      padding: "10px",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#0F6CBD",
                      margin: "10px",
                      color: "white",
                      cursor: "pointer",
                      gap: "10px",
                    }}
                  >
                    <div>
                      <Plus />
                    </div>
                    <p className="createtext"> Create Group</p>
                  </div>
                  <Modal
                    open={openGroupModal}
                    onClose={handleCloseGroupModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="style1">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: "#F37121",
                          }}
                        >
                          <h2 style={{ color: "#313335" }}>Group Name</h2>
                          <CircleX
                            style={{ cursor: "pointer" }}
                            onClick={handleCloseGroupModal}
                          />
                        </div>

                        <Divider
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        />
                        <div className="createGroup">
                          <div style={{ width: "600px" }}>
                            <label>Group Name</label>
                            <br />
                            <input
                              type="text"
                              placeholder="Enter Group Name"
                              value={groupForm.name}
                              onChange={(e) =>
                                setGroupForm({
                                  ...groupForm,
                                  name: e.target.value,
                                })
                              }
                              style={{
                                margin: "10px",
                                width: "350px",
                                padding: "10px",
                                border: "1px solid gray",
                              }}
                            />
                            <br />
                            <label>Description</label>
                            <br />
                            <textarea
                              placeholder="Enter Description"
                              value={groupForm.description}
                              onChange={(e) =>
                                setGroupForm({
                                  ...groupForm,
                                  description: e.target.value,
                                })
                              }
                              style={{
                                margin: "10px",
                                width: "350px",
                                padding: "10px",
                                border: "1px solid gray",
                              }}
                            />
                          </div>
                          <div>
                            <div
                              className="uploadImage"
                              style={{
                                backgroundColor: "#D9D9D9",
                                padding: "10px",
                                height: "190px",
                                cursor: "pointer",
                              }}
                            >
                              {groupImage ? (
                                <div>
                                  <img
                                    src={groupImage}
                                    width={400}
                                    height={190}
                                  />
                                </div>
                              ) : (
                                <div>
                                  <label
                                    htmlFor="profileImageInput"
                                    style={{
                                      display: "block",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <img
                                      src={dragAndDrop}
                                      alt="Drag and drop icon"
                                    />
                                    <p htmlFor="profileImageInput">
                                      Drag & drop profile <br /> image to upload
                                    </p>
                                  </label>
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    id="profileImageInput"
                                    style={{ display: "none" }}
                                  />
                                </div>
                              )}
                            </div>

                            <div
                              style={{
                                backgroundColor: "#F9F9F9",
                                padding: "13px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                }}
                              ></div>

                              <p
                                style={{
                                  color: "#62666A",
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                }}
                              >
                                Group name
                              </p>
                              <p>{groupForm.name}</p>
                            </div>
                          </div>
                        </div>

                        <Divider
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        />

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "25px",
                          }}
                        >
                          {isUploaded ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <CheckCircleIcon style={{ color: "#3AC309" }} />

                              <p style={{ fontSize: "12px", color: "#95999D" }}>
                                Almost done
                              </p>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                              }}
                            >
                              <CheckCircleOutlineIcon
                                color="#95999D"
                                style={{ color: "#95999D" }}
                              />

                              <p style={{ fontSize: "12px", color: "#95999D" }}>
                                Upload complete.......processing will begin
                                shortly
                              </p>
                            </div>
                          )}

                          <button
                            style={{
                              backgroundColor: "#0C5BA0",
                              cursor: "pointer",
                              color: "white",
                              border: "none",
                              padding: "10px 30px",
                              borderRadius: "10px",
                            }}
                            onClick={createGroup}
                          >
                            Add Group
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </List>
              )}
            </>
          )}
          {currentTab === 1 && (
            <>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <div></div>
                <div
                  style={{ color: "#0F6CBD" }}
                  onClick={() => {
                    setOpenRequestModal(true);
                  }}
                >
                  Request
                </div>
              </div>
              {activeFriends.length > 0 ? (
                <>
                  {activeFriends.map((friend) => (
                    <List
                      key={friend.id}
                      onClick={() => {
                        setFriendId(friend.conversationId);
                        setConversation(friend.conversationId);
                        if (friend.receiver._id !== userId) {
                          setReceiverId(friend.receiver?._id);
                          setReceiverDets(friend?.receiver);
                        } else {
                          setReceiverId(friend.sender._id);
                          setReceiverDets(friend?.sender);
                        }
                        setGroupId("");
                      }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <Avatar
                            alt={
                              friend?.receiver?._id !== userId
                                ? friend?.receiver?.firstName
                                : friend?.sender?.firstName
                            }
                            src={
                              friend?.receiver?._id !== userId
                                ? friend?.receiver?.profilePhoto
                                : friend?.sender?.profilePhoto
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            friend?.receiver?._id !== userId
                              ? friend?.receiver?.firstName
                              : friend?.sender?.firstName
                          }
                        />
                      </ListItem>
                    </List>
                  ))}
                </>
              ) : (
                <div>
                  <h3>No Friends</h3>
                </div>
              )}
              <List align="end">
                <div
                  onClick={handleOpenFriendModal}
                  style={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "#0F6CBD",
                    margin: "10px",
                    color: "white",
                    cursor: "pointer",
                    gap: "10px",
                  }}
                >
                  <div>
                    <Plus />
                  </div>
                  <p className="createtext"> Add Friend</p>
                </div>
              </List>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={9}
          style={{ backgroundImage: `url(${groupimg})`, height: "67vh" }}
        >
          {groupId ? (
            <div>
              <div
                style={{
                  padding: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Avatar alt="Remy Sharp" src={groupProfile} />
                  <p>{groupName}</p>
                </div>

                <div style={{ cursor: "pointer" }}>
                  <Ellipsis
                    style={{ rotate: "90deg", cursor: "pointer" }}
                    size={20}
                    onClick={handleClickGroupOption}
                  />
                  <Popover
                    id={idGroupOption}
                    open={openGroupOption}
                    anchorEl={anchorElGroupOption}
                    onClose={handleCloseGroupOption}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div>
                      {!isAdmin ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              color: "#344054",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenLeaveModal}
                          >
                            Leave Chat
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenInviteModal}
                          >
                            Invite Friends
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenParticipantModal}
                          >
                            Participants
                          </div>
                        </>
                      ) : (
                        <div style={{ padding: "10px", style: "#344054" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenDeleteModal}
                          >
                            Delete Group
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenParticipantModal}
                          >
                            Participants
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                            onClick={handleOpenInviteModal}
                          >
                            Invite Friends
                          </div>

                          <div
                            style={{
                              display: "flex",
                              gap: "10px",
                              padding: "5px",
                              paddingLeft: "13px",
                              paddingRight: "13px",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              href="/groupchat/reportedUsers"
                              style={{
                                textDecoration: "none",
                                color: "#344054",
                              }}
                            >
                              Reported Users
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </Popover>
                  <Modal
                    open={openLeaveModal}
                    onClose={handleCloseLeaveModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div>
                        <h2 style={{ color: "#313335" }}>Are you sure</h2>
                        <p style={{ marginTop: "5px", color: "#252A31" }}>
                          You want to leave chats
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "25px",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              cursor: "pointer",
                              padding: "10px 20px",
                            }}
                            onClick={handleCloseLeaveModal}
                          >
                            Back
                          </button>
                          <button
                            style={{
                              backgroundColor: "#0C5BA0",
                              cursor: "pointer",
                              color: "white",
                              border: "none",
                              padding: "10px 30px",
                            }}
                            onClick={leaveGroupChatHandler}
                          >
                            Leave
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
              <Divider />
              <List className={classes.messageArea}>
                <ChatSection
                  groupMessage={groupMessage}
                  userId={userId}
                  socket={socket}
                  groupId={groupId}
                />
              </List>
              <Divider />
              {join ? (
                <div
                  style={{
                    display: "flex",
                    padding: "14px",
                    gap: "20px",
                    // margin: "5px",
                    alignItems: "center",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <div>
                    <Link
                      onClick={() => fileInputRef.current.click()}
                      style={{ cursor: "pointer" }}
                    />
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      accept="image/*,video/*"
                    />
                  </div>
                  <TextField
                    id="outlined-basic-email"
                    label="Type Something"
                    fullWidth
                    value={message}
                    onChange={handleMessageChange}
                  />
                  {/* <img src={gifbtn} style={{ cursor: "pointer" }} />  */}
                  <Sticker
                    style={{ cursor: "pointer", width: "40px" }}
                    onClick={handleOpenStickerModal}
                  />

                  {loading ? (
                    <div>Sending...</div>
                  ) : (
                    <div>
                      <img src={sendbtn} onClick={sendMessage} width={37} />
                    </div>
                  )}
                  {file && <div>File selected: {file.name}</div>}
                </div>
              ) : (
                <Grid
                  container
                  style={{
                    padding: "20px",
                    backgroundColor: "#2C9207",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={joinChatHandler}
                >
                  Tap to join the CHAT
                </Grid>
              )}
            </div>
          ) : friendId ? (
            <div>
              <div
                style={{
                  padding: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Avatar alt="Remy Sharp" src={receiverDets.profilePhoto} />
                  <p>{receiverDets.firstName + " " + receiverDets.lastName}</p>
                </div>

                {/* Right Side PopUp */}

                <div style={{ cursor: "pointer" }}>
                  <Ellipsis
                    style={{ rotate: "90deg", cursor: "pointer" }}
                    size={20}
                    onClick={handleClickGroupOption}
                  />
                  {/* <Popover
                    id={idGroupOption}
                    open={openGroupOption}
                    anchorEl={anchorElGroupOption}
                    onClose={handleCloseGroupOption}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div></div>
                  </Popover> */}
                  <Modal
                    open={openLeaveModal}
                    onClose={handleCloseLeaveModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div>
                        <h2 style={{ color: "#313335" }}>Are you sure</h2>
                        <p style={{ marginTop: "5px", color: "#252A31" }}>
                          You want to leave chats
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "25px",
                          }}
                        >
                          <button
                            style={{
                              border: "none",
                              cursor: "pointer",
                              padding: "10px 20px",
                            }}
                            onClick={handleCloseLeaveModal}
                          >
                            Back
                          </button>
                          <button
                            style={{
                              backgroundColor: "#0C5BA0",
                              cursor: "pointer",
                              color: "white",
                              border: "none",
                              padding: "10px 30px",
                            }}
                            onClick={leaveGroupChatHandler}
                          >
                            Leave
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
              </div>
              <Divider />
              <List className={classes.messageArea}>
                <FriendChatSection
                  friendMessages={friendMessage}
                  userId={userId}
                  socket={socket}
                  receiverDets={receiverDets}
                  // hello
                  friendId={friendId}
                  // loadMoreMessages={handleLoadMoreMessages}
                />
              </List>
              <Divider />
              <div
                style={{
                  display: "flex",
                  padding: "14px",
                  gap: "20px",
                  // margin: "5px",
                  alignItems: "center",
                  backgroundColor: "#F8F8F8",
                }}
              >
                <div>
                  <Link
                    onClick={() => fileInputRef.current.click()}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    accept="image/*,video/*"
                  />
                </div>
                <TextField
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                  value={message}
                  onChange={handleMessageChange}
                />
                <Sticker
                  style={{ cursor: "pointer", width: "40px" }}
                  onClick={handleOpenStickerModal}
                />

                {loading ? (
                  <div>Sending...</div>
                ) : (
                  <div>
                    <img src={sendbtn} onClick={sendMessage} width={40} />
                  </div>
                )}
                {file && <div>File selected: {file.name}</div>}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
              }}
            >
              <h3>Please select a group/friend</h3>
              <p style={{ marginTop: "10px", color: "#666" }}>
                Choose a group/friend from the list on the left to start
                chatting
              </p>
            </div>
          )}
        </Grid>
      </Grid>
      <DeleteGroupModal
        deleteGroup={deleteGroup}
        openDelete={openDelete}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <ReportSuccessModal
        messagetype="delete"
        openSuccess={openSuccess}
        handleOpenSuccessModal={handleOpenSuccessModal}
        handleCloseSuccessModal={handleCloseSuccessModal}
      />
      <InviteUsers
        openInvite={openInvite}
        handleCloseInviteModal={handleCloseInviteModal}
        handleOpenInviteModal={handleOpenInviteModal}
        groupId={groupId}
      />
      <StickerModal
        openStickerModal={openStickerModal}
        handleCloseStickerModal={handleCloseStickerModal}
        groupId={groupId}
        friendId={friendId}
        receiverId={receiverId}
        setGroupMessage={setGroupMessage}
        setFriendMessage={setFriendMessage}
      />
      <ParticipantModal
        openParticipantModal={openParticipantModal}
        handleCloseParticipantModal={handleCloseParticipantModal}
        participants={participants}
      />
      <InviteModal
        open={openGetInvite}
        handleClose={handleCloseGetInviteModal}
        invites={invites}
        onGroupSelect={(groupId) => {
          setGroupId(groupId);
          handleCloseGetInviteModal();
        }}
      />
      <RequestedInvites
        open={openRequestModal}
        handleClose={handleCloseRequestModal}
      />
      <NewChatModal
        open={openFriendModal}
        handleClose={handleCloseFriendModal}
        getFriends={getFriends}
        // onFriendSelect={(friendId) => {
        //   setFriendId(friendId);
        //   handleCloseFriendModal();
        // }}
      />
    </div>
  );
};

export default NewGroupChat;
