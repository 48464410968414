import React from 'react'
import './model.scss'

import Box from '@mui/material/Box';
import { Button } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import recCompleted from "../../assets/images/commentary/recCompleted.gif";
import generating from "../../assets/images/commentary/Generating.gif";
import readyToPost from "../../assets/images/commentary/readyToGo.gif";

const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "36%",
    height: 400,
    bgcolor: '#FFFFFF',
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,

    '@media (max-width: 768px)': {
        width: '90% !important',
    }
}



const PopupModel = ({ isVideoSaved, isAudioMerging, openPopup, setOpenPopup, handlePreview, handleSendToAdmin, handleShareToPitch }) => {
    return (
        <div>
            <Modal
                open={openPopup}
                onClose={() => setOpenPopup(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    {isAudioMerging ? (<img src={generating} alt='' />)
                        : isVideoSaved ? (
                            <img src={readyToPost} alt='' />
                        )
                            : (<img src={recCompleted} alt='' />)}
                    <h4 className='main-heading'>
                        {isAudioMerging ? (<p style={{ color: "#F37121" }}>Generating</p>) :
                            isVideoSaved ? (
                                <p style={{ color: "#2C9207" }}>Yipeeee...... Ready To Go</p>
                            ) : (
                                "Recording Completed"
                            )}
                    </h4>
                    <Typography
                        style={{
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "italic",
                            fontWeight: 600,
                            lineHeight: "24px",
                            textAlign: "center",
                            color: "#62666A"
                        }}
                    >
                        {isAudioMerging ? "Wait a moment while we add the magic..."
                            : isVideoSaved ?
                                "Your commentary for the next step"
                                : "Your recording has been successfully completed."
                        }
                    </Typography>
                    {!isAudioMerging && !isVideoSaved ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                style={{
                                    height: "40px",
                                    width: "35%",
                                    border: "1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)",
                                    textTransform: "none",
                                    color: "#424242",
                                }}
                                onClick={() => setOpenPopup(false)}
                            >Retake</Button>
                            <Button
                                style={{
                                    height: "40px",
                                    width: "35%",
                                    background: "var(--Brand-Background-1-Rest, #0F6CBD)",
                                    textTransform: "none",
                                    color: "#FFFFFF",
                                }}
                                onClick={handlePreview}
                            >Preview</Button>
                        </Box>
                    ) : isVideoSaved ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Button
                                style={{
                                    height: "40px",
                                    width: "40%",
                                    border: "1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)",
                                    textTransform: "none",
                                    color: "#424242",
                                    '@media (max-width: 768px)': {
                                        width: "43%",
                                    },
                                }}
                                onClick={handleSendToAdmin}
                            >Send to admin</Button>
                            <Button
                                style={{
                                    height: "40px",
                                    width: "40%",
                                    background: "var(--Brand-Background-1-Rest, #0F6CBD)",
                                    textTransform: "none",
                                    color: "#FFFFFF",
                                    '@media (max-width: 768px)': {
                                        width: "43%",
                                    },
                                }}
                                onClick={handleShareToPitch}
                            >Post in pitch</Button>
                        </Box>
                    ) : null}
                </Box>
            </Modal>
        </div>
    )
}

export default PopupModel;
