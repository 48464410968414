import React, { useEffect, useState } from "react";
import "./index.scss";
import champLogo from "../../assets/images/subscription/champLogo.png";
import vector from "../../assets/images/subscription/Vector.png";
import bgImage from "../../assets/images/subscription/bgImg.png";
import { Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import share from "../../assets/images/Share.png";
import axios from "axios"; // Assuming axios is imported
import { getStorageItem } from "../../utils/sessionStorage";
import UpdateMobileNumber from "./UpdateMobileNumber";
import CheckoutModal from "./checkoutModal";
import { set } from "firebase/database";

const SubsCription = () => {
  const [activePlan, setActivePlan] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discountedAmount, setDiscountedAmount] = useState(null);
  const mobileView = useMediaQuery("(max-width:769px)");
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const [plans, setPlans] = useState([]);
  const [updateMobileNo, setUpdateMobileNo] = useState(false);
  const handleUpdateMobileNoModal = () => setUpdateMobileNo(!updateMobileNo);
  const handlePlanClick = (plan) => {
    setActivePlan(plan);
  };
  const [mobileNo, setMobileNo] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [btndisable, setBtnDisable] = useState(false);
  const getMobileNo = () => {
    const userUrl = global.config.ROOTURL.prod + `/auth/profile`;
    const config = {
      method: "POST",
      url: userUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: JSON.stringify({ userId: userId }),
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        setMobileNo(response?.data?.mobileNo);
      })
      .catch((error) => {
        console.log("Error making payment:", error);
      });
  };
  const getSubscriptionDetails = () => {
    const userUrl = global.config.ROOTURL.prod + `/pay/isSubscriber/${userId}`;
    const config = {
      method: "GET",
      url: userUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: JSON.stringify({ userId: userId }),
    };

    axios(config)
      .then((response) => {
        setSubscriptionDetails(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log("Error making payment:", error);
      });
  };

  const getColumnBackground = (plan) => {
    return activePlan === plan
      ? "linear-gradient(180deg, rgba(148, 188, 255, 0.0825) 0%, rgba(148, 188, 255, 0) 100%)"
      : "transparent";
  };

  const getHeadingColor = (plan) => {
    return activePlan === plan ? "#46ef09" : "#94bcff4d";
  };

  const makePayment = () => {
    if (!userId || !activePlan) return; // Check if userId or activePlan is not set
    if (mobileNo === null || mobileNo === undefined || !mobileNo) {
      setUpdateMobileNo(true);
    }
    const plan = activePlan; // Assuming activePlan is a string representing the plan name

    const amount = plans.find((p) => p.planName === plan)?.planAmount; // Get the amount for the selected plan

    if (!amount) {
      console.error("Plan amount not found for selected plan:", plan);
      return;
    }

    const paymentAmount = amount; // Converting to paise if your backend requires
    const mobileNumber = mobileNo;

    const paymentUrl =
      global.config.ROOTURL.prod +
      `/pay/paymentCall/${discountedAmount}/${userId}/${mobileNumber}/${activePlan}`;

    const config = {
      method: "GET",
      url: paymentUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        }
      })
      .catch((error) => {
        console.log("Error making payment:", error);
      });
  };

  const getPlans = () => {
    if (!userId) return; // Assuming userId is checked for validity
    const res = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/subscription/all`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(res)
      .then((data) => {
        setPlans(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCoupon = () => {
    if (!userId) return; // Assuming userId is checked for validity
    const res = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/couponRedeem/validateSubscriptionCoupon/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(res)
      .then((data) => {
        setCoupon(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getMobileNo();
    getCoupon();
    getPlans();
    getSubscriptionDetails();
  }, []);

  const getButtonText = () => {
    if (subscriptionDetails) {
      console.log(subscriptionDetails.expiresAt);
      const expiresAtDate = new Date(subscriptionDetails.expiresAt);
      const formattedDate = expiresAtDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      return (
        <span>
          Active plan:{subscriptionDetails.planName} <br></br> Expires on:{" "}
          {formattedDate}
        </span>
      );
    } else if (!activePlan) {
      return <span>Select a Plan &raquo;</span>;
    }
    return (
      <span>
        Continue with <strong>{activePlan}</strong> &raquo;
      </span>
    );
  };

  const openCheckoutModal = () => {
    if (!activePlan) return; // Ensure a plan is selected

    const planAmount = plans.find((p) => p.planName === activePlan)?.planAmount;
    if (!planAmount) {
      console.error("Plan amount not found for selected plan:", activePlan);
      return;
    }
    console.log(coupon);
    // Apply coupon discount logic if any
    const discount = coupon ? coupon.discount : 0;
    const discounted = planAmount - (planAmount * discount) / 100;
    setDiscountedAmount(discounted);

    setIsModalOpen(true);
  };

  const closeCheckoutModal = () => {
    setIsModalOpen(false);
  };

  const benefitsTag = [
    "All Deals processed within 48 hrs",
    "Special discount on all brand deals",
    "Early access to deals",
    "Exclusive deals for members only",
    "2x runs during Daily Happy Hours",
    "1.5x runs on all activities",
    "5x more runs in arena for all winning activities",
    "10% Runs back on all marketplace transactions",
    "10% Runs back on redeeming deals",
    "Post and apply for unlimited jobs",
    "Access to members only sessions",
    "Special deals from our partners",
    "Skip the queue for physical events",
    "Priority chat support",
    // "Exclusive cash deals",
    // "10% Runs back on all marketplace transactions",
    // "10% Runs back on deal redemptions",
    // "Double Runs during daily Happy Hours",
    // "One free 24-hour website promotion monthly",
    // "Unlimited Job Posting and Applications",
    // "5x Runs in the arena for all winning activities",
    // "Members-Only Coaching Sessions",
    // "Early Access to Exclusive Deals",
    // "Special deals from our partners",
    // "All deals processed within 48 hours",
    // "Skip the Queue for physical events",
    // "Priority Chat Support",
  ];

  return (
    <div className="sub-main-container">
      {mobileView && (
        <div className="mobileView-container">
          <div className="logo-and-text-mobile">
            <img className="champ-logo-mobile" src={champLogo} alt="logo" />
            <img className="champ-name-mobile" src={vector} alt="Champhunt" />
          </div>
          <h4 className="heading-text-logo-mobile">Unleash your real fun</h4>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="sub-left-container">
          <div className="logo-and-text">
            <img className="champ-logo" src={champLogo} alt="logo" />
            <img className="champ-name" src={vector} alt="Champhunt" />
          </div>
          <h4 className="heading-text-logo">Unleash your real fun</h4>
          <div
            className="bgImg-container"
            style={{ maxHeight: "235px", overflowY: "auto" }}
          >
            <img className="bgImage" src={bgImage} alt="backgroundImg" />
          </div>
        </div>
        <div>
          <h3 style={{ fontSize: "32px", color: "#ffffff" }}>PICK A PLAN</h3>
          <p style={{ color: "#707378", fontSize: "18px" }}>
            Join Now to Score Winning Deals!
          </p>
          <div
            style={{
              marginTop: "20px",
              maxHeight: "250px",
              overflowY: "auto",
              paddingRight: "15px",
            }}
            className="subscript-rule"
          >
            {benefitsTag.map((items, index) => (
              <div
                style={{ display: "flex", marginBottom: "13px" }}
                key={index}
              >
                <img src={share} width={34} height={34} alt="share icon" />
                <p
                  style={{
                    color: "#FFFFFF",
                    fontSize: "20px",
                    marginLeft: "10px",
                  }}
                >
                  {items}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="btn-containers">
        <div
          className="btn-inner-container"
          style={{ justifyContent: "center" }}
        >
          {plans.map((plan, index) => (
            <div
              className={`btn-plan ${
                activePlan === plan.planName ? "active" : ""
              }`}
              key={plan.planName}
              onClick={() => handlePlanClick(plan.planName)}
            >
              <p className="category-text">{plan.planName}</p>
              <div className="btn-inner-container2">
                <p className="plan-text">
                  &#8377;{plan.planAmount}
                  <small>/ {plan.planDuration} Days</small> &nbsp;
                  +<small> {plan.extraDays} Days Extra</small>
                </p>
              </div>
            </div>
          ))}
        </div>
        <Button
          className="btn-continue"
          onClick={openCheckoutModal}
          disabled={subscriptionDetails}
        >
          {getButtonText()}
        </Button>
        {updateMobileNo && (
          <UpdateMobileNumber
            open={updateMobileNo}
            handleClose={handleUpdateMobileNoModal}
          />
        )}

        <span style={{ color: "#D3D3D3" }}>
          Note: Your claimed coupon will be auto-applied during checkout
        </span>
      </div>

      <CheckoutModal
        isModalOpen={isModalOpen}
        closeCheckoutModal={closeCheckoutModal}
        activePlan={activePlan}
        plans={plans}
        coupon={coupon}
        discountedAmount={discountedAmount}
        makePayment={makePayment}
      />
    </div>
  );
};

export default SubsCription;
