import React, { useEffect, useState } from "react";
import BallIcon from "../../assets/images/icons/ball-white.svg";
import "./run.scss";
import axios from "axios";
import fireBaseFuntion from "../../firebase";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router";

const Run = () => {
  //...call  base real time update end
  
  return (
    <div className="run-section">
      <div className="runs-stats" style={{cursor:'pointer'}} >
        <img src={BallIcon} alt="" />
        {/* <div>{userRuns}</div> */}
        <div>0</div>
      </div>
    </div>
  );
};

export default Run;
