import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import "./ChatScreen.scss";
import {
  EllipsisVertical,
  ArrowLeft,
  Search,
  MoreVertical,
  Paperclip,
  Smile,
  Send,
  Play,
  Pause,
  Volume2,
  VolumeX,
  File as FileIcon,
} from "lucide-react";
import { Modal, Box, Popover, Menu, MenuItem, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import axios from "axios";
import { SocketContext } from "../../../../context/SocketContext";
import ReportSuccessModal from "../ReportSuccessModal";
import ParticipantModal from "../ParticipantModal";
import StickerModal from "../StickerModal";
import InviteUsers from "../InviteUsers";
import hahaEmoji from "../../../../assets/images/chatreactions/haha.gif";
import likeEmoji from "../../../../assets/images/chatreactions/like.gif";
import sadEmoji from "../../../../assets/images/chatreactions/sad.gif";
import clapEmoji from "../../../../assets/images/chatreactions/clap.gif";
import loveEmoji from "../../../../assets/images/chatreactions/love.gif";
import celebrateEmoji from "../../../../assets/images/chatreactions/celebrate.gif";
import reportImg from "../../../../assets/images/chatreactions/report.png";
import { getStorageItem } from "../../../../utils/sessionStorage";
import { Navigate } from "react-router-dom";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  },
}));

const MAX_VISIBLE_REACTIONS = 2;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const emojiMap = {
  like: likeEmoji,
  haha: hahaEmoji,
  sad: sadEmoji,
  clap: clapEmoji,
  love: loveEmoji,
  celebrate: celebrateEmoji,
};

const Message = ({ message, onReact, searchQuery }) => {
  const [showReactions, setShowReactions] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [anchorElReport, setAnchorElReport] = useState(null);
  const [progress, setProgress] = useState(0);
  const longPressTimer = useRef(null);
  const messageRef = useRef(null);
  const videoRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const userId = getStorageItem("user_id");
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccess(false);
  };

  const openReport = Boolean(anchorElReport);
  const idReport = openReport ? "simple-popover" : undefined;

  const handleLongPress = () => {
    setShowReactions(true);
  };

  const handleCloseReport = () => {
    setAnchorElReport(null);
  };

  const handleClickReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    longPressTimer.current = setTimeout(handleLongPress, 500);
  };

  const handleTouchEnd = () => {
    if (longPressTimer.current) {
      clearTimeout(longPressTimer.current);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (messageRef.current && !messageRef.current.contains(event.target)) {
        setShowReactions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const reportUserHandler = () => {
    setOpenSuccess(true);
    setOpenModal(false);
  };

  const reactionOptions = ["like", "celebrate", "haha", "love", "sad", "clap"];

  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return (
      <span>
        {parts
          .filter(String)
          .map((part, i) =>
            regex.test(part) ? (
              <mark key={i}>{part}</mark>
            ) : (
              <span key={i}>{part}</span>
            )
          )}
      </span>
    );
  };

  const renderMessageContent = () => {
    switch (message.messageType) {
      case "text":
        return (
          <p className="text">{highlightText(message.content, searchQuery)}</p>
        );
      case "image":
        return (
          <div className="image-container">
            <img
              src={message.content}
              alt="Shared image"
              className="shared-image"
            />
          </div>
        );
      case "video":
        return (
          <div className="video-container">
            <video
              ref={videoRef}
              src={message.content}
              className="shared-video"
              onTimeUpdate={() => {
                const progress =
                  (videoRef.current.currentTime / videoRef.current.duration) *
                  100;
                setProgress(progress);
              }}
            />
            <div className="video-controls">
              <button
                onClick={() => {
                  if (isPlaying) {
                    videoRef.current.pause();
                  } else {
                    videoRef.current.play();
                  }
                  setIsPlaying(!isPlaying);
                }}
              >
                {isPlaying ? <Pause size={24} /> : <Play size={24} />}
              </button>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              <button
                onClick={() => {
                  videoRef.current.muted = !isMuted;
                  setIsMuted(!isMuted);
                }}
              >
                {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
              </button>
            </div>
          </div>
        );
      default:
        return <p className="text">Unsupported message type</p>;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const isSelf =
    message.senderDetails &&
    message.senderDetails._id === getStorageItem("user_id");

  const renderReactions = () => {
    if (!message.reactions || message.reactions.length === 0) {
      return null;
    }

    const visibleReactions = message.reactions.slice(0, MAX_VISIBLE_REACTIONS);
    const remainingCount = message.reactions.length - MAX_VISIBLE_REACTIONS;

    return (
      <div className="reactions">
        {visibleReactions.map((reaction, index) => (
          <span key={index} className="reaction">
            <img
              src={emojiMap[reaction.reaction]}
              alt={reaction.reaction}
              className="reaction-emoji"
            />
          </span>
        ))}
        {remainingCount > 0 && (
          <span className="reaction reaction-count">+{remainingCount}</span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={messageRef}
      className={`message ${isSelf ? "self" : "other"}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
      onMouseLeave={handleTouchEnd}
    >
      {!isSelf && (
        <img
          src={message.senderDetails.profilePhoto}
          alt={message.senderDetails.firstName}
          className="avatar"
        />
      )}
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="message-content">
            {!isSelf && (
              <span className="sender">
                {`${message.senderDetails.firstName} ${
                  message.senderDetails.lastName || ""
                }`}
              </span>
            )}
            {renderMessageContent()}
            <span className="time">{formatDate(message.createdDate)}</span>
          </div>
          {message.senderDetails._id !== userId && (
            <p>
              <EllipsisVertical onClick={handleClickReport} />
              <Popover
                id={idReport}
                open={openReport}
                anchorEl={anchorElReport}
                onClose={handleCloseReport}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    padding: "5px",
                    paddingLeft: "13px",
                    paddingRight: "13px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenModal}
                >
                  Report
                </div>
              </Popover>

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={reportImg}
                      alt="report png"
                      width={140}
                      height={140}
                    />
                    <p
                      style={{
                        color: "#313335",
                        fontSize: "17px",
                        marginTop: "10px",
                      }}
                    >
                      Report User
                    </p>
                    <p
                      style={{
                        color: "#F37121",
                        fontSize: "17px",
                        marginTop: "10px",
                      }}
                    >
                      Encountered something inappropriate? Let us know!
                    </p>
                    <p style={{ color: "#62666A", marginTop: "10px" }}>
                      Are you sure you want to report this user? This action
                      can't be undone.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "25px",
                        width: "300px",
                      }}
                    >
                      <button
                        style={{
                          border: "none",
                          cursor: "pointer",
                          padding: "10px 20px",
                          borderRadius: "7px",
                        }}
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        style={{
                          backgroundColor: "#0C5BA0",
                          cursor: "pointer",
                          color: "white",
                          border: "none",
                          padding: "10px 30px",
                          borderRadius: "7px",
                        }}
                        onClick={reportUserHandler}
                      >
                        Report
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </p>
          )}
        </div>

        {renderReactions()}
        {showReactions && (
          <div className="reaction-options">
            {reactionOptions.map((reaction) => (
              <button
                key={reaction}
                onClick={() => {
                  onReact(message._id, reaction);
                  setShowReactions(false);
                }}
              >
                <img
                  src={emojiMap[reaction]}
                  alt={reaction}
                  className="reaction-emoji"
                />
              </button>
            ))}
          </div>
        )}
      </div>

      <ReportSuccessModal
        messagetype="report"
        openSuccess={openSuccess}
        handleCloseSuccessModal={handleCloseSuccessModal}
      />
    </div>
  );
};

//  CHAT SCREEN
const ChatScreen = ({ chat, groupId, profilePhoto, onBack }) => {
  const [inputMessage, setInputMessage] = useState("");
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const [messages, setMessages] = useState([]);
  const [socket] = useContext(SocketContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [file, setFile] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [openParticipantModal, setOpenParticipantModal] = useState(false);
  const [openStickerModal, setOpenStickerModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(-1);

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const isAtBottom = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        messagesContainerRef.current;
      return scrollHeight - scrollTop === clientHeight;
    }
    return false;
  };

  useEffect(() => {
    if (isAtBottom()) {
      scrollToBottom();
    }
  }, [scrollToBottom]);

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 100);

    return () => clearTimeout(timer);
  }, [scrollToBottom]);
  const fileInputRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchMessages = (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }

    const results = messages.filter((message) =>
      message.content.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(results);
    setCurrentResultIndex(results.length > 0 ? 0 : -1);
  };

  const getMessageById = (msgId) => {
    if (!accessToken) return;

    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/groupchat/getMessage/${msgId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    axios(options)
      .then(({ data }) => {
        if (data && data.senderDetails) {
          setMessages((prevMessages) => [...prevMessages, data]);
        } else {
          console.error("Received incomplete message data:", data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log("Unauthorized:", error);
        } else {
          console.log("Error fetching message:", error);
        }
      });
  };
  useEffect(() => {
    fetchUserInfo();
    checkUserIsJoined();
    getGroupMessages();
    getParticipants();

    if (socket) {
      socket.emit("setup", userId);
      socket.emit("join chat", groupId);

      socket.on("message received", (newMessage) => {
        if (newMessage.chat.groupId === groupId) {
          getMessageById(newMessage.chat._id);
          scrollToBottom();
        }
      });

      socket.on("typing", (data) => {
        if (data.groupId === groupId && data.userId !== userId) {
          setIsTyping(true);
        }
      });

      socket.on("stop typing", (data) => {
        if (data.groupId === groupId && data.userId !== userId) {
          setIsTyping(false);
        }
      });

      socket.on("reaction received", (data) => {
        if (data.groupId === groupId) {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg._id === data.messageId
                ? {
                    ...msg,
                    reactions: [...(msg.reactions || []), data.reaction],
                  }
                : msg
            )
          );
        }
      });
    }

    return () => {
      if (socket) {
        socket.off("message received");
        socket.off("typing");
        socket.off("stop typing");
        socket.off("reaction received");
      }
    };
  }, [groupId, socket, userId]);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/auth/profile/`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsAdmin(["SuperAdmin", "Admin"].includes(response.data.role));
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

  const checkUserIsJoined = async () => {
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/groupchat/checkUserJoinedGroup/${groupId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsJoined(response.data === "User already joined group");
    } catch (error) {
      console.error("Error checking if user joined:", error);
    }
  };

  const getGroupMessages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/groupchat/getMessages/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setMessages(response.data);
      setTimeout(scrollToBottom, 100);
    } catch (error) {
      console.error("Error fetching group messages:", error);
    } finally {
      setLoading(false);
    }
  };

  const getParticipants = async () => {
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/groupchat/getParticipants/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setParticipants(response.data);
    } catch (error) {
      console.error("Error fetching participants:", error);
    }
  };

  const handleSend = async () => {
    if (inputMessage.trim() || file) {
      setLoading(true);
      try {
        let messageType = "text";
        let content = inputMessage;

        if (file) {
          const fileUrl = await uploadFileToS3(file);
          if (file.type.startsWith("image")) {
            messageType = "image";
          } else if (file.type.startsWith("video")) {
            messageType = "video";
          } else if (file.type === "image/gif") {
            messageType = "gif";
          }
          content = fileUrl;
        }

        const response = await axios.post(
          `${global.config.ROOTURL.prod}/groupchat/sendMessage`,
          {
            groupId,
            senderId: userId,
            messageType,
            content,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setInputMessage("");
        setFile(null);

        if (socket) {
          socket.emit("new message", response.data);
          socket.emit("stop typing", { groupId, userId });
        }
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const scrollToRef = useRef(null);
  // useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentResultIndex]);

  const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file, file.name);

    const uploadResponse = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (uploadResponse.data && uploadResponse.data.length > 0) {
      return uploadResponse.data[0].location;
    } else {
      throw new Error("No data returned from upload.");
    }
  };

  const handleReact = async (messageId, reaction) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) =>
        message._id === messageId
          ? {
              ...message,
              reactions: [...(message.reactions || []), { userId, reaction }],
            }
          : message
      )
    );
    const newReaction = { messageId, reaction, userId };
    const options = {
      method: "put",
      url: global.config.ROOTURL.prod + `/groupchat/reactMessage`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: newReaction,
    };
    await axios(options);

    if (socket) {
      socket.emit("reaction", { groupId, messageId, userId, reaction });
    }
  };

  const emitTyping = debounce(() => {
    if (socket) {
      socket.emit("typing", { groupId, userId });
    }
  }, 300);

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    emitTyping();
  };

  const joinGroup = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${global.config.ROOTURL.prod}/groupchat/joinchatGroup`,
        { groupId, userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsJoined(true);
      getParticipants();
    } catch (error) {
      console.error("Error joining group:", error);
      // Implement user feedback for failed join
    } finally {
      setLoading(false);
    }
  };

  const leaveGroup = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${global.config.ROOTURL.prod}/groupchat/leavechatGroup`,
        { groupId, userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setIsJoined(false);
      getParticipants();
      onBack(); // Go back to the group list
    } catch (error) {
      console.error("Error leaving group:", error);
      // Implement user feedback for failed leave
    } finally {
      setLoading(false);
    }
  };
  const LeaveGroupMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  }));
  const DeleteGroupMenuItem = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  }));

  const deleteGroup = async () => {
    setLoading(true);
    try {
      await axios.delete(
        `${global.config.ROOTURL.prod}/groupchat/delete/${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      onBack();
    } catch (error) {
      console.error("Error deleting group:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-screen">
      <header>
        {searchActive ? (
          <div className="search-bar">
            <button onClick={() => setSearchActive(false)}>
              <ArrowLeft />
            </button>
            <input
              type="text"
              placeholder="Search messages..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                searchMessages(e.target.value);
              }}
            />

            {searchResults.length > 0 && (
              <div className="search-navigation">
                <span>
                  {currentResultIndex + 1} of {searchResults.length}
                </span>
                <button
                  onClick={() =>
                    setCurrentResultIndex((prev) =>
                      prev > 0 ? prev - 1 : searchResults.length - 1
                    )
                  }
                >
                  ▲
                </button>
                <button
                  onClick={() =>
                    setCurrentResultIndex((prev) =>
                      prev < searchResults.length - 1 ? prev + 1 : 0
                    )
                  }
                >
                  ▼
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <div style={{ display: "flex", gap: "5px" }}>
              <button className="back-button" onClick={onBack}>
                <ArrowLeft />
              </button>
              <div className="chat-info">
                {profilePhoto && (
                  <img
                    src={profilePhoto}
                    alt={`${chat.name} profile`}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                )}
                <h2>{chat.name}</h2>
                {isTyping && (
                  <p className="typing-indicator">Someone is typing...</p>
                )}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
              <button
                className="search-button"
                onClick={() => setSearchActive(true)}
              >
                <Search />
              </button>
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MoreVertical color="white" />
              </IconButton>
              <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenParticipantModal(true);
                    handleClose();
                  }}
                >
                  Participants
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenInviteModal(true);
                    handleClose();
                  }}
                >
                  Invite Friends
                </MenuItem>
                {isAdmin ? (
                  <>
                    <MenuItem>
                      <a href="/groupchat/reportedUsers">reportedUsers</a>
                    </MenuItem>
                    <DeleteGroupMenuItem
                      onClick={() => {
                        deleteGroup();
                        handleClose();
                      }}
                    >
                      Delete Group
                    </DeleteGroupMenuItem>
                  </>
                ) : (
                  <LeaveGroupMenuItem
                    onClick={() => {
                      leaveGroup();
                      handleClose();
                    }}
                  >
                    Leave Group
                  </LeaveGroupMenuItem>
                )}
              </StyledMenu>
            </div>
          </>
        )}
      </header>

      <main className="messages" ref={messagesContainerRef}>
        {loading ? (
          <div className="loading">Loading messages...</div>
        ) : (
          messages.map((message, index) => (
            <div
              key={message._id}
              ref={
                searchResults[currentResultIndex] === message
                  ? scrollToRef
                  : null
              }
            >
              <Message
                message={message}
                onReact={handleReact}
                searchQuery={searchQuery}
              />
            </div>
          ))
        )}
        <div ref={messagesEndRef} />
      </main>

      {isJoined ? (
        <footer>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
            accept="image/*,video/*"
          />
          <button
            className="attach-button"
            onClick={() => fileInputRef.current.click()}
          >
            <Paperclip />
          </button>
          <div className="input-wrapper">
            {file && (
              <div className="file-preview">
                {file.type.startsWith("image") ? (
                  <img src={URL.createObjectURL(file)} alt="Preview" />
                ) : (
                  <FileIcon size={24} />
                )}
                <span>{file.name}</span>
                <button onClick={() => setFile(null)}>×</button>
              </div>
            )}
            <input
              type="text"
              placeholder="Write a message..."
              value={inputMessage}
              onChange={handleInputChange}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
            />
            <button
              className="emoji-button"
              onClick={() => setOpenStickerModal(true)}
            >
              <Smile />
            </button>
          </div>
          <button
            className="send-button"
            onClick={handleSend}
            disabled={loading}
          >
            <Send />
          </button>
        </footer>
      ) : (
        <footer>
          <button
            className="join-button"
            onClick={joinGroup}
            disabled={loading}
          >
            {loading ? "Joining..." : "Tap to join the group"}
          </button>
        </footer>
      )}

      <ParticipantModal
        openParticipantModal={openParticipantModal}
        handleCloseParticipantModal={() => setOpenParticipantModal(false)}
        participants={participants}
      />
      <StickerModal
        openStickerModal={openStickerModal}
        handleCloseStickerModal={() => setOpenStickerModal(false)}
        groupId={groupId}
        setGroupMessage={setInputMessage}
      />

      <InviteUsers
        openInvite={openInviteModal}
        handleCloseInviteModal={() => setOpenInviteModal(false)}
        handleOpenInviteModal={() => setOpenInviteModal(true)}
        groupId={groupId}
      />
    </div>
  );
};

export default ChatScreen;
