import React, { useEffect, useState } from "react";
import "./index.css";
import MatchItem from "./MatchItem";
import PaginationBox from "./PaginationBox";
import AddBox from "./AddBox";
import { Button, Stack, TextField } from "@mui/material";
import Filter from "./Filter";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import moment from "moment";
const accessToken = getStorageItem("token");
const userId = getStorageItem("user_id");

const Index = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [allMatches, setAllMatches] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  

  const getAllMatches = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/fantasy/getAllMatches/${pageNumber}/50`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        setAllMatches(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getAllMatches();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div style={{ display: "flex", justifyContent: "right", marginTop: "8px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TextField
            id="fromDate"
            onChange={(e) => {
              setFromDate(
                moment(new Date(e.target.value)).format("yyyy-MM-DD")
              );
            }}
            label="From"
            type="date"
            value={fromDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="toDate"
            onChange={(e) => {
              setToDate(moment(new Date(e.target.value)).format("yyyy-MM-DD"));
            }}
            label="To"
            type="date"
            value={toDate}
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          
        </div>
      </div>
      <div className="contest_admin_head">
        <h3 className="contest_admin_head-content--left">Match List</h3>

      </div>
      <div className="contest_admin_prediction-list">
        {allMatches?.map((prediction, index) => {
          return (
            <MatchItem
              key={index}
              data={prediction}
              length={allMatches?.length}
              getAllMatches={getAllMatches}
            />
          );
        })}
      </div>
      {allMatches.length > 10 && (
        <div className="contest_admin_pagination-wrapper">
          <PaginationBox setPageNumber={setPageNumber} />
        </div>
      )}
      <div className="contest_admin_add">
        <AddBox />
      </div>
      <div className="contest_admin_cancel_save">
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            className="contest_admin_cancel_save--cancel"
          >
            Cancel
          </Button>
          <Button variant="contained" href="#outlined-buttons">
            Save
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default Index;